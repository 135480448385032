import React from 'react';
import { useCountries } from 'api/admin/countries';
import { useField, useForm } from 'react-final-form';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

export function CountriesField() {
    const countries = useCountries();
    const fromField = useField('country_id');
    const form = useForm();

    return (
        <FormControl style={{ minWidth: '100%' }}>
            <InputLabel>Country</InputLabel>
            <Select
                value={fromField.input.value}
                onChange={(ev) => form.change('country_id', ev.target.value)}
                label="Country"
                fullWidth
            >
                {countries.payload?.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                        {country.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
