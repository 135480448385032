import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	Grid,
	Link as MaterialLink,
	Paper,
	Typography,
} from "@material-ui/core";
import {
	toggleUserConfirmContent,
	useChangeReqStatus,
	useUGCEntry,
	useUGCEntryBySlug,
} from "api/admin/ugc/entries";
import {
	IChangeEntryStatusActionParams,
	IUserDataComments,
	UGCEntryResponse,
	UGCEntryURL,
} from "api/admin/ugc/entries.types";
import { useVerifications } from "api/admin/verifications";
import PageNotFound from "components/page-not-found";
import { ViewImage } from "components/screenshot";
import { UGCRequestRouteParams, UGCRequestsRoute } from "config/routes";
import { FormApi } from "final-form";
import { fillEmptyStr } from "helpers/strings";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import UGCRequestURL from "./ugc-content/ugc-request-url";
import IUGCRequestUserData, {
	UserDataForm,
} from "./ugc-content/ugc-request-user-data";
import UGCRequestFile from "./ugc-content/ugc-request-user-file";
import UGCUserURL from "./ugc-content/ugc-request-user-url";
import UGCHeadInfo from "./ugc-head-info";
import UGCRequestMenu from "./ugc-request-menu";
import { useRequestStyles } from "./ugc-request.styles";
import UGCVerificationStatus, {
	getUGCVerificationProps,
} from "./ugc-verification-status";

function UGCRequestWrap({
	children,
}: {
	children: React.ReactChild[] | React.ReactChild;
}) {
	const classes = useRequestStyles();
	return (
		<Box py={4} px={5} mt={1}>
			<Paper className={classes.block}>
				<Box p={3} mt={2}>
					{children}
				</Box>
			</Paper>
		</Box>
	);
}

function UGCRequestInfo({
	children,
	label,
}: {
	children: React.ReactChild[] | React.ReactChild;
	label: string;
}) {
	const classes = useRequestStyles();
	return (
		<>
			<Grid item lg={2}>
				<Box mt={1}>
					<Typography variant="h6" component="p" className={classes.subCaption}>
						{label}
					</Typography>
				</Box>
			</Grid>
			<Grid item lg={10}>
				<Box mt={1}>{children}</Box>
			</Grid>
		</>
	);
}

export default function UGCRequest() {
	const [formAPI, setFormAPI] = useState<FormApi<IUserDataComments> | null>(
		null
	);
	const [createdByAdmin, setCreatedByAdmin] = useState<UGCEntryURL[]>([]);
	const [createdByUser, setCreatedByUser] = useState<UGCEntryURL[]>([]);
	const [actionType, setActionType] = useState<
		IChangeEntryStatusActionParams["status"] | null
	>(null);
	const { id: slug } = useParams<UGCRequestRouteParams>();
	const loc = useLocation();
	const isSharedPage = loc.pathname.includes("/share");
	const classes = useRequestStyles();
	const entryBySlug = useUGCEntryBySlug();
	let entryAPI = useUGCEntry();
	const entryStatusAPI = useChangeReqStatus();
	const history = useHistory();
	const contentStatusAPI = toggleUserConfirmContent();
	const [editLinks, updEditLinks] = useState<number[]>([]);
	const [editFiles, updEditFiles] = useState<number[]>([]);

	if (isSharedPage) {
		entryAPI = entryBySlug;
	}

	const verifications = useVerifications();

	const { entry, urls, files, user, images } = (entryAPI.payload ||
		{}) as UGCEntryResponse;

	useEffect(() => {
		if (urls && urls.length > 0) {
			let createdByAdminList: UGCEntryURL[] = [];
			let createdByUserList: UGCEntryURL[] = [];
			let updEditLinksList: number[] = [];
			urls.forEach((url) => {
				if (url.admin_created) {
					createdByAdminList.push(url);
				} else {
					createdByUserList.push(url);
					if (url.accepted_status === "on_review") {
						updEditLinksList.push(url.id as number);
					}
				}
			});
			if (createdByAdminList.length > 0) {
				setCreatedByAdmin(createdByAdminList);
			}
			if (createdByUserList.length > 0) {
				setCreatedByUser(createdByUserList);
				updEditLinks(updEditLinksList);
			}
		}

		if (files && files.length > 0) {
			let updEditFilesList: number[] = [];
			files.forEach((file) => {
				if (file.accepted_status === "on_review") {
					updEditFilesList.push(file.id);
				}
			});
			updEditFiles(updEditFilesList);
		}
	}, [urls, files]);

	useEffect(() => {
		slug && entryAPI.query(isSharedPage ? { slug } : { id: slug });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug]);

	const setFormAPIHandler = useCallback((api: UserDataForm) => {
		setFormAPI(api);
	}, []);

	if (entryAPI.status === 404) {
		return <PageNotFound />;
	} else if (entryAPI.loading && !entryAPI.payload?.entry) {
		return <CircularProgress />;
	} else if (!entryAPI.payload?.entry) {
		return null;
	}

	async function sendForReSignatureHandler() {
		if (formAPI) {
			await formAPI.submit();
			setFormAPI(null);
		} else {
			await contentStatusAPI({
				entry_id: entry.id,
				content_confirmed: true,
			});
			entryStatusAPI({ entry_id: entry.id, status: "revision" });
		}
	}

	function acceptHandler() {
		setActionType("approve");
	}

	function denyHandler() {
		setActionType("decline");
	}

	function closeConfirmActionModalHandler() {
		setActionType(null);
	}

	async function confirmActionRequestHandler() {
		await entryStatusAPI({ entry_id: entry.id, status: actionType! });
		history.push(UGCRequestsRoute.getLink());
	}

	const created_at = DateTime.fromISO(entry.created_at).toHTTP();
	const userContentNeedAction = editLinks.length + editFiles.length;
	const totalUserContent = createdByUser.length + files.length;

	const haveUserData =
		entry.user_data && Object.keys(entry.user_data).length > 0;

	const isAdminReview = entry.status === "on_review";
	const isUserReview = entry.status === "revision";
	const disabledEdit =
		entry.status === "approved" || entry.status === "declined" || isUserReview;

	const contentReviewed = userContentNeedAction === 0;
	const commentsFilled =
		Object.keys(
			formAPI !== null ? formAPI.getState().values : entry.comment || {}
		).length > 0;

	const contentStatusIsEditing = editLinks.length > 0 || editFiles.length > 0;
	const contentChanged = createdByUser
		.concat(files)
		.some(
			({ accepted_status, previous_accepted_status }) =>
				accepted_status !== previous_accepted_status
		);

	const isEdited = commentsFilled || contentChanged;

	const showReSignatureBtn =
		(!entry.content_confirmed &&
			entry.user_data_confirmed &&
			entry.user_content_allowed) ||
		(isEdited &&
			!(
				contentStatusIsEditing ||
				(!commentsFilled && !entry.user_data_confirmed && contentReviewed) ||
				entry.status === "revision" ||
				(entry.user_step === "finished" && !contentReviewed)
			));

	const showAcceptBtn =
		(entry.content_confirmed || !entry.user_content_allowed) &&
		!isEdited &&
		!(
			!entry.user_data_confirmed ||
			!contentReviewed ||
			contentStatusIsEditing ||
			entry.status === "revision"
		);

	return (
		<div className="page__container">
			<Grid container>
				<Grid item lg={10}>
					<Box py={4} px={5} mt={1}>
						<Typography variant="h2" className={classes.ugcTitle}>
							UGC-{entry.id}
							{!isSharedPage && `: ${entry.title}`}
						</Typography>

						<Box component="span">
							<Typography component="span">{created_at}</Typography>
						</Box>
					</Box>
				</Grid>
				{!isSharedPage && (
					<Grid item lg={2}>
						<UGCRequestMenu entry={entry} disabledEdit={disabledEdit} />
					</Grid>
				)}
				<Grid item lg={11}>
					{!isSharedPage && (
						<Box pt={4} px={5} mt={1} mb={3}>
							<UGCHeadInfo entry={entry} />
						</Box>
					)}

					{!isSharedPage && (
						<Box mt={2} px={5} mb={3}>
							<Grid container>
								{user && (
									<UGCRequestInfo label="Source:">
										<Box m={1}>
											<Link
												to={`/users/${user?.id}`}
												className="link"
												style={
													!user.first_name && !user.last_name
														? { pointerEvents: "none" }
														: void 0
												}
											>
												{fillEmptyStr(user.first_name, user.last_name, {
													separator: "\t",
												})}
											</Link>
										</Box>
									</UGCRequestInfo>
								)}
								<UGCRequestInfo label="Location link:">
									<Box m={1}>
										<MaterialLink
											href={entry.location_link || ""}
											color="inherit"
											underline="always"
											className="link"
											rel="noreferrer noopener"
											target="_blank"
											style={
												!entry.location_link
													? { pointerEvents: "none" }
													: void 0
											}
										>
											{fillEmptyStr(entry.location_link)}
										</MaterialLink>
									</Box>
								</UGCRequestInfo>
								<UGCRequestInfo label="News links:">
									<Box m={1}>
										{entry.news_links.map((newsLink: string | any, index: number) => (
											<Typography key={index} component="p" className={classes.subCaption}>
												{fillEmptyStr(newsLink)}
											</Typography>
										))}
									</Box>
								</UGCRequestInfo>
								<UGCRequestInfo label="Verification methods:">
									<Box m={1}>
										{entry.verification?.map((method) => {
											return (
												<Typography
													component="p"
													className={classes.subCaption}
													key={method.id}
												>
													{method.title}
												</Typography>
											);
										})}
									</Box>
								</UGCRequestInfo>
								<UGCRequestInfo label="Verification materials:">
									<Grid container>
										{(images || []).length > 0 ? (
											images.map(({ id, link }) => (
												<Box m={1} key={id}>
													<ViewImage link={link} />
												</Box>
											))
										) : (
											<Box m={1}>{fillEmptyStr()}</Box>
										)}
									</Grid>
								</UGCRequestInfo>
							</Grid>
						</Box>
					)}
					{createdByAdmin.length > 0 && (
						<Box pt={3} px={5} mb={3}>
							<Typography component="h2" variant="h6">
								Links:
							</Typography>
							<Divider />
							{createdByAdmin.map((url) => {
								return (
									<UGCRequestURL
										key={url.id}
										url={url}
										disabledUpd={isSharedPage || disabledEdit}
									/>
								);
							})}
						</Box>
					)}

					{haveUserData &&
						(createdByUser.length > 0 || (files && files.length > 0)) && (
							<UGCRequestWrap>
								<Typography variant="h2" className={classes.ugcTitle}>
									Content, added by user
								</Typography>
								{!isSharedPage ? (
									<UGCVerificationStatus
										{...getUGCVerificationProps(
											(contentReviewed &&
												!contentStatusIsEditing &&
												entry.user_step === "revision_finished" &&
												!contentChanged &&
												!isUserReview) ||
												entry.status === "approved",
											contentReviewed && !contentStatusIsEditing,
											entry.status
										)}
										tip={
											!contentReviewed
												? `Please accept/deny user content: ${
														totalUserContent - userContentNeedAction
												  } of ${totalUserContent}`
												: void 0
										}
									/>
								) : (
									<Box mb={2} />
								)}
								<>
									{createdByUser.length > 0 &&
										createdByUser.map((url) => {
											return (
												<UGCUserURL
													key={url.id}
													url={url}
													withControls={!isSharedPage}
													disabledEdit={disabledEdit}
													contentConfirmed={contentChanged}
													changeContentEditStatus={(list: number[]) => {
														updEditLinks(list);
													}}
													editList={editLinks}
												/>
											);
										})}

									{files &&
										files.map((file) => {
											return (
												<UGCRequestFile
													key={file.id}
													file={file}
													withControls={!isSharedPage}
													disabledEdit={disabledEdit}
													changeContentEditStatus={(list: number[]) => {
														updEditFiles(list);
													}}
													editList={editFiles}
												/>
											);
										})}
								</>
							</UGCRequestWrap>
						)}

					{haveUserData && !isSharedPage && (
						<UGCRequestWrap>
							<IUGCRequestUserData
								entry={entry}
								setFromAPI={setFormAPIHandler}
								disabledEdit={disabledEdit}
								isAdminReview={isAdminReview}
								isUserReview={isUserReview}
								withControls={!isSharedPage}
							/>
						</UGCRequestWrap>
					)}

					{!isSharedPage &&
						entry.status !== "approved" &&
						entry.status !== "declined" && (
							<Box py={3} px={5} mb={2}>
								<Grid container>
									{showReSignatureBtn && (
										<Button
											type="button"
											color="primary"
											variant="contained"
											onClick={sendForReSignatureHandler}
										>
											{entry.user_content_allowed && !commentsFilled
												? "Send to sign documents"
												: "Send for re-signature"}
										</Button>
									)}
									{showAcceptBtn && (
										<Button
											type="button"
											color="primary"
											variant="contained"
											onClick={acceptHandler}
										>
											Accept
										</Button>
									)}
									<Button
										type="button"
										color="secondary"
										variant="text"
										onClick={denyHandler}
									>
										Deny
									</Button>
								</Grid>
								<Dialog
									open={actionType !== null}
									onClose={closeConfirmActionModalHandler}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<DialogTitle id="alert-dialog-title">
										You really want to {actionType} request?
									</DialogTitle>
									<DialogActions>
										<Button
											onClick={closeConfirmActionModalHandler}
											color="secondary"
											autoFocus
										>
											Disagree
										</Button>
										<Button
											onClick={confirmActionRequestHandler}
											color="primary"
										>
											Agree
										</Button>
									</DialogActions>
								</Dialog>
							</Box>
						)}
				</Grid>
			</Grid>
		</div>
	);
}
