import React, {useCallback} from 'react';
import {
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Switch,
  Typography, Box
} from "@material-ui/core";
import {useAppStyles} from "../../app.styles";
import TableContainer from "@material-ui/core/TableContainer";
import {useCountries, useUpdateCountry} from 'api/admin/countries';
import {DateTime} from "luxon";
import {humanizeDate} from "../../helpers/string-functions";

export const Countries = () => {
  const commonClasses = useAppStyles();
  const {payload} = useCountries();
  const {mutate: updateCountry} = useUpdateCountry();

  const handleCountryToggle = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.currentTarget.name!.match(/\d+/g)!.join('');
        updateCountry({id, visible: event.currentTarget.checked});
      },
      [],
  );


  return (
      <Container maxWidth="xl" className={commonClasses.container}>
        <Typography variant="h4">Countries</Typography>
        <Box mb={3}/>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['Country',
                  'Last modified',
                  'Active'].map((str) => (
                    <TableCell key={str}>
                      {str}
                    </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(payload || []).map((country) => (
                  <TableRow hover key={country.id}>
                    <TableCell>
                      {country.name}
                    </TableCell>
                    <TableCell>
                      {humanizeDate(country.updated_at, DateTime.DATETIME_MED)}
                    </TableCell>
                    <TableCell>
                      <Switch
                          defaultChecked={country.visible}
                          onChange={handleCountryToggle}
                          color="primary"
                          name={`country_${country.id}`}
                      />
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
  );
};
