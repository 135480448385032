import { makeStyles } from '@material-ui/core/styles';

export const useHeaderStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.dark,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerMainBlock: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        background: `url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTA5IiBoZWlnaHQ9IjIzIiB2aWV3Qm94PSIwIDAgMTA5IDIzIj4gICAgPGRlZnM+ICAgICAgICA8cGF0aCBpZD0iYSIgZD0iTTEwOS4wOSAxMS4xNDR2MTEuMDIySDBWLjEyMWgxMDkuMDl6Ii8+ICAgIDwvZGVmcz4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiM3N0JDMUYiIGQ9Ik05MC44NTguNDIxbDUuODQ5IDExLjA0NyAxLjk3LTMuNjU2LTMuOTg0LTcuMzl6Ii8+ICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC4yOSkiPiAgICAgICAgICAgIDxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj4gICAgICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjYSIvPiAgICAgICAgICAgIDwvbWFzaz4gICAgICAgICAgICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNNjUuNDI0IDIxLjk2M2gzLjYyNlYzLjI0OWg1LjkzMVYuMTJINTkuNDkzVjMuMjVoNS45M3YxOC43MTR6bS0yMS4wMzkuMDFWMy4yNDloNS4yMzljMi41MTcgMCA0LjIzIDEuNDE5IDQuMjMgMy44MzdzLTEuNzEzIDMuODA1LTQuMjMgMy44MDVoLTIuOTk1djMuMTZoMy4xOTZjNC42NjcgMCA3LjY1Ni0yLjk2NyA3LjY1Ni02Ljk2NSAwLTMuOTk4LTIuOTg5LTYuOTY1LTcuNjU2LTYuOTY1SDQwLjc2djIxLjg1MmgzLjYyNnptMzcuODkxLTMuNFYuMTJINzguNjV2MjEuODMxSDkzLjQ1di0zLjM4SDgyLjI3NnpNMzMuMTI5LjEydjEzLjcxYzAgMy4wNjQtMS45OCA1LTQuOTY5IDUtMi45ODggMC00LjkzNi0xLjkzNi00LjkzNi01Vi4xMjJoLTMuNjI2djE0LjExNGMwIDQuNzQgMy42OTMgNy45MzEgOC41NjIgNy45MzEgNC44NjggMCA4LjU5Ni0zLjE5MSA4LjU5Ni03LjkzMVYuMTJIMzMuMTN6bTY4LjcxOCAyMS44NTJ2LTguMTU4TDEwOS4wOTEuMTMyaC0zLjgzNkw5OC4xMDMgMTMuNHY4LjU3MmgzLjc0NHpNMCAyMS45NzNWLjEzMmg5LjA2NmM0LjY2NyAwIDcuNjU2IDIuOTY2IDcuNjU2IDYuOTY1IDAgMy4xODQtMS44OTggNS43MTItNS4wMjggNi42MWw0LjU4OCA4LjI2NmgtNC4yMzdMNS45NzMgMTAuOTAyaDIuODkxYzIuNTE4IDAgNC4yMy0xLjM4NyA0LjIzLTMuODA1IDAtMi40MTktMS43MTItMy44MzgtNC4yMy0zLjgzOEgzLjYyNnYxOC43MTRIMHoiIG1hc2s9InVybCgjYikiLz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        display: 'block',
        width: 109,
        height: 23,
    },
    menuItem: {
        borderRadius: 0,
        borderBottom: `3px solid transparent`,
        '&.active': {
            borderBottom: `3px solid ${theme.palette.success.main}`,
            pointerEvents: 'none',
        },
    },
}));
