import React, { useEffect, useState } from 'react';
import useDebounce from '../../../../hooks/use-debounce';
import { useField } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import { useParameterizedQuery } from 'react-fetching-library';
import { getEventsAction } from '../../../../api/admin/event-story/event.actions';

type PropsType = {
    name: string;
};

const EventsChooseField: React.FC<PropsType> = ({ name }) => {
    const classes = useStyles();
    const fromField = useField(name);

    const { payload, query } = useParameterizedQuery(getEventsAction);

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debouncedSearchTerm) {
            query(`?search=${debouncedSearchTerm}`);
        } else {
            query('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    const handleChange = (event: any, value: any) => {
        fromField.input.onChange(value);
    };

    const setSearchValue = React.useCallback(
        (newValue: any) => {
            setSearchTerm(newValue);
        },
        [setSearchTerm]
    );

    const dataEvents = payload !== undefined ? payload.events : [];

    return (
        <div className={classes.content}>
            <Autocomplete
                value={fromField.input.value}
                onChange={handleChange}
                size="small"
                options={dataEvents}
                getOptionSelected={(option: any, value) => {
                    return option.id === value.id;
                }}
                getOptionLabel={(option) => {
                    return option.title || '';
                }}
                renderOption={(option) => {
                    return <Typography>{option.title}</Typography>;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        error={fromField.meta.error}
                        placeholder={`Choose EVENT`}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                )}
            />
        </div>
    );
};
export default EventsChooseField;

const useStyles = makeStyles({
    content: {
        padding: 0,
    },
});
