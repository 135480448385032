import React from "react";
import {Checkbox, makeStyles} from "@material-ui/core";
import {useForm} from "react-final-form";
import {FieldArray} from "react-final-form-arrays";

type PropsType = {
    name: string
    options: Array<{ id: string, label: string }>
}

const CheckboxField: React.FC<PropsType> = ({name, options}) => {
    const classes = useStyles();

    return (
        <div className={classes.box}>
            <FieldArray
                name={name}
                component={CheckboxGroup}
                options={options}
            />
        </div>
    )
}
export default CheckboxField


const CheckboxGroup = ({fields, options}: any) => {
    const classes = useStyles();
    const form = useForm();

    const handleChange = (event: any, option: any) => {
        if (event.target.checked) {
            fields.push(option);
        } else {
            fields.value.forEach((field: string, index: number) => {
                if (field === option) {
                    fields.remove(index);
                }
            })
        }
        form.submit();
    }

    return (
        <>
            {options.map((option: any, index: number) => {
                    // const formData: Array<string> = Object.keys(form.getState().values).map((k) => form.getState().values[k])
                    return (
                        <div>
                            <Checkbox
                                className={classes.item}
                                key={option.label}
                                value={option.id}
                                onChange={event => handleChange(event, option.id)}
                                color="primary"
                                // checked={form.getState().values[fields.name]}
                                checked={Boolean(fields.value) && fields.value.includes(option.id)}
                            />
                            <span className={classes.label}>{option.label}</span>
                        </div>
                    )
                }
            )}
        </>
    )
}

const useStyles = makeStyles({
    label: {
        fontSize: '14px'
    },
    item: {
        padding: '2px 5px 2px 0px',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
    }
});