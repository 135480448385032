import { Action } from "react-fetching-library";
import {UpdateCountryParams} from "./countries.types";

export function getCountriesAction(): Action {
    return {
        endpoint: "/api/v1/admin/countries",
        method: "GET",
    };
}

export function updateCountryAction({id, visible}: UpdateCountryParams): Action {
    return {
        endpoint: `/api/v1/admin/countries/${id}`,
        method: "PATCH",
        body: { country: { visible } }
    };
}
