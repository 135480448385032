import { Action, useMutation } from 'react-fetching-library';
import { useCurrentAdmin } from './current_admin';

export interface ISignInParams {
    email: string;
    password: string;
}

export function signInAction(user: ISignInParams): Action {
    return {
        method: 'POST',
        endpoint: '/api/v1/admin/sign_in',
        body: {
            admin_user: {
                email: user.email,
                password: user.password,
            },
        },
    };
}

function signInTwoAuthAction(token: string): Action {
    return {
        method: 'POST',
        endpoint: '/api/v1/admin/token_verifications',
        body: {
            token,
        },
    };
}

export function useTwoAuth(): [(token: string) => Promise<any>, boolean] {
    const twoAuthAPI = useMutation(signInTwoAuthAction);
    const currentAdmin = useCurrentAdmin();

    async function doTwoAuthSignIn(token: string) {
        await twoAuthAPI.mutate(token);
        return await currentAdmin.query(null);
    }

    return [doTwoAuthSignIn, twoAuthAPI.loading];
}
