import {makeStyles} from "@material-ui/core";

export const useUsersStyles = makeStyles({
    container: {
        paddingTop: 30,
    },
    button: {
        marginRight: 10,
        marginBottom: 10
    },
    buttonShow: {
        width: '100%',
        textTransform: 'none',
        shadows: "none"
    },
    box: {
        display: 'flex',
        alignItems: 'center',
    },
});