import React, { useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Dialog, Link } from '@material-ui/core';
import { useCreateNewSlug, useCreateShareSlug } from 'api/admin/ugc/entries';
import { UGCEntry } from 'api/admin/ugc/entries.types';
import { UGCPopup } from 'components/UGC/ugc-popup/ugc-popup';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import copy from 'copy-to-clipboard';
import { FRONT_HOST } from 'config/develop';
import ModifySnackbar from 'components/snackbar';
import {
    NEW_SHARE_ALERT,
    NEW_UPLOAD_LINK_ALERT,
    SHARE_COPIED_ALERT,
} from 'constants/messages';

const renewInitialState = { open: false, msg: '' };

export default function UGCRequestMenu({
    entry,
    disabledEdit,
}: {
    entry: UGCEntry;
    disabledEdit: boolean;
}) {
    const [editURL, setEditURL] = useState<UGCEntry | undefined>();
    const [menuIsOpen, toggleMenu] = useState<null | HTMLElement>(null);
    const createNewSlug = useCreateNewSlug();
    const createShareSlug = useCreateShareSlug();
    const [renewConfig, setRenewConfig] = useState(renewInitialState);

    const openMenuHandler = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        toggleMenu(event.currentTarget);
    };

    const closeMenuHandler = (): void => {
        toggleMenu(null);
    };

    const closeEditURLHandler = (): void => {
        setEditURL(undefined);
    };

    const snackbarCloseHandler = () => {
        setRenewConfig(renewInitialState);
    };

    const renewSlug = (isShared: boolean = false, isRenew: boolean = false) => {
        if (isShared) {
            createShareSlug({ id: entry.id, renew: isRenew }).then((resp) => {
                copy(
                    `${document.location.origin}/ugc-requests/${resp.payload.admin_share_slug}/share`
                );
            });
            setRenewConfig({
                open: true,
                msg: isRenew ? NEW_SHARE_ALERT : SHARE_COPIED_ALERT,
            });
        } else {
            createNewSlug({ id: entry.id }).then((resp) => {
                copy(`${FRONT_HOST}/ugc-request/${resp.payload.slug}`);
            });
            setRenewConfig({
                open: true,
                msg: NEW_UPLOAD_LINK_ALERT,
            });
        }
        closeMenuHandler();
    };

    return (
        <>
            <ModifySnackbar
                text={renewConfig.msg}
                open={renewConfig.open}
                handleClose={snackbarCloseHandler}
            />
            <Grid container justify="flex-end">
                <IconButton
                    aria-label="more"
                    aria-controls="menu"
                    aria-haspopup="true"
                    onClick={openMenuHandler}
                >
                    <MoreHorizIcon fontSize="large" />
                </IconButton>
                <Menu
                    id="menu"
                    anchorEl={menuIsOpen}
                    keepMounted
                    open={Boolean(menuIsOpen)}
                    onClose={closeMenuHandler}
                >
                    <MenuItem onClick={() => renewSlug(true)}>
                        Copy share link
                    </MenuItem>
                    <MenuItem onClick={() => renewSlug(true, true)}>
                        Reset share link
                    </MenuItem>
                    <MenuItem onClick={() => renewSlug()}>
                        Reset and copy upload link
                    </MenuItem>
                    { entry.status === 'approved' && entry.pdf_view_url && (
                        <MenuItem>
                            <Link
                                href={entry.pdf_view_url}
                                color='inherit'
                                underline='none'
                                target='_blank'
                            >
                                Export to PDF
                            </Link>
                        </MenuItem>
                     )}
                    <MenuItem
                        onClick={() => {
                            setEditURL(entry);
                            closeMenuHandler();
                        }}
                        disabled={disabledEdit}
                    >
                        Edit
                    </MenuItem>
                </Menu>
            </Grid>
            <Dialog open={Boolean(editURL)} onClose={closeEditURLHandler}>
                <UGCPopup onClose={closeEditURLHandler} data={editURL} />
            </Dialog>
        </>
    );
}
