import React, { useState } from "react";
import { Box, Grid, Typography, Button, Collapse } from "@material-ui/core";
import { EntryContentStatus, UGCEntryFile } from "api/admin/ugc/entries.types";
import { useRequestStyles } from "../ugc-request.styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {
  toggleUserConfirmContent,
  useUGCContentStatus,
} from "api/admin/ugc/entries";
import UGCUserContentActions from "./user-content-actions";
import { fillEmptyStr } from "helpers/strings";
import { ViewImage } from "components/screenshot";
import { humanize } from "../../../helpers/string-functions";

interface IUGCRequestFile {
  file: UGCEntryFile;
  withControls: boolean;
  disabledEdit: boolean;
  changeContentEditStatus: (list: number[]) => void;
  editList: number[];
}

function getFormattedFileDuration(duration: any) {
  if (duration && typeof duration === "number") {
    const val = Math.floor(duration as number);
    let minutes = parseInt(String(val / 60));
    let seconds = val % 60;
    return `${minutes}:${seconds}`;
  }
  return fillEmptyStr(duration);
}

function UGCRequestFile({
  file,
  withControls,
  disabledEdit,
  changeContentEditStatus,
  editList,
}: IUGCRequestFile) {
  const classes = useRequestStyles();
  const [expandedExif, setExpandedExif] = useState<boolean>(false);
  const statusAPI = useUGCContentStatus();
  const contentStatusAPI = toggleUserConfirmContent();

  function onUpdateStatus() {
    const delIndex = editList.findIndex((i) => i === file.id);
    editList.splice(delIndex, 1);
    changeContentEditStatus([...editList]);
  }

  async function updateStatus(accepted_status: EntryContentStatus) {
    if (accepted_status === "on_review") {
      editList.push(file.id as number);
      changeContentEditStatus([...editList]);
    } else if (file.accepted_status !== accepted_status) {
      await contentStatusAPI({
        entry_id: file.owner_id,
        content_confirmed: false,
      });
      await statusAPI({
        entry_id: file.owner_id,
        content_id: file.id,
        type: "file",
        accepted_status,
      });
      onUpdateStatus();
    } else {
      onUpdateStatus();
    }
  }

  function exifShowHandler(): void {
    setExpandedExif((prev) => !prev);
  }

  return (
    <Grid key={file.id} container justify="flex-end">
      <Grid item lg={3} className={classes.userContentRowHead}>
        <Box mr={3} my={2}>
          <Typography
            variant="h6"
            component="h6"
            className={classes.subCaption}
          >
            File:
          </Typography>
          <Typography component="p" className={classes.textBreak}>
            {file.metadata.original_name}
            <a
              className={classes.downloadVideo}
              href={file.permanent_url || ""}
              download={file.metadata.original_name}
              target="_blank"
              rel="noopener noreferrer"
            >
              {""}
            </a>
          </Typography>
          <Box my={2}>
            {file.file_type === "video" ? (
              <video className={classes.preview} controls>
                <source
                  src={file.permanent_url || ""}
                  type={file.metadata.mimetype}
                ></source>
                Tag video is not supported in your browser.
              </video>
            ) : (
              <ViewImage
                link={file.permanent_url || ""}
                className={classes.preview}
              />
            )}
          </Box>
          <Typography
            variant="h6"
            component="h6"
            className={classes.subCaption}
          >
            User comment:
          </Typography>
          <Typography
            variant="inherit"
            component="p"
            className={clsx(classes.text, !file.comment && classes.italic)}
            gutterBottom={false}
          >
            {fillEmptyStr(file.comment, {
              emptyText: "No comment",
            })}
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6}>
        <Box ml={3} my={2}>
          <Typography component="p" className={classes.subCaption}>
            Video duration:{"asd "}
            {getFormattedFileDuration(file.metadata.transloadit_meta.duration)}
          </Typography>

          {(file.thumbnails || []).length > 0 && (
            <Typography component="span" className={classes.subCaption}>
              Screenshots:
            </Typography>
          )}
          <Grid container>
            asd
            {(file.thumbnails || []).map((link) => {
              return (
                <Box m={1} key={link}>
                  <ViewImage link={link} />
                </Box>
              );
            })}
          </Grid>
          <br />
          <Button
            onClick={exifShowHandler}
            aria-expanded={expandedExif}
            aria-label="show file exif"
            variant="text"
          >
            <Typography component="span" className={classes.subCaption}>
              Exif
            </Typography>
            <ExpandMoreIcon
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedExif,
              })}
            />
          </Button>
          <Collapse in={expandedExif} timeout="auto" unmountOnExit>
            <Typography component="p" className={classes.text}>
              <table>
                {Object.entries(file.metadata.transloadit_meta).map(
                  ([key, value]) => {
                    return (
                      <tr>
                        <td>{humanize(key)}</td>
                        <td>{value}</td>
                      </tr>
                    );
                  }
                )}
              </table>
            </Typography>
          </Collapse>
        </Box>
      </Grid>
      <UGCUserContentActions
        withControls={withControls}
        status={file.accepted_status}
        onAccept={() => updateStatus("accepted")}
        onDeny={() => updateStatus("declined")}
        onEdit={() => updateStatus("on_review")}
        hideEdit={disabledEdit}
        isEdit={editList.includes(file.id)}
      />
    </Grid>
  );
}

export default UGCRequestFile;
