export const UGC_USER_STEPS: { [key: string]: string } = {
    content: 'Awaiting for Content',
    personal_data: 'Awaiting for Personal data',
    billing_info: 'Awaiting for Billing Address',
    payment_info: 'Awaiting for Payment information',
    contract: 'Signing Contract',
    invoice: 'Signing Invoice',
    user_confirmation: 'Email confirmation',
    finished: 'Sent to review',
    revision: 'Data correction',
    contract_revision: 'Signing Contract',
    invoice_revision: 'Signing Invoice',
    revision_finished: 'Sent to review',
};

export const UGC_REQUEST_STATUS: { [key: string]: string } = {
    created: 'Created',
    sent: 'Link sent',
    received: 'Waiting for the content',
    started: 'Waiting for User Data',
    on_review: 'Review',
    approved: 'Accepted',
    waited_user_confirmation: 'Email confirmation',
    declined: 'Denied',
    revision: 'Data correction',
    completed: 'Completed',
    canceled: 'Canceled',
};
