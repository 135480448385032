import formatStringByPattern from 'format-string-by-pattern';

export function getUrlFromPath(path: string, params: any = {}): string {
  return path
    .replace(/:([\w_-]+)/g, (...args: any[]) => {
      return params && params[args[1]]
        ? String(params[args[1]])
        : String(args[0]);
    })
    .replace(/\/\//g, '/')
    .replace(/\(.*:.*\)\?/g, '')
    .replace(/[()?]/g, '');
}

export const formatOnlyNumbers = (str: string, pattern: string): string => {
  const onlyNumbers = str.replace(/[^\d]/g, '');

  return formatStringByPattern(pattern, onlyNumbers);
};

export const formatContentTiming = (str: string) => {
  const formatted = formatOnlyNumbers(str, '00:00:00.000');
  let [hh, mm, ss, ms] = formatted.split(/[:.]/);
  let miliseconds = +ms > 999 ? 0 : ms;
  let seconds = +ss > 59 ? 0 : ss;
  let minutes = +ss > 59 ? +mm + 1 : mm;
  let hours = +hh > 24 ? 0 : hh;

  if (hh && mm && ss && ms) {
    return `${hours}:${minutes}:${seconds}.${miliseconds}`;
  } else if (mm && ss && ms){
    return `${minutes}:${seconds}.${miliseconds}`;
  } else if (ss && ms){
    return `${seconds}.${miliseconds}`;
  } else if (ms){
    return `${miliseconds}`;
  } else {
    return formatted;
  }
};

type fillEmptyStrConfig = {
  separator?: string;
  emptyText?: string;
};

export const fillEmptyStr = (
  ...list: (undefined | null | string | boolean | fillEmptyStrConfig | number)[]
): string => {
  let config = {
    separator: '',
    emptyText: 'N/A',
  };
  const last = list[list.length - 1];

  if (last instanceof Object) {
    config = { ...config, ...last };
  }

  const str = list.reduce((prev, curr, index) => {
    const needSeparator = index !== 0 && index !== list.length;

    return curr && !(curr instanceof Object)
      ? `${prev}${needSeparator ? config.separator : ''}${curr}`
      : prev;
  }, '') as string;

  return str.length > 0 ? str.trim() : config.emptyText;
};
