import { makeStyles, Theme, createStyles } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
    createStyles({
        demo: {
            backgroundColor: theme.palette.background.paper,
        },
        root: {
            display: 'flex',
            justifyContent: 'flex-end',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
        previewImg: {
            marginRight: 15,
            width: 50,
            height: 50,
            objectFit: 'cover',
        },
        hideItem: {
            display: 'none',
        }
    })
);
