import React from 'react';
import { Typography, Box } from '@material-ui/core';

export default function PageNotFound() {
    return (
        <Box mt={8}>
            <Typography component="h1" variant="h1" align="center">
                Page not found
            </Typography>
        </Box>
    );
}
