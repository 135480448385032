import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import useDebounce from '../../../hooks/use-debounce';
import qs from 'qs';
import AutocompleteText from 'components/UGC/ugc-popup/autocomplete-text';
import { useCreateStory, useStories } from 'api/admin/event-story/stories';

type PropsType = {
    name: string;
};

const AutocompleteStoriesField: React.FC<PropsType> = ({ name }) => {
    const form = useForm();
    const formState = form.getState();

    const stories = useStories();
    const createStory = useCreateStory();

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (formState.values.event_id) {
            const paramsObj = {
                q: {
                    event_id_in: formState.values.event_id
                        ? [formState.values.event_id.id]
                        : null,
                    active_eq: true,
                },
                search: debouncedSearchTerm,
            };
            const filterParamsStr = qs.stringify(paramsObj, {
                encode: false,
                arrayFormat: 'brackets',
                addQueryPrefix: true,
            });

            stories.query(filterParamsStr).then((result) => {
                if (
                    result.payload !== undefined &&
                    formState.values.story_ids
                ) {
                    const optionsStories = result.payload.stories.map(
                        (item) => item.id
                    );
                    if (
                        !optionsStories.includes(formState.values.story_ids.id)
                    ) {
                        form.change('story_ids', undefined);
                    }
                }
            });
        } else {
            if (formState.values.event_id === null) {
                form.change('story_ids', undefined);
            }
            const params = qs.stringify(
                {
                    search: debouncedSearchTerm,
                    q: {
                        active_eq: true,
                    },
                },
                {
                    encode: false,
                    arrayFormat: 'brackets',
                    addQueryPrefix: true,
                }
            );

            stories.query(params);
        }
    }, [debouncedSearchTerm, formState.values.event_id]);

    const changeStory = React.useCallback(
        async (newValue: any) => {
            if (newValue.inputValue) {
                const { payload, error } = await createStory({
                    title: newValue.inputValue,
                    event_id: formState.values.event_id.id,
                });
                return payload.story;
            } else {
                return newValue;
            }
        },
        [createStory, formState.values.event_id]
    );

    const setSearchValue = React.useCallback(
        (newValue: any) => {
            setSearchTerm(newValue);
        },
        [setSearchTerm]
    );

    const dataStories =
        stories.payload !== undefined ? stories.payload.stories : [];

    return (
        <AutocompleteText
            name={name}
            title="Story"
            options={dataStories}
            onChange={changeStory}
            setSearchValue={setSearchValue}
        />
    );
};
export default AutocompleteStoriesField;
