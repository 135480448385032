import createQueryContext from 'react-fetching-library-context';

export function getCurrentAdminAction() {
    return {
        method: 'GET',
        endpoint: '/api/v1/admin/current_admin.json',
    };
}

export const {
    useQueryContext: useCurrentAdmin,
    Provider: CurrentAdminProvider,
} = createQueryContext<any, any>(getCurrentAdminAction);

export function useIsAuthorized() {
    const user = useCurrentAdmin();

    return user.status === 200;
}
