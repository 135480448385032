import React from 'react';
import { Grid, List, Typography } from '@material-ui/core';
import { useUserProfileStyles } from './user-profile.styles';
import { UserType } from '../../api/admin/users/users.types';
import { fillEmptyStr } from 'helpers/strings';
import { Country } from 'api/admin/countries/countries.types';

type DataType = {
    label: string;
    value: string;
};

type UserDataProps = {
    data: UserType | undefined;
};

const UserPersonalData: React.FC<UserDataProps> = ({ data }) => {
    const classes = useUserProfileStyles();

    if (!data) {
        return null;
    }

    function getPersonalData() {
        return [
            {
                label: 'First name',
                value: fillEmptyStr(data?.first_name),
            },
            {
                label: 'Last name',
                value: fillEmptyStr(data?.last_name),
            },
            {
                label: 'Phone number',
                value: fillEmptyStr(data?.phone),
            },
            { label: 'E-mail', value: fillEmptyStr(data?.email) },
        ];
    }

    function getBillingAddressData() {
        return [
            {
                label: 'Country',
                value: fillEmptyStr(data?.country_name),
            },
            {
                label: 'Zip',
                value: fillEmptyStr(data?.zip_code),
            },
            {
                label: 'City',
                value: fillEmptyStr(data?.city),
            },
            {
                label: 'Address',
                value: fillEmptyStr(data?.address),
            },
        ];
    }

    function getPaymentInfData() {
        return [
            { label: 'Paypal', value: fillEmptyStr(data?.paypal_email) },
            {
                label: 'Bank',
                value: fillEmptyStr(data?.bank),
            },
            {
                label: 'Account number',
                value: fillEmptyStr(data?.account_number),
            },
            {
                label: 'IBAN',
                value: fillEmptyStr(data?.iban),
            },
            {
                label: 'SWIFT',
                value: fillEmptyStr(data?.swift),
            },
            {
                label: 'Additional info',
                value: fillEmptyStr(data?.additional_info),
            },
        ];
    }

    const personalData: Array<DataType> = getPersonalData();
    const billingData: Array<DataType> = getBillingAddressData();
    const paymentData: Array<DataType> = getPaymentInfData();

    return (
        <Grid container className={classes.content}>
            <Grid item xs={4}>
                <BlockData title="Personal data" blockData={personalData} />
            </Grid>
            <Grid item xs={4}>
                <BlockData title="Billing Address" blockData={billingData} />
            </Grid>
            <Grid item xs={4}>
                <BlockData
                    title="Payment information"
                    blockData={paymentData}
                />
            </Grid>
        </Grid>
    );
};
export default UserPersonalData;

type BlockDataProps = {
    title: string;
    blockData: Array<DataType>;
};

const BlockData: React.FC<BlockDataProps> = ({ title, blockData }) => {
    const classes = useUserProfileStyles();
    return (
        <>
            <Typography variant="h6" className={classes.title}>
                {title}
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <List>
                        {blockData.map(({ label }) => (
                            <Typography className={classes.labelList}>
                                {label}
                            </Typography>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List>
                        {blockData.map(({ value }) => (
                            <Typography>{value}</Typography>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </>
    );
};
