import { Action } from 'react-fetching-library';
import { IImageList, IDeleteActionParams } from './images.types';

export function getUploadImagesOfEntryAction({
    id,
    body,
}: {
    id: number;
    body: IImageList;
}): Action {
    return {
        method: 'POST',
        endpoint: `​/admin​/ugc​/entries​/${id}​/images`,
        body,
    };
}

export function getDeleteImageOfEntryAction({
    id,
    entry_id,
}: IDeleteActionParams): Action {
    return {
        method: 'DELETE',
        endpoint: `/api/v1/admin/ugc/entries/${entry_id}/images/${id}`,
    };
}
