import React from 'react';
import Events from "./events-stories";
import {StoriesProvider} from "../../api/admin/event-story/stories";
import {EventsProvider} from "../../api/admin/event-story/events";

const EventsPage: React.FC = () => {
    return (
        <EventsProvider initFetch={false}>
            <StoriesProvider initFetch={false}>
                <Events/>
            </StoriesProvider>
        </EventsProvider>
    )
}
export default EventsPage
