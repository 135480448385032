import {EventType} from "../../api/admin/event-story/events.types";
import {StoryType} from "../../api/admin/event-story/stories.types";
import {
    ActionsTypes,
    SET_EVENTS,
    SET_OPEN_STORIES,
    SET_STORIES
} from "./actions";

type EventsStoriesType = {
    events: Array<EventType>
    data_stories: {
        [key: string]: { stories: Array<StoryType>, openStories: boolean }
    }
}

let initialState: EventsStoriesType = {
    events: [],
    data_stories: {}
}

const eventsStoriesReducer =
    (state = initialState, action: ActionsTypes): EventsStoriesType => {

        switch (action.type) {
            case SET_EVENTS:
                return {
                    ...state,
                    events: action.events
                };
            case SET_STORIES:
                return {
                    ...state,
                    data_stories: {
                        ...state.data_stories,
                        [action.event_id.toString()]:
                            {
                                stories: [...action.stories],
                                openStories: action.openStories
                            }
                    }
                };
            case SET_OPEN_STORIES:
                const event_id: string = action.event_id.toString();
                return {
                    ...state,
                    data_stories: {
                        ...state.data_stories,
                        [event_id]:
                            {
                                stories: [...state.data_stories[event_id].stories],
                                openStories: action.openStories
                            }
                    }
                };
            default:
                return state;
        }
    };

export default eventsStoriesReducer;