import React, {useState} from "react";
import {TextField} from "@material-ui/core";
import {useField, useForm} from "react-final-form";

type PropsType = {
    name: string
    reset: boolean
    setReset: (flag: boolean) => void
}

const SearchField: React.FC<PropsType> = ({name, reset, setReset}) => {
    const fromField = useField(name);
    const form = useForm();
    const [valueSearch, setvalueSearch] = useState<string>(fromField.input.value);

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            form.change(name, valueSearch);
            form.submit();
        }
    };

    return (
        <TextField
            value={!reset ? valueSearch : ''}
            onKeyPress={onKeyPress}
            onChange={(ev) => {
                setvalueSearch(ev.target.value)
                setReset(false)
            }}
            variant="outlined"
            size="small"
            fullWidth
        />
    )
}
export default SearchField