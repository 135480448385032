import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IPayloadURLScreenshots } from 'api/admin/ugc/entries.types';
import { IDeleteActionParams } from 'api/admin/ugc/images.types';
import { AddImages } from 'components/screenshot';
import { TIMECODE_FORMAT } from 'constants/common';
import { fillEmptyStr, formatContentTiming } from 'helpers/strings';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import useStyles from './image-upload.styles';

interface IUGCImageUploadOfURLProps {
  id: number;
  index: number;
  isUpdate: boolean;
  isCanNotEditDataUrl: boolean;
}

export default function UGCImageUploadOfURL(props: IUGCImageUploadOfURLProps) {
  const form = useForm();
  const classes = useStyles();
  const id = `${props.id}-button-file-${props.index}`;
  const formState = form.getState();
  const name = `urls_attributes[${props.index}][images]`;
  const extData = `urls_attributes[${props.index}][extData]`;
  const attrList = formState.values.urls_attributes;
  const required = (value: string) => (value ? undefined : 'Required');

  const imgList: any[] =
    (attrList.length > 0 &&
      attrList[props.index] &&
      attrList[props.index].images) ||
    [];

  function deleteImgHandler(index: number): void {
    form.mutators.removeScreenshot(name, index);
  }

  async function deleteImgFromServerHandler(
    params: IDeleteActionParams,
    index: number
  ): Promise<void> {
    deleteImgHandler(index);
  }

  function delImg(isFile: boolean, index: number, img: IPayloadURLScreenshots) {
    if (isFile) {
      deleteImgHandler(index);
    } else {
      deleteImgFromServerHandler(
        {
          id: img.id,
          entry_id: formState.values.id,
        },
        index
      );
    }
  }

  return (
    <>
      <div className={classes.root}>
        <AddImages name={name} id={id} images={imgList} />
        {!props.isCanNotEditDataUrl && (
          <label htmlFor={id}>
            <Button variant="text" color="primary" component="span">
              Add screenshots
            </Button>
          </label>
        )}
      </div>
      <div className={classes.demo}>
        <List dense={true}>
          {imgList &&
            imgList.map((img, index) => {
              const isFile = img instanceof File;
              let previewURL: string;

              if (isFile) {
                previewURL = URL.createObjectURL(img as File);
              } else {
                previewURL = fillEmptyStr((img as IPayloadURLScreenshots).link);
              }
              return (
                <ListItem
                  key={isFile ? img.name : previewURL}
                  className={img._destroy ? classes.hideItem : ''}
                >
                  <img
                    src={previewURL}
                    alt={previewURL}
                    className={classes.previewImg}
                  />
                  <Field
                    validate={required}
                    render={({ input, meta }) => (
                      <TextField
                        error={meta.error && meta.touched}
                        disabled={props.isCanNotEditDataUrl}
                        {...input}
                        label={`Time (${TIMECODE_FORMAT})`}
                      />
                    )}
                    name={`${
                      isFile ? extData : name
                    }[${index}][content_timing]`}
                    parse={formatContentTiming}
                  />
                  <ListItemSecondaryAction
                    className={
                      img._destroy || props.isCanNotEditDataUrl
                        ? classes.hideItem
                        : ''
                    }
                  >
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        delImg(isFile, index, img as IPayloadURLScreenshots)
                      }
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      </div>
    </>
  );
}
