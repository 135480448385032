import {Action} from "react-fetching-library";
import {UserProfileResponseType, UsersResponseType} from "./users.types";

export function getUsersAction(params: string): Action<UsersResponseType> {
    return {
        method: "GET",
        endpoint: `/api/v1/admin/users${params}`,
    };
}

export function getUserProfileAction(id: number): Action<UserProfileResponseType> {
    return {
        method: "GET",
        endpoint: `/api/v1/admin/users/${id}`,
    };
}

// export function updateUserAction(id: string): Action {
//     return {
//         method: "PATCH",
//         endpoint: `/api/v1/admin/users/${id}`,
//         body: {
//             id: id,
//         },
//     };
// }