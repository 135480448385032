import React, {useEffect, useState} from "react";
import {useForm} from "react-final-form";
import useDebounce from "../../../../hooks/use-debounce";
import qs from "qs";
import AutocompleteTextField from "./autocomplete-text-field";
import {useParameterizedQuery} from "react-fetching-library";
import {getStoriesAction} from "../../../../api/admin/event-story/story.actions";

type PropsType = {
    name: string
}
const AutocompleteStories: React.FC<PropsType> = ({name}) => {
    const form = useForm();
    const formState = form.getState()
    const {payload, query} = useParameterizedQuery(getStoriesAction);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(
        () => {
            if (formState.values.event_id) {
                const paramsObj = {
                    q: {
                        event_id_in: formState.values.event_id ?
                            [formState.values.event_id.id] : null
                    },
                    search: debouncedSearchTerm
                }
                const filterParamsStr = qs.stringify(paramsObj,
                    {
                        encode: false,
                        arrayFormat: 'brackets',
                        addQueryPrefix: true
                    })

                query(filterParamsStr).then((result) => {
                    if (result.payload !== undefined
                        && formState.values.story_ids) {

                        const optionsStories =
                            result.payload.stories.map((item) => item.id)
                        if (!optionsStories.includes(
                            formState.values.story_ids.id)) {
                            form.change('story_ids', undefined)
                        }
                    }
                })
            } else {
                if (formState.values.event_id === null) {
                    form.change('story_ids', undefined)
                }

                query(`?search=${debouncedSearchTerm}`)
            }
        },
        [debouncedSearchTerm, formState.values.event_id]
    );

    const setSearchValue = React.useCallback((newValue: any) => {
        setSearchTerm(newValue)
    }, [setSearchTerm])

    const dataStories = payload !== undefined ?
        payload.stories : []

    return (
        <AutocompleteTextField name={name} title="Story" options={dataStories}
                               setSearchValue={setSearchValue}/>
    )
}
export default AutocompleteStories