import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useStyles } from './view-image.styles';
import clsx from 'clsx';
import ClickOutside from 'components/outside-click';

interface IViewImageProps {
    link: string;
    className?: string;
}

export default function ViewImage({ link, className }: IViewImageProps) {
    const classes = useStyles();
    const [openViewImage, setOpenViewImage] = useState('');
    const isOpen = Boolean(openViewImage);

    const handleOpen = () => {
        setOpenViewImage(link);
    };

    const handleClose = () => {
        setOpenViewImage('');
    };

    return (
        <>
            <button className="btn-clear" type="button" onClick={handleOpen}>
                <img
                    className={className || classes.image}
                    src={link}
                    alt={link}
                />
            </button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.imageView}>
                    <button
                        type="button"
                        className={clsx(
                            'btn-clear',
                            `${classes.imageView}__close`
                        )}
                    >
                        &#10006;
                    </button>
                    <Fade in={isOpen}>
                        <ClickOutside onClick={handleClose}>
                            <img
                                src={link}
                                alt={link}
                                className={`${classes.imageView}__img`}
                            />
                        </ClickOutside>
                    </Fade>
                </div>
            </Modal>
        </>
    );
}
