import React from 'react';
import { useUpdateEvent } from '../../../../api/admin/event-story/events';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { EventType } from 'api/admin/event-story/events.types';
import TxtField from '../../../../components/UGC/ugc-popup/text-field';

type PropsType = {
    onClose: () => void;
    data: EventType;
};
const EventRename: React.FC<PropsType> = ({ data, onClose }) => {
    const updateEvent = useUpdateEvent();

    if (!data) {
        return null;
    }

    async function onSubmit(formData: any) {
        await updateEvent({ id: data.id, title: formData.title });
        onClose();
    }

    return (
        <Form onSubmit={onSubmit} initialValues={{ title: data.title }}>
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle id="form-dialog-title">
                            Rename event
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {`You are about to give another name to 
                                 event "${data && data?.title}".`}
                            </DialogContentText>
                            <Box mb={1}>Change name to:</Box>
                            <TxtField name="title" />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                            >
                                Rename
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        </Form>
    );
};
export default EventRename;
