import React from "react";
import {makeStyles, TextField, Typography} from "@material-ui/core";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {useField, useForm} from "react-final-form";

const filter = createFilterOptions();

type PropsType = {
    name: string;
    title: string;
    options: Array<any>;
    onChange: (item: any) => Promise<any>;
    setSearchValue: (value: string) => void;
}

const AutocompleteText: React.FC<PropsType> = ({
    name,
    options = [],
    onChange,
    setSearchValue,
    title,
}) => {

        const classes = useStyles();
        const form = useForm();
        const fromField = useField(name);

        const handleChange = (event: any, value: any) => {
            if (value && value.inputValue) {
                onChange(value).then((newValue) => {
                    fromField.input.onChange(newValue)
                })
            } else {
                fromField.input.onChange(value)
            }
        }

        const handleClose = (event: any, value: any) => {
            if (value === '') {
                setSearchValue('')
            }
        }

        return (
            <div className={classes.content}>
                <Autocomplete
                    value={fromField.input.value}
                    onChange={handleChange}
                    onInputChange={handleClose}
                    size='small'
                    options={options}
                    groupBy={(option) => option.event_title}
                    getOptionSelected={(option: any, value) => {
                        if (value.inputValue) {
                            return option.title === value.inputValue;
                        }
                        return (option.id === value.id);
                    }}
                    getOptionLabel={(option) => {
                        return option.inputValue || option.title || ''
                    }}
                    renderOption={(option) => {
                        if (option.inputValue) {
                            return (
                                <div
                                    className={classes.firstOption}>
                                    <Typography>
                                        {option.title}
                                    </Typography>
                                    <AddCircleOutlineIcon
                                        className={classes.icon}/>
                                </div>
                            )
                        } else {
                            return <Typography>{option.title}</Typography>
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        let isErrorAdd: boolean = true;
                        const isStoryErrorAdd: boolean = name === 'story_ids'
                            && !Boolean(form.getState().values.event_id)

                        const optionsTitle = options.map((item) => item.title)
                        const isEventErrorAdd: boolean =
                            optionsTitle.includes(params.inputValue)

                        isErrorAdd = isStoryErrorAdd || isEventErrorAdd

                        if (!isErrorAdd && params.inputValue !== '') {
                            filtered.unshift({
                                inputValue: params.inputValue,
                                title: `"${params.inputValue}" Create new ${title}`
                            })
                        }
                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField {...params}
                                   variant="outlined"
                                   error={fromField.meta.error}
                                   placeholder={`Start typing the name of ${title}`}
                                   onChange={e => setSearchValue(e.target.value)}
                        />
                    )}
                />
                {/*{fromField.meta.error &&*/}
                {/*<div style={{color: 'red'}}>{fromField.meta.error}</div>}*/}
            </div>
        )
    }
export default React.memo(AutocompleteText)

const useStyles = makeStyles({
    content: {
        padding: 0,
    },
    firstOption: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingRight: "20px",
        paddingBottom: "10px",
        fontWeight: 600,
        borderBottom: "1px solid",
        width: "100%",
    },
    icon: {
        marginLeft: "15px",
    },
    error: {
        border: "1px solid red",
    },
});