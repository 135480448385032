import { makeStyles } from '@material-ui/core/styles';
import arrayMutators from 'final-form-arrays';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import FilterBlock from '../../../components/filter/filter-block';
import EventsFields from './events-filter';
import StoriesFields from './story-filter';

export type FilterFormData = { event_id_in: string[]; stories_id_in: string[] };

type PropsType = {
    onSubmit: (values: FilterFormData) => void;
    filterData: FilterFormData;
};

type InitialFilter = 'event' | 'story';

export type SearchProps = {
    searchBy?: InitialFilter;
    searchByHandle: (searchBy: InitialFilter) => void;
};

const FilterEventStory: React.FC<PropsType> = ({ onSubmit, filterData }) => {
    const classes = useStyles();
    const [searchBy, setSearchBy] = useState<InitialFilter>('event');

    const searchByHandle = useCallback((searchBy: InitialFilter) => {
        setSearchBy(searchBy);
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            mutators={{ ...arrayMutators }}
            initialValues={{
                event_id_in: filterData.event_id_in || [],
                stories_id_in: filterData.stories_id_in || [],
            }}
        >
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <div className={classes.box}>
                            <FilterBlock title="Event:">
                                <EventsFields
                                    name="event_id_in"
                                    searchByHandle={searchByHandle}
                                />
                            </FilterBlock>
                        </div>
                        <div className={classes.box}>
                            <FilterBlock title="Story:">
                                <StoriesFields
                                    name="stories_id_in"
                                    searchByHandle={searchByHandle}
                                    searchBy={searchBy}
                                />
                            </FilterBlock>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};
export default FilterEventStory;

const useStyles = makeStyles({
    form: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    box: {
        width: '50%',
    },
});
