import React, { useState } from 'react';
import { Dialog, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { StoryType } from '../../../api/admin/event-story/stories.types';
import StoryMergeStory from './story-actions/story-merge-story';
import StoryMergeEvent from './story-actions/story-merge-event';
import StoryRename from './story-actions/story-rename';
import { actions } from 'reducers/events-stories/actions';
import { useStories, useUpdateStory } from 'api/admin/event-story/stories';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import qs from 'qs';

type PropsType = {
    row: StoryType;
    onError: (msg: string) => void;
};

const RowStoryAction: React.FC<PropsType> = ({ row, onError }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [popupStoryMergeStory, setPopupStoryMergeStory] = useState<any>(null);
    const [popupStoryMergeEvent, setPopupStoryMergeEvent] = useState<any>(null);
    const [popupStoryRename, setPopupStoryRename] = useState<any>(null);
    const updateStory = useUpdateStory();
    const { data_stories } = useSelector(
        (store: AppStateType) => store.dataEventsStories
    );
    const stories = useStories();
    const dispatch = useDispatch();

    const onClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const onMerge = () => {
        setPopupStoryMergeStory(row);
        setAnchorEl(null);
    };

    const onMove = () => {
        setPopupStoryMergeEvent(row);
        setAnchorEl(null);
    };

    const onRename = () => {
        setPopupStoryRename(row);
        setAnchorEl(null);
    };

    async function onToggleActiveHandler() {
        const { status } = await updateStory({
            id: row.id,
            title: row.title,
            event_id: row.event_id,
            active: !row.active,
        });

        if (status && data_stories[row.event_id.toString()].openStories) {
            const filterParamsStr = qs.stringify(
                { q: { event_id_in: [row.event_id] } },
                {
                    encode: false,
                    arrayFormat: 'brackets',
                    addQueryPrefix: true,
                }
            );
            const { payload } = await stories.query(filterParamsStr);
            if (payload && payload.stories) {
                dispatch(
                    actions.setStories(payload.stories, row.event_id, true)
                );
            }
        }
        setAnchorEl(null);
    }

    return (
        <>
            <div>
                <IconButton aria-label="more" onClick={onClick}>
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={onRename}>Rename</MenuItem>
                    <MenuItem onClick={onMerge}>Remove and replace</MenuItem>
                    <MenuItem onClick={onMove}>Move to another Event</MenuItem>
                    <MenuItem onClick={onToggleActiveHandler}>
                        {row.active ? 'Disactivate' : 'Activate'}
                    </MenuItem>
                </Menu>
            </div>
            <Dialog
                open={popupStoryMergeStory}
                fullWidth
                onClose={() => setPopupStoryMergeStory(null)}
            >
                <StoryMergeStory
                    data={popupStoryMergeStory}
                    onClose={() => setPopupStoryMergeStory(null)}
                />
            </Dialog>

            <Dialog
                open={popupStoryMergeEvent}
                fullWidth
                onClose={() => setPopupStoryMergeEvent(null)}
            >
                <StoryMergeEvent
                    data={popupStoryMergeEvent}
                    onClose={() => setPopupStoryMergeEvent(null)}
                    onError={onError}
                />
            </Dialog>

            <Dialog
                open={popupStoryRename}
                fullWidth
                onClose={() => setPopupStoryRename(null)}
            >
                <StoryRename
                    data={popupStoryRename}
                    onClose={() => setPopupStoryRename(null)}
                />
            </Dialog>
        </>
    );
};
export default RowStoryAction;
