// import { useUGCEntry } from 'api/admin/ugc/entries';
import * as actions from './images.actions';
import { useMutation } from 'react-fetching-library';
import { IImageList, IDeleteActionParams } from './images.types';
import { useUGCEntries } from './entries';

export function useUploadImagesOfEntryAction() {
    const uploadAPI = useMutation(actions.getUploadImagesOfEntryAction);
    const list = useUGCEntries();

    return async function (params: { id: number; body: IImageList }) {
        const result = await uploadAPI.mutate(params);
        try {
            await list.query(null);
        } catch (err) {}

        return result;
    };
}

export function useDeleteImageOfEntryAction(updType?: 'entry') {
    const deleteAPI = useMutation(actions.getDeleteImageOfEntryAction);
    const list = useUGCEntries();
    // const entry = useUGCEntry();

    return async function (params: IDeleteActionParams) {
        await deleteAPI.mutate(params);
        if (updType === 'entry') {
            return null;
        }
        return list.query('');
    };
}
