import createQueryContext from "react-fetching-library-context";
import {Country} from "./countries.types";
import {getCountriesAction, updateCountryAction} from "./countries.actions";
import {useMutation} from "react-fetching-library";

export const {
    Provider: CountriesProvider,
    useQueryContext: useCountries,
} = createQueryContext<Country[], any>(getCountriesAction);

export const useUpdateCountry = () => useMutation(updateCountryAction);
