import React, { useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import SearchBar from '../../components/searchBar/search-bar';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import EventsStoriesTable from './table/events-stories-table';
import { EventStoryRoute } from '../../config/routes';
import qs from 'qs';
import { useEvents } from '../../api/admin/event-story/events';
import { AppStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'reducers/events-stories/actions';
import { useAppStyles } from 'app.styles';

const Events: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();
    const { events: dataEvents } = useSelector(
        (store: AppStateType) => store.dataEventsStories
    );
    const commonClasses = useAppStyles();

    const [valueSearchBar, setValueSearchBar] = useState<string>(
        getSearchValueFromUrl()
    );

    const events = useEvents();

    React.useEffect(() => {
        events.query(location.search);
    }, [location.search]);

    React.useEffect(() => {
        if (events.payload && events.payload.events) {
            dispatch(actions.setEvents(events.payload.events));
        }
    }, [events.payload]);

    function getSearchValueFromUrl() {
        const obj = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (obj.search) return obj.search.toString();
        else return '';
    }

    const handleSearch = React.useCallback(() => {
        if (valueSearchBar !== '') {
            const filterParamsStr: string = qs.stringify(
                { search: valueSearchBar },
                {
                    encode: false,
                    arrayFormat: 'brackets',
                    addQueryPrefix: true,
                }
            );
            history.push(EventStoryRoute.getLink() + filterParamsStr);
        } else history.push(EventStoryRoute.getLink());
    }, [valueSearchBar]);

    const handleChange = React.useCallback(
        (newValue: string) => {
            setValueSearchBar(newValue);
            if (newValue === '') {
                history.push(EventStoryRoute.getLink());
            }
        },
        [setValueSearchBar]
    );

    return (
        <Container maxWidth="xl" className={commonClasses.container}>
            <Grid container spacing={4} className={classes.container}>
                <Grid item xs={12}>
                    <SearchBar
                        value={valueSearchBar}
                        placeholder="Event name"
                        onSubmit={handleSearch}
                        changeValue={handleChange}
                    />
                    <EventsStoriesTable dataEvents={dataEvents} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default withRouter(Events);

const useStyles = makeStyles({
    container: {
        paddingTop: 30,
    },
});
