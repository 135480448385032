import React from 'react';
import ReactDOM from 'react-dom';
import './configure-i18n';
import client from './configure-client-context';

import App from './App';
import { ClientContextProvider } from 'react-fetching-library';
import { CurrentAdminProvider } from 'api/admin/current_admin';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { CountriesProvider } from 'api/admin/countries';
import { VerificationsProvider } from 'api/admin/verifications';
import { Provider } from 'react-redux';
import store from './store';

const theme = createMuiTheme({
    palette: {
        // type: "dark",
        primary: {
            // Purple and green play nicely together.
            main: '#77bc1f',
            dark: '#212425',
            contrastText: '#FFFFFF',
        },
        success: {
            // Purple and green play nicely together.
            main: '#77bc1f',
            contrastText: '#FFFFFF',
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#e71d1d',
        },
        error: {
            main: '#e71d1d',
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <ClientContextProvider client={client}>
                    <CurrentAdminProvider initFetch>
                        <CountriesProvider>
                            <VerificationsProvider>
                                <App />
                            </VerificationsProvider>
                        </CountriesProvider>
                    </CurrentAdminProvider>
                </ClientContextProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
