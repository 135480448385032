import { FormControl, TextField, TextFieldProps } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useField } from 'react-final-form';

const numbReg = new RegExp('^[0-9]+$');

const TxtField: React.FC<TextFieldProps & { onlyNumbers?: boolean }> = ({
    name = '',
    label,
    disabled,
    type = 'text',
    onlyNumbers,
}) => {
    const fromField = useField(name);

    const onChangeHandle = useCallback(
        ({
            target: { value },
        }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (
                (onlyNumbers && numbReg.test(value)) ||
                (onlyNumbers && !value)
            ) {
                fromField.input.onChange(value);
            } else if (!onlyNumbers) {
                fromField.input.onChange(value);
            }
        },
        [onlyNumbers]
    );

    return (
        <FormControl style={{ minWidth: '100%' }}>
            <TextField
                value={fromField.input.value}
                onChange={onChangeHandle}
                label={label}
                fullWidth
                disabled={disabled}
                type={type}
            />
        </FormControl>
    );
};
export default TxtField;
