import React from 'react';
import { DateTime } from 'luxon';
import { useField, useForm } from 'react-final-form';
import { Grid, TextField } from '@material-ui/core';

interface DateColumnProps {
    onChange: (day: Date) => void;
    value?: string;
}

interface IFilterDateProps {
    fromDateName: string;
    toDateName: string;
}

export default function FilterDate(props: IFilterDateProps) {
    const form = useForm();
    const fromField = useField(props.fromDateName);
    const toField = useField(props.toDateName);

    function onChangeFrom(date: Date) {
        form.change(fromField.input.name, DateTime.fromJSDate(date).toJSDate());
        form.submit();
    }
    function onChangeTo(date: Date) {
        form.change(
            toField.input.name,
            DateTime.fromJSDate(date)
                .set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                })
                .toJSDate()
        );
        form.submit();
    }

    return (
        <Grid container spacing={1}>
            <DateColumn value={fromField.input.value} onChange={onChangeFrom} />
            <DateColumn value={toField.input.value} onChange={onChangeTo} />
        </Grid>
    );
}

function DateColumn({ value, onChange }: DateColumnProps) {
    const val = value
        ? DateTime.fromJSDate(new Date(value)).toFormat('yyyy-MM-dd')
        : '';
    return (
        <Grid item xs={6}>
            <TextField
                variant="outlined"
                type="date"
                inputProps={{ style: { fontSize: 12 } }}
                // className="date__text"
                value={val}
                onChange={(ev) => {
                    onChange(new Date(ev.target.value));
                }}
                fullWidth
                size="small"
            />
        </Grid>
    );
}
