import React from 'react';
import { useField, useForm } from 'react-final-form';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

export function ExclusiveTypeField() {
    const fromField = useField('exclusive_type');
    const form = useForm();

    return (
        <FormControl style={{ minWidth: '100%' }}>
            <InputLabel>Exclusive type</InputLabel>
            <Select
                value={fromField.input.value}
                onChange={(ev) =>
                    form.change('exclusive_type', ev.target.value)
                }
                label="Exclusive type"
                fullWidth
            >
                <MenuItem value="exclusive">Exclusive</MenuItem>
                <MenuItem value="non_exclusive">Nonexclusive</MenuItem>
            </Select>
        </FormControl>
    );
}
