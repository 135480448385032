import React, {useEffect, useState} from "react";
import useDebounce from "../../../../hooks/use-debounce";
import {useForm} from "react-final-form";
import qs from "qs";
import AutocompleteTextField from "./autocomplete-text-field";
import {useParameterizedQuery} from "react-fetching-library";
import {getEventsAction} from "../../../../api/admin/event-story/event.actions";

type PropsType = {
    name: string
}
const AutocompleteEvents: React.FC<PropsType> = ({name}) => {
    const form = useForm();
    const formState = form.getState()
    const {payload, query} = useParameterizedQuery(getEventsAction);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(
        () => {
            if (formState.values.story_ids !== undefined &&
                !formState.values.event_id) {

                const eventParamsStr =
                    qs.stringify({
                            q: {
                                id_in: formState.values.story_ids ?
                                    [formState.values.story_ids.event_id] : null
                            }
                        },
                        {
                            encode: false,
                            arrayFormat: 'brackets',
                            addQueryPrefix: true
                        })

                query(eventParamsStr)
                    .then((results) => {
                        if (!results.error && results.payload !== undefined) {
                            if (results.payload.events.length === 1) {
                                form.change('event_id', results.payload.events[0])
                            } else {
                                form.change('event_id', undefined)
                            }
                        }
                    })
            } else {
                query(`?search=${debouncedSearchTerm}`)
            }
        },
        [debouncedSearchTerm, formState.values.story_ids]
    );

    const setSearchValue = React.useCallback((newValue: any) => {
        setSearchTerm(newValue)
    }, [setSearchTerm])

    const dataEvents = payload !== undefined ?
        payload.events : []

    return (
        <AutocompleteTextField name={name} title="Event" options={dataEvents}
                               setSearchValue={setSearchValue}/>
    )
}
export default AutocompleteEvents