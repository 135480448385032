import * as actions from './event.actions';
import createQueryContext from 'react-fetching-library-context';
import { useMutation } from 'react-fetching-library';
import {
    EventParamsType,
    EventsResponseType,
    EventUpdateActionParams,
} from './events.types';

export const {
    useQueryContext: useEvents,
    Provider: EventsProvider,
} = createQueryContext<EventsResponseType>(actions.getEventsAction);

export function useCreateEvent() {
    const createEvent = useMutation(actions.createEventAction);
    const eventsList = useEvents();

    return async function (params: EventParamsType) {
        const result = await createEvent.mutate(params);
        try {
            await eventsList.query('');
        } catch (err) {}

        return result;
    };
}

export function useUpdateEvent() {
    const updateEvent = useMutation(actions.updateEventAction);
    const eventsList = useEvents();

    return async function (params: EventUpdateActionParams) {
        await updateEvent.mutate(params);
        return eventsList.query('');
    };
}

export function useDeleteEvent() {
    const deleteEvent = useMutation(actions.deleteEventAction);
    const eventsList = useEvents();

    return async function (id: number) {
        await deleteEvent.mutate(id);
        return eventsList.query('');
    };
}

export function useMergeEvent() {
    const mergeEvent = useMutation(actions.mergeEventAction);
    const eventsList = useEvents();

    return async function (params: { id: number; id_merge: number }) {
        await mergeEvent.mutate(params);
        return eventsList.query('');
    };
}
