import React from "react";
import { Box, Grid, Typography, Link } from "@material-ui/core";
import {
  EntryContentStatus,
  ImageList,
  UGCEntryURL,
} from "api/admin/ugc/entries.types";
import { useRequestStyles } from "../ugc-request.styles";
import { Form, Field } from "react-final-form";
import {
  toggleUserConfirmContent,
  useUGCContentStatus,
  useUpdateEntryURL,
} from "api/admin/ugc/entries";
import { fillEmptyStr, formatOnlyNumbers } from "helpers/strings";
import clsx from "clsx";
import { TIMING_FORMAT } from "constants/common";
import { Alert } from "@material-ui/lab";
import UGCUserContentActions from "./user-content-actions";
import UGCContentScreenshots from "./ugc-screenshots";
import { useDeleteImageOfEntryAction } from "api/admin/ugc/images";

interface IUGCUserURL {
  url: UGCEntryURL;
  withControls: boolean;
  disabledEdit: boolean;
  contentConfirmed: boolean;
  changeContentEditStatus: (list: number[]) => void;
  editList: number[];
}

function UGCUserURL({
  url,
  withControls,
  disabledEdit,
  contentConfirmed,
  changeContentEditStatus,
  editList,
}: IUGCUserURL) {
  const classes = useRequestStyles();
  const updateAPI = useUpdateEntryURL();
  const statusAPI = useUGCContentStatus();
  const imagesAPI = useDeleteImageOfEntryAction("entry");
  const isEdit = editList.includes(url.id as number);
  const contentStatusAPI = toggleUserConfirmContent();

  function onUpdateStatus() {
    const delIndex = editList.findIndex((i) => i === url.id);
    editList.splice(delIndex, 1);
    changeContentEditStatus([...editList]);
  }

  async function changeStatusReq(accepted_status: EntryContentStatus) {
    return await statusAPI({
      entry_id: url.entry_id as number,
      content_id: url.id as number,
      type: "url",
      accepted_status,
    });
  }

  async function updateStatus(
    accepted_status: EntryContentStatus,
    isEdit?: boolean
  ) {
    if (accepted_status === "on_review") {
      editList.push(url.id as number);
      changeContentEditStatus([...editList]);
    } else if (url.accepted_status !== accepted_status || isEdit) {
      await contentStatusAPI({
        entry_id: url.entry_id as number,
        content_confirmed: false,
      });
      await changeStatusReq(accepted_status);
      onUpdateStatus();
    } else {
      onUpdateStatus();
    }
  }

  async function submitHandler(updURL: UGCEntryURL) {
    const durationHasEdited = updURL.content_timing !== url.content_timing;
    const screenshotsHaveEdited = updURL.images !== url.images;
    const hasEdit = durationHasEdited || screenshotsHaveEdited;

    let updURLResp;

    if (url.accepted_status !== updURL.accepted_status || hasEdit) {
      let reqParams = {
        ...updURL,
        accepted_status: url.accepted_status,
        images_attributes: [] as { image: File }[],
      };

      delete reqParams.images;

      (updURL.images as ImageList).forEach((image) => {
        if (image instanceof File) {
          reqParams.images_attributes.push({
            image,
          });
        }
      });

      updURLResp = await updateAPI(reqParams);
    }

    if (!(updURLResp || { error: false }).error) {
      updateStatus(updURL.accepted_status, hasEdit);
    }
  }

  return (
    <Form
      onSubmit={submitHandler}
      initialValues={{
        ...url,
        images: url.images || [],
      }}
    >
      {({ handleSubmit, values, form }) => {
        const showWarning = values.images!.length < 3 || !values.content_timing;

        function setStatusHandler(status: EntryContentStatus) {
          if (status === "on_review") {
            updateStatus(status);
          } else {
            form.change("accepted_status", status);
            form.submit();
          }
        }

        async function deleteImageHandler(index: number) {
          const image = values.images![index];
          let updList = [...values.images!];
          updList.splice(index, 1);
          form.change("images", updList);

          if (!(image instanceof File)) {
            await imagesAPI({
              entry_id: url.entry_id as number,
              id: image.id,
            });

            if (updList.length < 3 && values.accepted_status !== "on_review") {
              changeStatusReq("on_review");
            }
          }
        }

        return (
          <form onSubmit={handleSubmit} className={classes.userContentRow}>
            <Grid key={values.id} container justify="flex-end">
              <Grid item lg={3} className={classes.userContentRowHead}>
                <Box mr={3} my={2}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.subCaption}
                  >
                    URL:
                  </Typography>
                  <Link
                    href={values.link}
                    className={classes.text}
                    color="inherit"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="always"
                  >
                    {values.link}
                  </Link>
                  <Box my={2} />
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.subCaption}
                  >
                    User comment:
                  </Typography>
                  <Typography
                    variant="inherit"
                    component="p"
                    className={clsx(
                      classes.text,
                      !values.title && classes.italic
                    )}
                    gutterBottom={false}
                  >
                    {fillEmptyStr(values.title, {
                      emptyText: "No comment",
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Box ml={3} my={2}>
                  <Grid item lg={10}>
                    <Typography component="span" className={classes.subCaption}>
                      Video duration:{" "}
                      {!withControls && fillEmptyStr(values.content_timing)}
                    </Typography>
                    {withControls && (
                      <Field
                        name="content_timing"
                        component="input"
                        parse={(str: string) =>
                          formatOnlyNumbers(str, TIMING_FORMAT)
                        }
                        placeholder="mm:ss"
                        className={classes.durationSM}
                        disabled={!isEdit}
                      />
                    )}
                    <br />
                    <Typography component="span" className={classes.subCaption}>
                      Screenshots 1:
                    </Typography>
                    <UGCContentScreenshots
                      urlID={url.id as number}
                      entryID={url.entry_id as number}
                      hiddenAction={!withControls}
                      deleteHandler={deleteImageHandler}
                      disabled={!isEdit}
                      contentConfirmed={contentConfirmed}
                    />
                  </Grid>

                  {showWarning && isEdit && (
                    <Alert severity="warning" className={classes.text}>
                      Please add minimum 3 screenshots and duration time to user
                      link
                    </Alert>
                  )}
                </Box>
              </Grid>
              <UGCUserContentActions
                withControls={withControls}
                status={values.accepted_status}
                acceptDisabled={showWarning}
                onAccept={() => setStatusHandler("accepted")}
                onDeny={() => setStatusHandler("declined")}
                onEdit={() => setStatusHandler("on_review")}
                hideEdit={disabledEdit}
                isEdit={isEdit}
              />
            </Grid>
          </form>
        );
      }}
    </Form>
  );
}

export default UGCUserURL;
