import React from 'react';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { useParams, withRouter } from 'react-router-dom';
import { useParameterizedQuery } from 'react-fetching-library';
import { getUserProfileAction } from '../../api/admin/users/users.actions';
import { useUserProfileStyles } from './user-profile.styles';
import UserRequestsTable from './user-requests-table';
import UserPersonalData from './user-personal-data';
import { useAppStyles } from 'app.styles';

const UserProfile: React.FC = () => {
    const classes = useUserProfileStyles();
    const commonClasses = useAppStyles();
    const { id } = useParams();
    const { payload, query } = useParameterizedQuery(getUserProfileAction);

    React.useEffect(() => {
        query(id);
    }, [id]);

    return (
        <Container maxWidth="xl" className={commonClasses.container}>
            <div className={classes.top}>
                <Typography variant="h4">UGC-Profile</Typography>
            </div>
            <Paper>
                <Grid container spacing={4} className={classes.container}>
                    <UserPersonalData data={payload?.user} />
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.title}>
                            UGC Requests for this user:
                        </Typography>
                        <UserRequestsTable data={payload?.entries} />
                        {/*{payload && payload?.pagy.count > 10 &&*/}
                        {/*<Button variant="contained" color="default" className={classes.buttonShow}*/}
                        {/*        onClick={() => {*/}
                        {/*            query(location.search ?*/}
                        {/*                `${location.search}&page=${payload?.pagy.count + 1}`*/}
                        {/*                : `?&page=${payload?.pagy.count + 1}`)*/}
                        {/*        }}>Show more</Button>}*/}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};
export default withRouter(UserProfile);
