import React from 'react';
import { makeStyles } from '@material-ui/core';
import { UGCEntry } from '../../api/admin/ugc/entries.types';
import TableUserRequests from '../../components/table/table-user-requests';

export type LabelType = {
    key: string;
    label: string;
};
const labels: Array<LabelType> = [
    {
        key: 'title',
        label: 'File ID',
    },
    {
        key: 'event',
        label: 'Event / Story',
    },
    {
        key: 'created_at',
        label: 'Created at',
    },
];

type PropsTable = {
    data: Array<UGCEntry> | undefined;
};
const UserRequestsTable: React.FC<PropsTable> = ({ data }) => {
    const classes = useStyles();

    return (
        <div className={classes.table}>
            <TableUserRequests labels={labels} data={data} />
        </div>
    );
};
export default UserRequestsTable;

const useStyles = makeStyles({
    table: {
        marginBottom: 10,
    },
});
