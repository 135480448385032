import React from 'react';
import { useIsAuthorized } from 'api/admin/current_admin';
import { useSignOut } from 'api/admin/sign_out';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Button, Container, Box, Grid } from '@material-ui/core';
import { useHeaderStyles } from './header.styles';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import {CountriesRoute, EventStoryRoute, UGCRequestsRoute, UsersRoute} from 'config/routes';

export default function Header() {
    const [t] = useTranslation();
    const classes = useHeaderStyles();
    const isAuthorized = useIsAuthorized();
    const signOut = useSignOut();
    const loc = useLocation();
    const isSharedPage = loc.pathname.includes('/share');

    return (
        <AppBar position="static" className={classes.root} color="primary">
            <Toolbar>
                <Container maxWidth="xl" className={classes.headerContainer}>
                    <Box className={classes.headerMainBlock}>
                        <Grid container spacing={2}>
                            <Grid item className={classes.logoContainer}>
                                <NavLink to={UGCRequestsRoute.getLink()}>
                                    <span className={classes.logo} />
                                </NavLink>
                            </Grid>
                            {isAuthorized && !isSharedPage && (
                                <>
                                    <Grid item>
                                        <Button
                                            color="inherit"
                                            className={classes.menuItem}
                                            component={NavLink}
                                            to={UGCRequestsRoute.getLink()}
                                        >
                                            {t('UGC Requests')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="inherit"
                                            className={classes.menuItem}
                                            component={NavLink}
                                            to={UsersRoute.getLink()}
                                        >
                                            {t('Users')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="inherit"
                                            className={classes.menuItem}
                                            component={NavLink}
                                            to={EventStoryRoute.getLink()}
                                        >
                                            {t('UGC Event/story manager')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="inherit"
                                            className={classes.menuItem}
                                            component={NavLink}
                                            to={CountriesRoute.getLink()}
                                        >
                                            {t('Countries')}
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    {isAuthorized && !isSharedPage && (
                        <Button color="inherit" onClick={signOut}>
                            Log Out
                        </Button>
                    )}
                </Container>
            </Toolbar>
        </AppBar>
    );
}
