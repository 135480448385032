import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useField } from 'react-final-form';

export default function SignInForm({ error }: { error: string }) {
    const email = useField('email');
    const password = useField('password');
    const hasError = error === 'user_email_or_password_not_valid';

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    type="email"
                    inputMode="email"
                    className="input"
                    placeholder="email"
                    variant="outlined"
                    fullWidth
                    error={hasError}
                    size="small"
                    style={{ background: 'white' }}
                    {...email.input}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="password"
                    className="input"
                    placeholder="password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={hasError}
                    style={{ background: 'white' }}
                    {...password.input}
                />
                {hasError && (
                    <small style={{ color: 'red' }}>
                        {error.replace(/_/g, ' ')}
                    </small>
                )}
            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                >
                    Log In
                </Button>
            </Grid>
        </>
    );
}
