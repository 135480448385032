import route from 'hocs/route';

export interface UGCRequestRouteParams {
    id: string;
}

export const UGCRequestsRoute = route({
    exact: true,
    path: '/ugc-requests',
    component: require('pages/ugc-request-list').default,
});

export const UGCRequestDetailsRoute = route<UGCRequestRouteParams>({
    path: '/ugc-requests/:id',
    component: require('pages/ugc-request').default,
});

export const UGCRequestShareRoute = route<UGCRequestRouteParams>({
    path: '/ugc-requests/:id/share',
    // path: '/ugc-requests/share/:id',
    component: require('pages/ugc-request').default,
});

export const UsersRoute = route({
    exact: true,
    path: "/users",
    component: require("pages/users").default,
});

export const UserProfileRoute = route<UGCRequestRouteParams>({
    exact: true,
    path: "/users/:id",
    component: require("pages/user-profile").default,
});

export const EventStoryRoute = route({
    exact: true,
    path: "/events",
    component: require("pages/events-stories").default,
});

export const CountriesRoute = route({
    exact: true,
    path: "/countries",
    component: require("pages/countries").default,
});
