import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import {
	UGCEntryProvider,
	useCreateUGCEntry,
	useUGCEntry,
	useUpdateUGCEntry,
} from "api/admin/ugc/entries";
import {
	IPayloadURLScreenshots,
	UGCEntryImageAttrs,
} from "api/admin/ugc/entries.types";
import { FRONT_HOST } from "config/develop";
import copy from "copy-to-clipboard";
import arrayMutators from "final-form-arrays";
import { makeFormData, makePlainObject } from "helpers/files";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import UGCForm from "./ugc-form";

interface UGCPopupContainerProps {
	onClose: (success?: boolean) => void;
	data?: any;
}

export default function UGCPopupContainer(props: UGCPopupContainerProps) {
	return (
		<UGCEntryProvider initFetch={false}>
			<UGCPopup {...props} />
		</UGCEntryProvider>
	);
}

export function UGCPopup(props: UGCPopupContainerProps) {
	const entry = useUGCEntry();
	const create = useCreateUGCEntry();
	const update = useUpdateUGCEntry();
	const [isSubmitForm, setIsSubmitForm] = useState<boolean>(false);

	const isNew = !props.data?.id;

	useEffect(() => {
		if (isNew) {
			entry.reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const id = props.data?.id;

		if (id) {
			entry.query({ id });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data?.id]);

	async function onSubmit(formEntry: { [key: string]: any }) {
		setIsSubmitForm(true);
		const urls_attributes = formEntry.urls_attributes.map(
			(url_data: { [key: string]: any }) => {
				const { extData, ...attr } = url_data;

				let res = {
					...attr,
					images_attributes: attr.images ? [...attr.images] : [],
				};
				if (attr.images && attr.images.length > 0) {
					res.images_attributes = res.images_attributes.map(
						(image: any, index: number) => {
							if (image.id) {
								return {
									id: image.id,
									_destroy: Boolean(image._destroy),
									content_timing: image.content_timing,
								};
							} else {
								return { image, meta: {}, ...extData[index] };
							}
						}
					);
				}
				return { ...res, images: [] };
			}
		);

		const images_attributes = (
			formEntry.images_attributes as UGCEntryImageAttrs[]
		).map((img) => {
			if (!(img.image instanceof File)) {
				delete img.image;
			}

			return img;
		});

		delete formEntry.event_name;
		delete formEntry.stories;

		const obj = makePlainObject(
			{
				...formEntry,
				event_id: formEntry.event_id.id,
				story_ids: [formEntry.story_ids.id],
				urls_attributes,
				images_attributes,
			},
			"entry"
		);

		const formData = makeFormData(obj);

		if (isNew) {
			await create(formData).then((res) => {
				if (res.payload) {
					copy(`${FRONT_HOST}/ugc-request/${res.payload.slug}`, {
						debug: false,
					});
				}
				setIsSubmitForm(false);
			});
			props.onClose(true);
		} else {
			await update({
				id: formEntry.id,
				entry: formData,
			}).then(() => setIsSubmitForm(false));
			props.onClose();
		}
	}

	const initialValues = useMemo(() => {
		if (isNew) {
			return {
				exclusive_type: "non_exclusive",
				urls_attributes: [],
				images_attributes: [],
				news_links: [""],
			};
		} else if (entry.payload) {
			const stories = entry.payload.entry?.stories || [];
			const story_id: number = stories.length !== 0 ? stories[0].story_id : 0;
			const story_name: string =
				stories.length !== 0 ? stories[0].story_name : "";

			const images_attributes = entry.payload?.images.map((img) => ({
				_destroy: false,
				file: img.link,
				id: img.id,
			}));

			const news_links = entry.payload?.entry.news_links.length
				? entry.payload?.entry.news_links
				: [""];

			return {
				...entry.payload?.entry,
				news_links: news_links,
				urls_attributes: (entry.payload?.urls || []).filter(
					(url) => url.admin_created
				),
				story_ids: { id: story_id, title: story_name },
				event_id: {
					id: entry.payload.entry.event_id,
					title: entry.payload.entry.event_name,
				},
				images_attributes,
			};
		}
		return null;
	}, [isNew, entry.payload]);

	if (!initialValues) {
		return null;
	}

	return (
		<Form
			onSubmit={onSubmit}
			mutators={{
				...arrayMutators,
				removeScreenshot: ([fieldName, index], state, utils) => {
					utils.changeValue(state, fieldName, (state) => {
						let data = Array.from(state);
						if (data[index] instanceof File) {
							data.splice(index, 1);
						} else {
							data[index] = {
								...(data[index] as IPayloadURLScreenshots),
								_destroy: true,
							};
						}
						return data;
					});
				},
			}}
			validate={(values) => {
				const errors: any = {};
				if (!values.event_id) {
					errors.event_id = "Required";
				}
				if (!values.story_ids) {
					errors.story_ids = "Required";
				}
				if (!values.title) {
					errors.title = "Required";
				}
				if (!values.price) {
					errors.price = "Required";
				}
				return errors;
			}}
			initialValues={initialValues as any}
		>
			{({ handleSubmit, values, errors }) => {
				const isDisabled =
					isSubmitForm ||
					(values.urls_attributes.length === 0 &&
						!values.user_content_allowed) ||
					Object.keys(errors as object).length !== 0;

				return (
					<form onSubmit={handleSubmit}>
						<DialogTitle id="form-dialog-title">
							{isNew ? "Create request" : "Update request"}
						</DialogTitle>
						<DialogContent>
							{/* <DialogContentText>
              To subscribe to this website, please enter your
              email address here. We will send updates
              occasionally.
            </DialogContentText> */}
							<UGCForm />
						</DialogContent>
						<DialogActions>
							<Button
								color="default"
								onClick={() => {
									props.onClose();
								}}
							>
								Cancel
							</Button>
							<Button
								color="primary"
								variant="contained"
								type="submit"
								disabled={isDisabled}
							>
								Save
							</Button>
						</DialogActions>
					</form>
				);
			}}
		</Form>
	);
}
