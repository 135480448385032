import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { LabelType } from '../../pages/users/users-table';
import { UserType } from '../../api/admin/users/users.types';
import { DateTime } from 'luxon';

type PropsTable = {
    labels: Array<LabelType>;
    data: Array<UserType> | undefined;
    clickOnRow: (id: number) => void;
};
const TableUsers: React.FC<PropsTable> = ({ labels, data, clickOnRow }) => {
    function full_name(user: UserType): string {
        return `${user.first_name !== null ? user.first_name : ''} ${
            user.last_name !== null ? user.last_name : ''
        }`;
    }

    function full_adress(user: UserType): string {
        return `${user.city !== null ? user.city : ''} ${
            user.address !== null ? user.address : ''
        }`;
    }

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {labels.map((l) => (
                            <TableCell align="center" key={l.key}>
                                {l.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.map((row) => (
                            <TableRow
                                key={row.id}
                                hover
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    clickOnRow(row.id);
                                }}
                            >
                                <TableCell align="center">
                                    {full_name(row)}
                                </TableCell>
                                <TableCell align="center">
                                    {full_adress(row)}
                                </TableCell>
                                <TableCell align="center">
                                    {row.email}
                                </TableCell>
                                <TableCell align="center">
                                    {row.user_type}
                                </TableCell>
                                <TableCell align="center">
                                    {DateTime.fromISO(row.updated_at).toFormat(
                                        'LLLL d, yyyy HH:mm a'
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default TableUsers;
