import React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Box,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import {
    useStories,
    useUpdateStory,
} from '../../../../api/admin/event-story/stories';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from '../../../../store';
import qs from 'qs';
import { actions } from '../../../../reducers/events-stories/actions';
import { StoryType } from '../../../../api/admin/event-story/stories.types';
import TxtField from '../../../../components/UGC/ugc-popup/text-field';

type PropsType = {
    onClose: () => void;
    data: StoryType;
};
const StoryRename: React.FC<PropsType> = ({ data, onClose }) => {
    const dispatch = useDispatch();
    const updateStory = useUpdateStory();
    const stories = useStories();
    const { data_stories } = useSelector(
        (store: AppStateType) => store.dataEventsStories
    );

    if (!data) {
        return null;
    }

    async function onSubmit(formData: any) {
        const { status } = await updateStory({
            id: data.id,
            title: formData.title,
            event_id: data.event_id,
        });

        if (status) {
            if (data_stories[data.event_id.toString()].openStories) {
                const filterParamsStr = qs.stringify(
                    { q: { event_id_in: [data.event_id] } },
                    {
                        encode: false,
                        arrayFormat: 'brackets',
                        addQueryPrefix: true,
                    }
                );
                const { payload } = await stories.query(filterParamsStr);
                if (payload && payload.stories) {
                    dispatch(
                        actions.setStories(payload.stories, data.event_id, true)
                    );
                }
            }
        }

        onClose();
    }

    return (
        <Form onSubmit={onSubmit} initialValues={{ title: data.title }}>
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle id="form-dialog-title">
                            Rename story
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {`You are about to give another name to 
                                 story "${data && data?.title}".`}
                            </DialogContentText>
                            <Box mb={1}>Change name to:</Box>
                            <TxtField name="title" />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                            >
                                Rename
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        </Form>
    );
};
export default StoryRename;
