import React from 'react';
import useBool from 'hooks/use-bool';
import { useForm, useField } from 'react-final-form';
import { Button, Box } from '@material-ui/core';
import { useFilterBlockStyles } from './filter-block.styles';

interface FilterBlockProps {
    children: React.ReactChild;
    title?: string;
    defaultOpen?: boolean;
    resetText?: string;
    name?: string | string[];
}

export default function FilterBlock(props: FilterBlockProps) {
    const classes = useFilterBlockStyles();
    const form = useForm();
    const formState = form.getState().values;
    const isListFields =
        props.name instanceof Array && (props.name! as string[]);
    const isDisabled =
        (isListFields && !isListFields.some((field) => formState[field])) ||
        (typeof props.name === 'string' && !formState[props.name]);

    function onReset(ev: any) {
        ev.preventDefault();

        if (isListFields) {
            isListFields.forEach((name) => {
                if (name) {
                    form.change(name);
                }
            });
        } else {
            form.change(props.name as string);
        }

        form.submit();
    }

    return (
        <>
            {props.title && <Box className={classes.title}>{props.title}</Box>}

            <Box className={classes.body}>{props.children}</Box>

            {props.name && (
                <Box className={classes.footer}>
                    <Button
                        className={classes.btnReset}
                        // color="primary"
                        onClick={onReset}
                        disabled={isDisabled}
                    >
                        Reset
                    </Button>
                </Box>
            )}
        </>
    );
}
