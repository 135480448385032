import {EventType} from "../../api/admin/event-story/events.types";
import {StoryType} from "../../api/admin/event-story/stories.types";
import {InferActionTypes} from "../../store";

export const SET_EVENTS = 'eventsStoriesReducer/SET_EVENTS'
export const SET_STORIES = 'eventsStoriesReducer/SET_STORIES'
export const SET_OPEN_STORIES = 'eventsStoriesReducer/SET_OPEN_STORIES'

export const actions = {
    setEvents: (events: Array<EventType>) =>
        ({type: SET_EVENTS, events} as const),

    setStories:
        (stories: Array<StoryType>, event_id: number, openStories: boolean) =>
            ({type: SET_STORIES, stories, event_id, openStories} as const),

    setOpenStories: (event_id: number, openStories: boolean) =>
        ({type: SET_OPEN_STORIES, event_id, openStories} as const)
}

export type ActionsTypes = InferActionTypes<typeof actions>;