import React from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import SignInPage from './pages/sign-in';
import { useIsAuthorized } from 'api/admin/current_admin';
import {
  CountriesRoute,
  EventStoryRoute,
  UGCRequestDetailsRoute,
  UGCRequestShareRoute,
  UGCRequestsRoute,
  UserProfileRoute,
  UsersRoute,
} from 'config/routes';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from 'components/layout/header';
import './styles.css';
import { useAppStyles } from 'app.styles';

export default function App() {
    const isAuthorized = useIsAuthorized();
    const classes = useAppStyles();

    return (
        <Router>
            <CssBaseline />
            <Header />

            <Switch>
                <UGCRequestShareRoute />

                <Route
                    render={(props) =>
                        !isAuthorized ? (
                            <Container className={classes.signInContainer}>
                                <SignInPage />
                            </Container>
                        ) : (
                            <Switch>
                                <Redirect
                                    exact
                                    from="/"
                                    to={UGCRequestsRoute.getLink()}
                                />
                                <UsersRoute />
                                <UserProfileRoute />
                                <UGCRequestsRoute />
                                <UGCRequestDetailsRoute />
                                <EventStoryRoute />
                                <CountriesRoute />
                                <Redirect to={UGCRequestsRoute.getLink()} />
                            </Switch>
                        )
                    }
                />
            </Switch>
        </Router>
    );
}
