import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	Fade,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CancelIcon from "@material-ui/icons/Cancel";
import { Alert } from "@material-ui/lab";
import {
	toggleUserConfirmContent,
	useConfirmUserData,
	useUserDataComments,
} from "api/admin/ugc/entries";
import {
	IUserDataComments,
	LEGAL_ENTITY,
	UGCEntry,
} from "api/admin/ugc/entries.types";
import clsx from "clsx";
import { FormApi } from "final-form";
import { fillEmptyStr } from "helpers/strings";
import React, { useState } from "react";
import { Form, useField } from "react-final-form";
import { useRequestStyles } from "../ugc-request.styles";
import UGCVerificationStatus, {
	getUGCVerificationProps,
} from "../ugc-verification-status";
import { useCountries } from "api/admin/countries";
import { Country } from "api/admin/countries/countries.types";

export type UserDataForm = FormApi<IUserDataComments> | null;

function UserDataRow({
	list,
	name,
	hiddenAction,
	onCancelComment,
}: {
	list: string[];
	name: keyof IUserDataComments;
	hiddenAction: boolean;
	onCancelComment?: () => void;
}) {
	const fieldAPI = useField(name);
	const hasComment = (fieldAPI.input.value || "").length > 0;
	const [showCorrectionField, setStatusOfCorrectionField] =
		useState(hasComment);
	const [showCorrectionBtn, setShowCorrectionBtnStatus] = useState(false);
	const correctionFieldId = `user_data_${name}_comment`;
	const classes = useRequestStyles();

	function showCommentHandler() {
		setStatusOfCorrectionField(true);
	}

	function hideCommentHandler() {
		setStatusOfCorrectionField(false);
	}

	function showCorrectionBtnHandler() {
		if (!showCorrectionBtn && !hiddenAction) {
			setShowCorrectionBtnStatus(true);
		}
	}

	function hideCorrectionBtnHandler() {
		setShowCorrectionBtnStatus(false);
	}

	function checkCommentOnFilledHandler(event: React.FocusEvent) {
		if (!event.currentTarget.textContent) {
			onCancelComment?.();
			hideCommentHandler();
		}
	}

	return (
		<TableRow
			onMouseOver={showCorrectionBtnHandler}
			onMouseLeave={hideCorrectionBtnHandler}
		>
			{list.map((cell, index) => (
				<TableCell
					key={index}
					className={clsx({ [classes.tableRowCaption]: index === 0 })}
				>
					<Typography
						component="span"
						className={clsx({
							[classes.textBreak]: true,
							[classes.disabled]: hiddenAction,
						})}
					>
						{cell}
					</Typography>
				</TableCell>
			))}
			{hiddenAction ? (
				<TableCell className={classes.cell} />
			) : (
				<TableCell>
					{showCorrectionField ? (
						<>
							<Typography
								component="label"
								htmlFor={correctionFieldId}
								className={clsx(classes.subCaption, classes.italic)}
							>
								Message for user
							</Typography>
							<TextField
								id={correctionFieldId}
								multiline
								rows={2}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								className={classes.textArea}
								{...fieldAPI.input}
								onBlur={checkCommentOnFilledHandler}
								autoFocus={!hasComment}
							/>
						</>
					) : (
						<div className={classes.correctionBtnWrap}>
							<Fade in={showCorrectionBtn}>
								<Button
									variant="contained"
									onClick={showCommentHandler}
									size="small"
								>
									Need correction
								</Button>
							</Fade>
						</div>
					)}
				</TableCell>
			)}
		</TableRow>
	);
}

export default function UGCRequestUserData({
	entry,
	setFromAPI,
	disabledEdit,
	isAdminReview,
	isUserReview,
	withControls,
}: {
	entry: UGCEntry;
	setFromAPI: (formAPI: UserDataForm) => void;
	disabledEdit: boolean;
	isAdminReview: boolean;
	isUserReview: boolean;
	withControls: boolean;
}) {
	const [confirmModalOpened, toggleModalOpened] = useState(false);
	const confirmAPI = useConfirmUserData();
	const [showWarning, setWarningShowStatus] = useState(
		!entry.user_data_confirmed
	);
	const classes = useRequestStyles();
	const commentsAPI = useUserDataComments();
	const contentStatusAPI = toggleUserConfirmContent();

	const countriesApi = useCountries();

	const selectedCountry = countriesApi?.payload?.find((item: Country) => {
		return item.id === Number(entry.user_data?.country_id as number);
	});

	function closeWarningHandler() {
		setWarningShowStatus(false);
	}

	async function sendCommentsHandler(comment: IUserDataComments) {
		const {
			country,
			zip_code,
			city,
			address,
			region,
			company_name,
			tax_number,
			...other
		} = comment;

		await contentStatusAPI({ entry_id: entry.id, content_confirmed: true });
		commentsAPI({
			entry_id: entry.id,
			comment: {
				...other,
				...(!comment.legal_entity
					? {
							...comment,
					  }
					: null),
			},
		});
	}

	async function confirmUserDataHandler() {
		await confirmAPI(entry.id);
		confirmModalCloseHandler();
	}

	function confirmModalCloseHandler() {
		toggleModalOpened(false);
	}

	function confirmModalOpenHandler() {
		toggleModalOpened(true);
	}

	return (
		<Form
			onSubmit={sendCommentsHandler}
			initialValues={entry.comment || ({} as IUserDataComments)}
		>
			{({ handleSubmit, values, form }) => {
				const commentsCounter = Object.values(values).length;
				const hasComments = commentsCounter > 0;

				function onChangeHandler() {
					setFromAPI(form);
				}

				function onCancelCommentHandler() {
					!hasComments && setFromAPI(null);
				}

				const canAddComment = values.legal_entity ? true : false;

				return (
					<form onSubmit={handleSubmit} onChange={onChangeHandler}>
						<Typography variant="h2" className={classes.ugcTitle}>
							User data
						</Typography>
						<UGCVerificationStatus
							{...getUGCVerificationProps(
								!hasComments && entry.user_data_confirmed && !isUserReview,
								(hasComments && (entry.content_confirmed || isAdminReview)) ||
									isUserReview,
								entry.status
							)}
						/>
						<Divider />

						<Grid container justify="flex-end">
							<Grid item lg={9}>
								<Box my={4}>
									{showWarning &&
										!disabledEdit &&
										!entry.user_data_confirmed && (
											<Alert color="warning" className="alert-close">
												Please confirm user data or leave comments about
												incorrect data below and send request for data
												correction and re-signature.
												<IconButton
													size="small"
													className="alert-close__btn"
													onClick={closeWarningHandler}
												>
													<CancelIcon fontSize="inherit" />
												</IconButton>
											</Alert>
										)}
								</Box>
								<Box>
									<Typography variant="h2" className={classes.ugcTitle}>
										Personal data
									</Typography>
									<Divider className="divider-black" />
									<Table aria-label="simple table">
										<TableBody>
											<UserDataRow
												list={[
													"First name:",
													fillEmptyStr(entry.user_data.first_name),
												]}
												name="first_name"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={[
													"Last name:",
													fillEmptyStr(entry.user_data.last_name),
												]}
												name="last_name"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={[
													"Patronimic name:",
													fillEmptyStr(entry.user_data.patronymic_name),
												]}
												name="patronymic_name"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={[
													"Phone number:",
													fillEmptyStr(entry.user_data.phone),
												]}
												name="phone"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={["E-mail:", fillEmptyStr(entry.user_data.email)]}
												name="email"
												hiddenAction={true}
											/>
										</TableBody>
									</Table>
								</Box>
								<Box mt={8}>
									<Typography variant="h2" className={classes.ugcTitle}>
										Billing details
									</Typography>
									<Divider className="divider-black" />
									<Table aria-label="simple table">
										<TableBody>
											<UserDataRow
												list={[
													"Legal entity:",
													fillEmptyStr(
														LEGAL_ENTITY[entry.user_data.legal_entity]
													),
												]}
												name="legal_entity"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>
											<UserDataRow
												list={[
													"Country:",
													fillEmptyStr(entry.user_data.country_name),
												]}
												name="country"
												hiddenAction={
													canAddComment || disabledEdit || !withControls
												}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={["ZIP:", fillEmptyStr(entry.user_data.zip_code)]}
												name="zip_code"
												hiddenAction={
													canAddComment || disabledEdit || !withControls
												}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={["City:", fillEmptyStr(entry.user_data.city)]}
												name="city"
												hiddenAction={
													canAddComment || disabledEdit || !withControls
												}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={[
													entry.user_data.legal_entity === "private_person"
														? "Address:"
														: "Billing address:",
													fillEmptyStr(entry.user_data.address),
												]}
												name="address"
												hiddenAction={
													canAddComment || disabledEdit || !withControls
												}
												onCancelComment={onCancelCommentHandler}
											/>

											{entry.user_data.legal_entity !== "private_person" && (
												<>
													<UserDataRow
														list={[
															"State/region:",
															fillEmptyStr(entry.user_data.region),
														]}
														name="region"
														hiddenAction={
															canAddComment || disabledEdit || !withControls
														}
														onCancelComment={onCancelCommentHandler}
													/>
													<UserDataRow
														list={[
															"Company:",
															fillEmptyStr(
																entry.user_data.not_a_company
																	? "Not a company"
																	: entry.user_data.company_name
															),
														]}
														name="company_name"
														hiddenAction={
															canAddComment || disabledEdit || !withControls
														}
														onCancelComment={onCancelCommentHandler}
													/>
													{selectedCountry?.is_eu_country && (
														<UserDataRow
															list={[
																"VAT/tax number:",
																fillEmptyStr(
																	entry.user_data.has_not_vat
																		? "VAT exemption"
																		: entry.user_data.tax_number
																),
															]}
															name="tax_number"
															hiddenAction={
																canAddComment || disabledEdit || !withControls || !!values.country
															}
															onCancelComment={onCancelCommentHandler}
														/>
													)}
												</>
											)}
										</TableBody>
									</Table>
								</Box>
								<Box mt={8}>
									<Typography variant="h2" className={classes.ugcTitle}>
										Payment information
									</Typography>
									<Divider className="divider-black" />
									<Table aria-label="simple table">
										<TableBody>
											<UserDataRow
												list={["Bank:", fillEmptyStr(entry.user_data.bank)]}
												name="bank"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={[
													"Account number:",
													fillEmptyStr(entry.user_data.account_number),
												]}
												name="account_number"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={[
													"Beneficiary name:",
													fillEmptyStr(entry.user_data.beneficiary_name),
												]}
												name="beneficiary_name"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={["IBAN:", fillEmptyStr(entry.user_data.iban)]}
												name="iban"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={["SWIFT:", fillEmptyStr(entry.user_data.swift)]}
												name="swift"
												hiddenAction={disabledEdit || !withControls}
												onCancelComment={onCancelCommentHandler}
											/>

											<UserDataRow
												list={[
													"Additional info:",
													fillEmptyStr(entry.user_data.additional_info),
												]}
												name="additional_info"
												hiddenAction={disabledEdit}
												onCancelComment={onCancelCommentHandler}
											/>
										</TableBody>
									</Table>
								</Box>
								{!entry.user_data_confirmed &&
									!hasComments &&
									isAdminReview &&
									withControls && (
										<Box display="flex" justifyContent="flex-end" mt={5}>
											<Button
												variant="contained"
												type="button"
												size="small"
												onClick={confirmModalOpenHandler}
											>
												Confirm user data
											</Button>

											<Dialog
												open={confirmModalOpened}
												onClose={confirmModalCloseHandler}
												aria-labelledby="alert-dialog-title"
												aria-describedby="alert-dialog-description"
											>
												<DialogTitle id="alert-dialog-title">
													You really want to confirm user data?
												</DialogTitle>
												<DialogActions>
													<Button
														onClick={confirmModalCloseHandler}
														color="secondary"
														autoFocus
													>
														Disagree
													</Button>
													<Button
														onClick={confirmUserDataHandler}
														color="primary"
													>
														Agree
													</Button>
												</DialogActions>
											</Dialog>
										</Box>
									)}
							</Grid>
						</Grid>
					</form>
				);
			}}
		</Form>
	);
}
