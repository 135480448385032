import { UserType } from "../users/users.types";

type Image = File | IPayloadURLScreenshots;
export type ImageList = Image[];

export type EntryStatus =
	| "created"
	| "sent"
	| "received"
	| "started"
	| "on_review" // verification by admin
	| "approved"
	| "waited_user_confirmation"
	| "declined"
	| "completed"
	| "canceled"
	| "revision"; // verification by user

type STR = string | null;
type NUMB = number | null;
export type EntryContentStatus = "on_review" | "accepted" | "declined";
type UserStep =
	| "content"
	| "personal_data"
	| "billing_info"
	| "payment_info"
	| "contract"
	| "invoice"
	| "finished"
	| "revision"
	| "contract_revision"
	| "invoice_revision"
	| "revision_finished"
	| "user_confirmation";

export type updateURL = Omit<UGCEntryURL, "images"> & {
	images_attributes?: {
		image: Image;
	}[];
};

export type UGCImages = {
	id: number;
	link: string;
	url: string;
};

interface IEntryParams {
	price?: number;
	title?: string;
	description?: string;
	story?: string;
	alternative_contact?: string;
	additional_info?: string;
	exclusive_type?: "exclusive" | "nonexcclusive";
	social_post_url?: string;
	urls_attributes?: UGCEntryURL[];
}

export interface UGCEntryImageAttrs {
	id?: number;
	image: File | string;
	file: string;
	_destroy: boolean;
	description?: string;
}

export interface UGCEntry {
	price: number;
	title: string;
	description: STR;
	status: EntryStatus;
	alternative_contact: STR;
	additional_info: STR;
	social_post_url: STR;
	slug_valid_till: string;
	slug: STR;
	token: string;
	admin_share_slug: string;
	status_changed_at: string;
	created_at: string;
	updated_at: string;
	exclusive_type: "exclusive" | "nonexclusive";
	id: number;
	user_step: UserStep;
	filmed_at: STR;
	location: STR;
	country_id: number;
	country_name: string;
	admin_share_slug_valid_till: string;
	user_data: UserType;
	user_id: NUMB;
	event_id: number;
	event_name: string;
	stories: Array<{ story_id: number; story_name: string }>;
	user_data_confirmed: boolean;
	user_content_allowed: boolean;
	content_confirmed: boolean;
	comment: IUserDataComments;
	location_link: STR;
	news_story: STR;
	news_links: String[];
	images_attributes?: UGCEntryImageAttrs[];
	verification: Array<{ id: number; title: string }>;
	pdf_view_url: string;
	currency: string;
}

export interface IPayloadURLScreenshots {
	created_at: string;
	id: number;
	link: string;
	meta: null | { [key: string]: any };
	updated_at: string;
	url_id: number;
	_destroy?: boolean;
	content_timing?: string;
}

export interface UGCEntryURL {
	id?: number;
	title?: string;
	link?: string;
	created_at?: string;
	updated_at?: string;
	content_timing?: string | null;
	images?: IPayloadURLScreenshots[];
	_destroy?: boolean;
	admin_created?: boolean;
	accepted_status: EntryContentStatus;
	entry_id?: number;
	previous_accepted_status?: EntryContentStatus;
	filmed_at?: string;
	location?: string;
	description?: string;
}

export interface UGCEntryFile {
	comment: STR;
	created_at: string;
	exif: STR;
	file_type: "image" | "video";
	id: number;
	metadata: {
		ext: string;
		mimetype: string;
		original_name: string;
		size: number;
		transloadit_meta: {
			aperture: number;
			aspect_ratio: string;
			average_color: string;
			city: string;
			duration: number;
			colorspace: string;
			copyright_notice: string;
			country: string;
			creator: string;
			date_file_created: string;
			date_file_modified: string;
			date_recorded: string;
			device_name: string;
			device_software: string;
			device_vendor: string;
			exposure_compensation: string;
			exposure_mode: string;
			exposure_time: string;
			f_number: number;
			flash: string;
			focal_length: string;
			frame_count: number;
			has_clipping_path: boolean;
			has_transparency: boolean;
			height: number;
			iso: number;
			keywords: string[];
			light_value: number;
			location: string;
			metering_mode: string;
			orientation: string;
			shutter_speed: string;
			state: string;
			white_balance: string;
			width: number;
		};
	};
	owner_id: number;
	owner_type: string;
	permanent_url: STR;
	status: string;
	transloadit_assembly_id: number;
	transloadit_file_url: string;
	transloadit_id: string;
	updated_at: string;
	video_preview_url: STR;
	accepted_status: EntryContentStatus;
	previous_accepted_status: EntryContentStatus;
	thumbnails: string[];
}

export interface UGCEntryResponse {
	entry: UGCEntry;
	urls: UGCEntryURL[];
	images: UGCImages[];
	files: UGCEntryFile[];
	user: UserType;
}

export interface GetEntryParams {
	id: number;
}

export interface CreateEntryParams {
	entry: IEntryParams;
}

export type UpdateEntryParams = GetEntryParams & CreateEntryParams;

export type DestroyEntryParams = GetEntryParams;

export interface UpdateEntryStatusParams extends GetEntryParams {
	status: EntryStatus;
}

export type SlugParams = GetEntryParams;

export interface IChangeUGCUserContentStatusActionParams {
	content_id: number;
	accepted_status: EntryContentStatus;
	type: "url" | "file";
}

export enum LEGAL_ENTITY {
	private_person = "Private person",
	company = "Company",
	enterpreneur = "Enterpreneur",
}

export interface IUserDataComments {
	email?: string;
	first_name?: string;
	last_name?: string;
	patronymic_name?: string;
	phone?: string;
	address?: string;
	bank?: string;
	account_number?: string;
	beneficiary_name?: string;
	iban?: string;
	swift?: string;
	country?: string;
	paypal_email?: string;
	additional_info?: string;
	country_name?: string;
	legal_entity: LEGAL_ENTITY;

	zip_code?: string;
	city?: string;
	region?: string | null;
	tax_number?: string | null;
	company_name?: string | null;
}

export interface IUserDataCommentsActionParams {
	entry_id: number;
	comment: IUserDataComments;
}

export interface IChangeEntryStatusActionParams {
	entry_id: number;
	status: "approve" | "decline" | "revision";
}

export interface IToggleUserConfirmContentActionParams {
	entry_id: number;
	content_confirmed: boolean;
}
