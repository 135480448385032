import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useRequestStyles } from './ugc-request.styles';

export default function UGCTitleBlock({
    capt,
    text,
}: {
    capt: string;
    text: string;
}) {
    const styles = useRequestStyles();

    return (
        <Grid item xs={2}>
            <Typography
                variant="h6"
                component="h3"
                align="center"
                className={styles.subCaption}
            >
                {capt}
            </Typography>
            <Typography
                variant="subtitle1"
                component="p"
                align="center"
                className={styles.text}
            >
                {text}
            </Typography>
        </Grid>
    );
}
