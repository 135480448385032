import { UGCEntry } from 'api/admin/ugc/entries.types';
import clsx from 'clsx';
import React from 'react';
import { useRequestStyles } from './ugc-request.styles';

export type UGCStatusTypes = 'warning' | 'danger' | 'success';

export interface UGCStatusProps {
    status: UGCStatusTypes;
    msg: string;
    tip?: string;
}

export function getUGCVerificationProps(
    isConfirmed: boolean,
    isWarning: boolean,
    status: UGCEntry['status']
): UGCStatusProps {
    if (isConfirmed) {
        return {
            msg: 'Confirmed',
            status: 'success',
        };
    } else if (isWarning) {
        return {
            msg:
                status === 'revision'
                    ? 'Waiting for data correction'
                    : 'Requires re-signature',
            status: 'warning',
        };
    }
    return {
        msg: 'Requires actions',
        status: 'danger',
    };
}

export default function UGCVerificationStatus({
    status = 'danger',
    msg,
    tip,
}: UGCStatusProps) {
    const classes = useRequestStyles();

    return (
        <div
            className={clsx(classes.statusBlock, {
                [`${classes.statusBlock}--danger`]: status === 'danger',
                [`${classes.statusBlock}--warning`]: status === 'warning',
                [`${classes.statusBlock}--success`]: status === 'success',
            })}
        >
            {tip ? `${msg}:` : msg}
            {tip && (
                <span className={`${classes.statusBlock}__sub`}>{tip}</span>
            )}
        </div>
    );
}
