import { Action } from 'react-fetching-library';
import {
    EventsResponseType,
    EventType,
    EventParamsType,
    EventUpdateActionParams,
} from './events.types';

export function getEventsAction(params: string): Action<EventsResponseType> {
    return {
        method: 'GET',
        endpoint: `/api/v1/admin/categorization/events${params}`,
    };
}

export function getEventAction(id: number): Action<EventType> {
    return {
        method: 'GET',
        endpoint: `/api/v1/admin/categorization/events/${id}`,
    };
}

export function createEventAction(params: EventParamsType): Action {
    return {
        method: 'POST',
        endpoint: `/api/v1/admin/categorization/events`,
        body: {
            event: params,
        },
    };
}

export function updateEventAction({
    id,
    ...event
}: EventUpdateActionParams): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/categorization/events/${id}`,
        body: {
            event,
        },
    };
}

export function mergeEventAction({
    id,
    id_merge,
}: {
    id: number;
    id_merge: number;
}): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/categorization/events/${id_merge}/merge`,
        body: {
            merge_with: id,
        },
    };
}

export function deleteEventAction(id: number): Action {
    return {
        method: 'DELETE',
        endpoint: `/api/v1/admin/categorization/events/${id}`,
    };
}
