export default function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = () => {
            reject();
        };
        reader.readAsDataURL(file);
    });
}

type SomeObject = { [key: string]: any };

export function makeFormData(object: SomeObject): FormData {
    let fd = new FormData();

    for (let key in object) {
        const updKey = key; //.replace(/\[\d+\]/g, '[]');
        const value = object[key];
        const isArray = value instanceof Array;
        const isFile = value instanceof File || value instanceof Blob;
        const isObject = typeof value === 'object' && value !== null;

        if (value === null || value === undefined) {
            continue;
        }

        if (isArray) {
            value.forEach((el: any) => {
                fd.append(updKey, el);
            });
        } else if (isFile) {
            fd.append(updKey, value, value.name);
        } else if (isObject) {
            fd.append(updKey, JSON.parse(value));
        } else {
            fd.append(updKey, value);
        }
    }
    return fd;
}

export function makePlainObject(
    treeObject: SomeObject,
    _prefix?: string
): SomeObject {
    let result = {} as SomeObject;
    const isArray = (el: any): boolean => el instanceof Array;
    const isObject = (el: any): boolean =>
        typeof el === 'object' && el !== null;
    const isFile = (el: any): boolean =>
        el instanceof File || el instanceof Blob;

    for (let key in treeObject) {
        const _key = _prefix ? `${_prefix}[${key}]` : key;
        const current = treeObject[key];

        if (isArray(current)) {
            current.forEach((el: any, index: number) => {
                const arrKey = `${_key}[${index}]`;

                if (isObject(el) && !isFile(el)) {
                    Object.assign(result, makePlainObject(el, arrKey));
                } else {
                    result[arrKey] = el;
                }
            });
        } else if (isObject(current) && !isFile(current)) {
            Object.assign(result, makePlainObject(current, _key));
        } else {
            result[_key] = current;
        }
    }
    return result;
}
