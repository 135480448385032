/* eslint-disable react-hooks/rules-of-hooks */
import * as actions from './entries.actions';
import createQueryContext from 'react-fetching-library-context';
import { useMutation } from 'react-fetching-library';
import {
    DestroyEntryParams,
    UGCEntry,
    UpdateEntryStatusParams,
    SlugParams,
    UGCEntryResponse,
    UGCEntryURL,
    IChangeUGCUserContentStatusActionParams,
    updateURL,
    IUserDataCommentsActionParams,
    IChangeEntryStatusActionParams,
    IToggleUserConfirmContentActionParams,
} from './entries.types';
import PagyResponse from 'types/pagy';

export const {
    useQueryContext: useUGCEntries,
    Provider: UGCEntriesProvider,
} = createQueryContext<PagyResponse<UGCEntry>>(actions.getEntriesAction);

export const {
    useQueryContext: useUGCEntry,
    Provider: UGCEntryProvider,
} = createQueryContext<UGCEntryResponse>(actions.getEntryAction);

export const {
    useQueryContext: useUGCEntryBySlug,
    Provider: UGCEntryBySlugProvider,
} = createQueryContext<UGCEntryResponse>(actions.getShareSlugAction);

export function useCreateUGCEntry() {
    const create = useMutation(actions.createEntryAction);
    const list = useUGCEntries();
    const createSlug = useMutation(actions.createNewSlugAction);

    return async function (params: FormData) {
        const newRequestData = await create.mutate(params);
        const newSlugData = await createSlug.mutate({
            id: newRequestData.payload.entry.id,
        });
        await list.query('');

        return newSlugData;
    };
}

export function useUpdateUGCEntry() {
    const update = useMutation(actions.updateEntryAction);
    const list = useUGCEntries();
    const entry = useUGCEntry();

    return async function (params: { id: number; entry: any }) {
        const result = await update.mutate(params);
        try {
            list.payload && (await list.query(''));
            entry.payload && (await entry.query({ id: params.id }));
        } catch (err) {}

        return result;
    };
}

export function useDestroyUGCEntry() {
    const destroy = useMutation(actions.destroyEntryAction);
    const list = useUGCEntries();

    return async function (params: DestroyEntryParams) {
        await destroy.mutate(params);
        return list.query('');
    };
}

export function useUpdateUGCEntryStatus() {
    const update = useMutation(actions.updateEntryStatusAction);
    const list = useUGCEntries();

    return async function (params: UpdateEntryStatusParams) {
        await update.mutate(params);
        return list.query('');
    };
}

export function useCreateNewSlug() {
    const create = useMutation(actions.createNewSlugAction);

    return async function (params: SlugParams) {
        const result = await create.mutate(params);
        return result;
    };
}

export function useCreateShareSlug() {
    const create = useMutation(actions.createShareSlugAction);

    return async function (params: { id: number; renew: boolean }) {
        const result = await create.mutate(params);
        return result;
    };
}

export function useCreateURLByEntry() {
    const create = useMutation(actions.getUGCEntryURLCreateAction);

    return async function (params: {
        id: number;
        urls_attributes: UGCEntryURL[];
    }) {
        return await create.mutate(params);
    };
}

// change user content files or links
export function useUGCContentStatus() {
    const statusContentAPI = useMutation(
        actions.changeUGCUserContentStatusAction
    );
    const entry = useUGCEntry();

    return async function (
        params: IChangeUGCUserContentStatusActionParams & { entry_id: number }
    ) {
        await statusContentAPI.mutate(params);
        return entry.query({ id: params.entry_id });
    };
}

// update ugc entry
export function useUpdateEntryURL() {
    const updEntryAPI = useMutation(actions.updateEntryURLAction);

    return async function (params: updateURL) {
        return updEntryAPI.mutate(params);
    };
}

// user data comments
export function useUserDataComments() {
    const commentsAPI = useMutation(actions.userDataCommentsAction);
    const entry = useUGCEntry();

    return async function (params: IUserDataCommentsActionParams) {
        await commentsAPI.mutate(params);
        return entry.query({ id: params.entry_id });
    };
}

// confirm user data
export function useConfirmUserData() {
    const confirmAPI = useMutation(actions.confirmUserDataAction);
    const entry = useUGCEntry();

    return async function (id: number) {
        await confirmAPI.mutate(id);
        return entry.query({ id });
    };
}

// toggle user confirm content
export function toggleUserConfirmContent() {
    const statusAPI = useMutation(actions.toggleUserConfirmContentAction);

    return function (params: IToggleUserConfirmContentActionParams) {
        return statusAPI.mutate(params);
    };
}

// accept / deny / revision - status of entry
export function useChangeReqStatus() {
    const confirmAPI = useMutation(actions.changeEntryStatusAction);
    const entry = useUGCEntry();

    return async function (params: IChangeEntryStatusActionParams) {
        await confirmAPI.mutate(params);
        return entry.query({ id: params.entry_id });
    };
}
