import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/use-debounce';
import { useForm } from 'react-final-form';
import qs from 'qs';
import AutocompleteText from './autocomplete-text';
import { useCreateEvent, useEvents } from 'api/admin/event-story/events';

type PropsType = {
    name: string;
};

const AutocompleteEventsField: React.FC<PropsType> = ({ name }) => {
    const form = useForm();
    const formState = form.getState();

    const events = useEvents();
    const createEvent = useCreateEvent();

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (
            formState.values.story_ids !== undefined &&
            !formState.values.event_id
        ) {
            const eventParamsStr = qs.stringify(
                {
                    q: {
                        id_in: formState.values.story_ids
                            ? [formState.values.story_ids.event_id]
                            : null,
                        active_eq: true,
                    },
                },
                { encode: false, arrayFormat: 'brackets', addQueryPrefix: true }
            );

            events.query(eventParamsStr).then((results) => {
                if (!results.error && results.payload !== undefined) {
                    if (results.payload.events.length === 1) {
                        form.change('event_id', results.payload.events[0]);
                    } else {
                        form.change('event_id', undefined);
                    }
                }
            });
        } else {
            const params = qs.stringify(
                {
                    search: debouncedSearchTerm,
                    q: {
                        active_eq: true,
                    },
                },
                {
                    encode: false,
                    arrayFormat: 'brackets',
                    addQueryPrefix: true,
                }
            );
            events.query(params);
        }
    }, [debouncedSearchTerm, formState.values.story_ids]);

    const changeEvent = React.useCallback(
        async (newValue: any) => {
            if (newValue.inputValue) {
                const { payload, error } = await createEvent({
                    title: newValue.inputValue,
                });
                return payload.event;
            } else {
                return newValue;
            }
        },
        [createEvent]
    );

    const setSearchValue = React.useCallback(
        (newValue: any) => {
            setSearchTerm(newValue);
        },
        [setSearchTerm]
    );

    const dataEvents =
        events.payload !== undefined ? events.payload.events : [];

    return (
        <AutocompleteText
            name={name}
            title="Event"
            options={dataEvents}
            onChange={changeEvent}
            setSearchValue={setSearchValue}
        />
    );
};
export default AutocompleteEventsField;
