import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { EntryContentStatus } from 'api/admin/ugc/entries.types';
import { useRequestStyles } from '../ugc-request.styles';

interface IUGCUserContentActionsProps {
    withControls?: boolean;
    status: EntryContentStatus;
    onAccept?: () => void;
    onDeny?: () => void;
    onEdit?: () => void;
    acceptDisabled?: boolean;
    hideEdit?: boolean;
    isEdit: boolean;
}

export default function UGCUserContentActions({
    withControls,
    status,
    onAccept = () => {},
    onDeny = () => {},
    onEdit = () => {},
    acceptDisabled,
    hideEdit,
    isEdit,
}: IUGCUserContentActionsProps) {
    const classes = useRequestStyles();

    return (
        <Grid item lg={3} container justify="center" alignItems="center">
            {withControls && isEdit && (
                <>
                    <Box mr={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onAccept}
                            disabled={acceptDisabled}
                            size="small"
                        >
                            Accept
                        </Button>
                    </Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onDeny}
                        size="small"
                    >
                        Deny
                    </Button>
                </>
            )}
            {withControls && !isEdit && (
                <>
                    <Box mr={3}>
                        <Typography
                            component="span"
                            color={
                                status === 'accepted' ? 'primary' : 'secondary'
                            }
                            className={classes.italic}
                        >
                            <Grid container alignItems="center">
                                {status === 'accepted' ? (
                                    <>
                                        <DoneIcon fontSize="small" />
                                        Accepted
                                    </>
                                ) : (
                                    <>
                                        <CloseIcon fontSize="small" />
                                        Denied
                                    </>
                                )}
                            </Grid>
                        </Typography>
                    </Box>
                    {!hideEdit && (
                        <Button onClick={onEdit} type="button">
                            <Link underline="always" color="inherit">
                                Edit
                            </Link>
                        </Button>
                    )}
                </>
            )}
        </Grid>
    );
}
