import {useCountries} from "api/admin/countries";
import React from "react";
import {MenuItem, OutlinedInput, Select} from "@material-ui/core";
import {useField, useForm} from "react-final-form";

interface CountriesFieldProps {
    name: string;
}

export function CountriesField(props: CountriesFieldProps) {
    const countries = useCountries();
    const fromField = useField(props.name);
    const form = useForm();

    return (
        <Select
            value={fromField.input.value}
            onChange={(ev) => {
                form.change(props.name, ev.target.value);
                form.submit();
            }}
            variant="outlined"
            fullWidth
            input={<OutlinedInput margin='dense'/>}
        >
            {countries.payload?.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                    {country.name}
                </MenuItem>
            ))}
        </Select>
    )
}