import React from 'react';
import TableUsers from '../../components/table/table-users';
import { UserType } from '../../api/admin/users/users.types';
import { makeStyles } from '@material-ui/core';
import { UserProfileRoute } from '../../config/routes';
import { useHistory } from 'react-router-dom';

export type LabelType = {
    key: string;
    label: string;
};
const labels: Array<LabelType> = [
    {
        key: 'name',
        label: 'First name, Last name',
    },
    {
        key: 'city',
        label: 'City',
    },
    {
        key: 'mail',
        label: 'E-Mail',
    },
    {
        key: 'status',
        label: 'Status',
    },
    {
        key: 'last_activity_time',
        label: 'Last Activity',
    },
];

type PropsTable = {
    data: Array<UserType> | undefined;
};
const UsersTable: React.FC<PropsTable> = ({ data }) => {
    const classes = useStyles();
    let history = useHistory();

    const showUserProfile = React.useCallback(
        (id: number) => {
            history.push(UserProfileRoute.getLink({ id: String(id) }));
        },
        [history]
    );

    return (
        <div className={classes.table}>
            <TableUsers
                labels={labels}
                data={data}
                clickOnRow={showUserProfile}
            />
        </div>
    );
};
export default UsersTable;

const useStyles = makeStyles({
    table: {
        marginBottom: 10,
    },
});
