import React from 'react';
import { Field } from 'react-final-form';

interface AddImagesProps {
    id: string;
    images: any[];
    name: string;
    middleware?: (list: File[]) => { [key: string]: any }[];
}

export default function AddImages(props: AddImagesProps) {
    const handleChange = (
        { target: { files } }: React.ChangeEvent<HTMLInputElement>,
        onChange: (_: File[]) => void
    ) => {
        if (files && files.length > 0) {
            const filtered = Array.from(files).filter((file) => {
                const fileHasBeenSelected = props.images.some(
                    (img) => img.name === file.name
                );

                return !fileHasBeenSelected;
            });
            const result = props.middleware
                ? props.middleware(filtered).concat(props.images)
                : [...props.images, ...filtered];
            onChange(result);
        }
    };

    return (
        <Field name={props.name} multiple type="file">
            {({ input: { value, onChange, ...input } }) => {
                return (
                    <input
                        id={props.id}
                        accept="image/*"
                        {...input}
                        onChange={(ev) => handleChange(ev, onChange)}
                        style={{ display: 'none' }}
                    />
                );
            }}
        </Field>
    );
}
