import React, {FormEvent} from "react";
import {Box, InputBase} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close';
import {useSearchBarStyles} from "./search-bar.styles";

type PropsType = {
    value: string
    placeholder: string
    changeValue: (newValue: string) => void
    onSubmit: () => void
}

const SearchBar: React.FC<PropsType> = ({value, placeholder, changeValue, onSubmit}) => {
    const classes = useSearchBarStyles()

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    }

    return (
        <Box className={classes.box}>
            <form className={classes.search} onSubmit={handleSubmit}>
                <div className={classes.icon}>
                    <SearchIcon/>
                </div>
                <InputBase
                    placeholder={placeholder}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    onChange={(e) => changeValue(e.target.value)}
                    value={value}
                />
                <div className={classes.icon}>
                    <CloseIcon onClick={() => changeValue('')}/>
                </div>
            </form>
        </Box>
    )
}

export default SearchBar

