import { getEventsAction } from 'api/admin/event-story/event.actions';
import AutocompleteCheckbox, {
    Option,
} from 'components/filter/autocomplete-checkbox';
import useDebounce from 'hooks/use-debounce';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useForm } from 'react-final-form';
import { FilterFormData, SearchProps } from './filter-event-story';

type PropsType = {
    name: string;
} & SearchProps;

const QUERY_CONF: qs.IStringifyOptions = {
    encode: false,
    arrayFormat: 'brackets',
    addQueryPrefix: true,
};

let timeoutID: NodeJS.Timeout;

const EventsFields: React.FC<PropsType> = ({ name, searchByHandle }) => {
    const form = useForm();
    const { event_id_in, stories_id_in } = form.getState()
        .values as FilterFormData;
    const { query: queryEvents } = useParameterizedQuery(getEventsAction);

    const [searchTerm, setSearchTerm] = useState('');
    const [options, setOptions] = useState<Option[]>([]);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const selectedList = useMemo(
        () =>
            options.filter(({ value }) => event_id_in.includes(String(value))),
        [event_id_in, options]
    );

    useEffect(() => {
        let filterParamsStr = qs.stringify(
            {
                search: debouncedSearchTerm,
                q: {
                    active_eq: true,
                },
            },
            QUERY_CONF
        );

        if (event_id_in) {
            filterParamsStr = qs.stringify(
                {
                    q: {
                        event_id_in,
                        active_eq: true,
                    },
                },
                QUERY_CONF
            );
        }

        queryEvents(filterParamsStr).then(({ payload }) => {
            if (payload)
                setOptions(
                    payload.events.map(({ id, title }) => ({
                        value: String(id),
                        title,
                    }))
                );
        });
    }, [debouncedSearchTerm]);

    useEffect(() => {
        clearTimeout(timeoutID);
        timeoutID = setTimeout(form.submit, 50);
    }, [form, event_id_in, stories_id_in]);

    const changeHandle = useCallback(() => searchByHandle('event'), [
        searchByHandle,
    ]);

    return (
        <AutocompleteCheckbox
            name={name}
            options={options}
            setSearchValue={setSearchTerm}
            onChangeHandle={changeHandle}
            value={selectedList}
        />
    );
};
export default EventsFields;
