import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ISignInParams, signInAction } from 'api/admin/sign_in';
import { TEST_USER } from 'config/develop';
import { IS_DEV } from 'constants/common';
import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { Form } from 'react-final-form';
import SignInForm from './sign-in-form';
import TwoFA from './two-FA';

export default function SignInPage() {
    const signInAPI = useMutation(signInAction);
    const [error, setError] = useState('');

    async function onSubmit(data: ISignInParams) {
        const { error, payload: pl } = await signInAPI.mutate(data);
        if (error)
            pl.errors instanceof Array &&
                pl.errors[0] &&
                setError(pl.errors[0].code);
    }

    if (signInAPI.loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={IS_DEV ? TEST_USER : { email: '', password: '' }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h2">Log In</Typography>
                                <p>for registered users</p>
                            </Grid>
                            <SignInForm error={error} />
                        </Grid>
                    </form>
                )}
            </Form>
            <TwoFA
                isOpen={error === 'user_need_2FA'}
                handleClose={() => setError('')}
            />
        </>
    );
}
