import { DateTime, DateTimeFormatOptions } from 'luxon';

export const humanize = function (string: string = ''): string {
    return string
        .replace(/\w/, (c) => c.toUpperCase())
        .replace(/_/g, ' ')
        .replace(/\sid/g, ' ID');
};

export const humanizeDate = (
    date: string,
    format: string | DateTimeFormatOptions = 'dd.LL.yyyy'
): string => {
    const FROM_ISO  = DateTime.fromISO(date);
    if (typeof format === 'string') {
        return FROM_ISO.toFormat(format)
    } else if (format) {
        return FROM_ISO.toLocaleString(format)!;
    }
    return '';
};
