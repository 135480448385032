import * as actions from './story.actions';
import createQueryContext from 'react-fetching-library-context';
import { useMutation } from 'react-fetching-library';
import {
    StoriesResponseType,
    StoryParamsType,
    StoryType,
    StoryUpdateActionParams,
} from './stories.types';
import qs from 'qs';
import { useEvents } from './events';

export const {
    useQueryContext: useStories,
    Provider: StoriesProvider,
} = createQueryContext<StoriesResponseType>(actions.getStoriesAction);

export function useCreateStory() {
    const createStory = useMutation(actions.createStoryAction);
    const storiesList = useStories();

    return async function (params: StoryParamsType) {
        const result = await createStory.mutate(params);

        try {
            const filterParamsStr = qs.stringify(
                { q: { event_id_in: params.event_id } },
                {
                    encode: false,
                    arrayFormat: 'brackets',
                    addQueryPrefix: true,
                }
            );

            await storiesList.query(filterParamsStr);
        } catch (err) {}

        return result;
    };
}

export function useUpdateStory() {
    const updateStory = useMutation(actions.updateStoryAction);

    return async function (params: StoryUpdateActionParams) {
        return updateStory.mutate(params);
    };
}

export function useDeleteStory() {
    const deleteStory = useMutation(actions.deleteStoryAction);
    const storiesList = useStories();

    return async function (story: StoryType) {
        await deleteStory.mutate(story.id);

        const filterParamsStr = qs.stringify(
            { q: { event_id_in: story.event_id } },
            { encode: false, arrayFormat: 'brackets', addQueryPrefix: true }
        );

        return storiesList.query(filterParamsStr);
    };
}

export function useMergeWithStory() {
    const mergeWithStory = useMutation(actions.mergeWithStoryAction);
    const eventsList = useEvents();

    return async function (params: {
        id: number;
        id_merge: number;
        event_id: number;
    }) {
        await mergeWithStory.mutate(params);
        return eventsList.query('');
    };
}

export function useMergeWithEvent() {
    const mergeWithEvent = useMutation(actions.mergeWithEventAction);
    const eventsList = useEvents();

    return async function (params: { id_merge: number; event_id: number }) {
        await mergeWithEvent.mutate(params);
        return eventsList.query('');
    };
}
