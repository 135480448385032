import {
	Box,
	Divider,
	Grid,
	Link,
	TextField,
	Typography,
} from "@material-ui/core";
import { useUpdateEntryURL } from "api/admin/ugc/entries";
import { UGCEntryURL } from "api/admin/ugc/entries.types";
import { ViewImage } from "components/screenshot";
import { fillEmptyStr } from "helpers/strings";
import React from "react";
import { Field, Form } from "react-final-form";
import { useRequestStyles } from "../ugc-request.styles";

interface IUGCRequestURL {
	url: UGCEntryURL;
	disabledUpd: boolean;
}

function UGCRequestURL({ url, disabledUpd }: IUGCRequestURL) {
	const classes = useRequestStyles();
	const updateEntryAPI = useUpdateEntryURL();

	function submitHandler({ title }: { title: string }) {
		if (title !== url.title) {
			updateEntryAPI({
				accepted_status: url.accepted_status,
				id: url.id,
				title,
			});
		}
	}

	return (
		<Form onSubmit={submitHandler} initialValues={url}>
			{({ handleSubmit, form }) => (
				<form onSubmit={handleSubmit}>
					<Box my={3}>
						<Grid key={url.id} container justify="flex-end">
							<Grid item lg={3}>
								<Typography
									variant="h6"
									component="h6"
									className={classes.subCaption}
								>
									URL:
								</Typography>
								<Link
									href={url.link}
									className={classes.text}
									color="inherit"
									target="_blank"
									rel="noopener noreferrer"
									underline="always"
								>
									{url.link}
								</Link>
							</Grid>
							<Grid item lg={6}>
								<Box ml={3}>
									<Typography
										variant="h6"
										component="h6"
										className={classes.subCaption}
									>
										Description
									</Typography>
									<Typography className={classes.text}>
										{url.description}
									</Typography>
									<Grid container alignItems="center">
										<Grid item lg={10}>
											<Typography
												variant="h6"
												component="span"
												className={classes.subCaption}
											>
												Video duration:
											</Typography>
											<Typography component="span" className={classes.text}>
												&nbsp;
												{fillEmptyStr(url.content_timing)}
											</Typography>
										</Grid>
									</Grid>

									<Grid container>
										{url.images?.map((img, index) => {
											return (
												<Box m={1} key={index}>
													<ViewImage link={img.link} />
												</Box>
											);
										})}
									</Grid>
								</Box>
							</Grid>
							<Grid item lg={3} />
							<Grid item lg={9}>
								<Box mt={3}>
									<Divider />
								</Box>
							</Grid>
						</Grid>
					</Box>
				</form>
			)}
		</Form>
	);
}

export default UGCRequestURL;
