import React from "react";
import {makeStyles, TextField, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useField} from "react-final-form";

type PropsType = {
    name: string
    title: string
    options: Array<any>
    setSearchValue: (value: string) => void
}
const AutocompleteTextField: React.FC<PropsType> =
    ({name, options = [], setSearchValue, title}) => {

        const classes = useStyles();
        const fromField = useField(name);

        const handleChange = (event: any, value: any) => {
            fromField.input.onChange(value)
        }

        return (
            <div className={classes.content}>
                <Autocomplete
                    value={fromField.input.value}
                    onChange={handleChange}
                    size='small'
                    options={options}
                    groupBy={(option) => option.event_title}
                    getOptionSelected={(option: any, value) => {
                        return (option.id === value.id);
                    }}
                    getOptionLabel={(option) => {
                        return option.title || ''
                    }}
                    renderOption={(option) => {
                        return <Typography>{option.title}</Typography>
                    }}
                    renderInput={(params) => (
                        <TextField {...params}
                                   variant="outlined"
                                   error={fromField.meta.error}
                                   placeholder=
                                       {`Start typing the name of ${title}`}
                                   onChange=
                                       {e => setSearchValue(e.target.value)}
                        />
                    )}
                />
            </div>
        )
    }
export default React.memo(AutocompleteTextField)

const useStyles = makeStyles({
    content: {
        padding: 0,
    },
});