import React, { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import Slide, { SlideProps } from '@material-ui/core/Slide';

type TransitionProps = Omit<SlideProps, 'direction'>;

interface ISnackbarProps {
    open: boolean;
    handleClose: () => void;
    severity?: 'success' | 'info' | 'warning' | 'error';
    text: string;
}

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

export default function ModifySnackbar({
    open = false,
    handleClose,
    severity = 'success',
    text = 'Msg',
}: ISnackbarProps) {
    const [transition, setTransition] = useState<
        React.ComponentType<TransitionProps> | undefined
    >(undefined);

    useEffect(() => {
        if (open) {
            setTransition(() => TransitionDown);
        }
    }, [open]);

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionComponent={transition}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            key={transition ? transition.name : ''}
        >
            <Alert onClose={handleClose} severity={severity}>
                {text}
            </Alert>
        </Snackbar>
    );
}
