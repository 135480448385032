import React from 'react';
import { Form } from 'react-final-form';
import FilterBlock from '../../components/filter/filter-block';
import FilterDateRange from '../../components/filter/filter-date-range';
import { Button, makeStyles, Paper } from '@material-ui/core';
import { CountriesField } from '../../components/filter/countries-field';
import arrayMutators from 'final-form-arrays';
import CheckboxField from 'components/filter/checkbox-group';

type PropsType = {
    onSubmit: (values: any) => void;
    onResetAll: () => void;
    filterData: any;
};

const FilterUsers: React.FC<PropsType> = ({
    onSubmit,
    onResetAll,
    filterData,
}) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Form
                onSubmit={onSubmit}
                mutators={{ ...arrayMutators }}
                initialValues={{ ...filterData }}
            >
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <FilterBlock
                            title="Last Activity"
                            defaultOpen
                            name={['updated_at_gteq', 'updated_at_lteq']}
                        >
                            <FilterDateRange
                                fromDateName="updated_at_gteq"
                                toDateName="updated_at_lteq"
                            />
                        </FilterBlock>

                        <FilterBlock title="Country" defaultOpen>
                            <CountriesField name="country_id_eq" />
                        </FilterBlock>
                        <FilterBlock>
                            <Button
                                color="primary"
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    marginTop: '15px',
                                }}
                                disabled={
                                    Object.keys(form.getState().values)
                                        .length === 0
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    form.reset();
                                    onResetAll();
                                }}
                            >
                                Reset all filters
                            </Button>
                        </FilterBlock>
                    </form>
                )}
            </Form>
        </Paper>
    );
};
export default FilterUsers;

const useStyles = makeStyles({
    paper: {
        padding: '10px 10px',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
    },
});
