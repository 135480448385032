import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    imageView: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&__img': {
            maxWidth: '90vw',
            height: '100vh',
            outline: 'none',
            display: 'block',
            objectFit: 'contain',
        },
        '&__close': {
            position: 'absolute',
            right: '1.4%',
            top: '1%',
            padding: 10,
            color: '#fff',
            fontSize: '20px',
            opacity: 0.8,

            '&:hover': {
                opacity: 1,
            },
        },
    },
    image: {
        objectFit: 'cover',
        width: 70,
        display: 'block',
        height: 50,
    },
}));
