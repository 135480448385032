import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { useTwoAuth } from 'api/admin/sign_in';
import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';

type TwoFAProps = {
    handleClose: () => void;
    isOpen: boolean;
};

export default function TwoFA({ isOpen, handleClose }: TwoFAProps) {
    const [doTwoAuthSignIn, loading] = useTwoAuth();
    const [error, setError] = useState(false);
    const submitHandle = useCallback(
        async ({ token }: { token: string }) => {
            const { error } = await doTwoAuthSignIn(token);
            error && setError(true);
        },
        [doTwoAuthSignIn]
    );

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            aria-labelledby="two-auth-popup"
        >
            <Form onSubmit={submitHandle} initialValues={{ token: '' }}>
                {({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle id="two-auth-popup">
                                Confirm authentication
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Two-factor authentication required. Please
                                    check your e-mail and fill the code below.
                                </DialogContentText>
                                <Field name="token">
                                    {({ input }) => (
                                        <TextField
                                            type="token"
                                            className="input"
                                            placeholder="token"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            error={error}
                                            style={{ background: 'white' }}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                {error && (
                                    <small style={{ color: 'red' }}>
                                        Wrong verification code
                                    </small>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button color="default" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={!!loading}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
        </Dialog>
    );
}
