import React from 'react';
import UGCRequest from './ugc-request';
import {
    UGCEntryBySlugProvider,
    UGCEntryProvider,
} from 'api/admin/ugc/entries';
import { EventsProvider } from '../../api/admin/event-story/events';
import { StoriesProvider } from '../../api/admin/event-story/stories';

export default function UGCRequestPage() {
    return (
        <UGCEntryProvider initFetch={false}>
            <UGCEntryBySlugProvider initFetch={false}>
                <EventsProvider initFetch={false}>
                    <StoriesProvider initFetch={false}>
                        <UGCRequest />
                    </StoriesProvider>
                </EventsProvider>
            </UGCEntryBySlugProvider>
        </UGCEntryProvider>
    );
}
