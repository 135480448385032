import {
    Box,
    Chip,
    Dialog,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ShareIcon from '@material-ui/icons/Share';
import { useCreateNewSlug, useCreateShareSlug } from 'api/admin/ugc/entries';
import { UGCEntry } from 'api/admin/ugc/entries.types';
import clsx from 'clsx';
import ModifySnackbar from 'components/snackbar';
import { UGCPopup } from 'components/UGC';
import { UGCRequestDetailsRoute } from 'config/routes';
import {
    NEW_SHARE_ALERT,
    NEW_UPLOAD_LINK_ALERT,
    SHARE_COPIED_ALERT,
} from 'constants/messages';
import copy from 'copy-to-clipboard';
import { fillEmptyStr } from 'helpers/strings';
import { DateTime } from 'luxon';
import UGCTitleBlock from 'pages/ugc-request/ugc-titile-block';
import React, { useCallback, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { FRONT_HOST } from '../../../config/develop';
import { UGC_REQUEST_STATUS, UGC_USER_STEPS } from '../../../constants/status';
import { useUGCRequestRowStyles } from './ugc-request-row.styles';

interface UGCRequestRowProps {
    data: UGCEntry;
}

const renewInitialState = { open: false, msg: '' };

export default function UGCRequestRow(props: UGCRequestRowProps) {
    const { data } = props;
    const [popup, setPopup] = useState<any>(null);
    const [sharedOpen, toggleShared] = useState<HTMLButtonElement | null>(null);
    const [renewConfig, setRenewConfig] = useState(renewInitialState);
    const allowEdit = data.status !== 'declined' && data.status !== 'approved';

    const history = useHistory();
    const classes = useUGCRequestRowStyles();
    const isDetailsPage = useRouteMatch(UGCRequestDetailsRoute.defaultProps);
    const [expanded, setExpanded] = React.useState(!!isDetailsPage);
    const createNewSlug = useCreateNewSlug();
    const createShareSlug = useCreateShareSlug();

    function closeShareMenu(): void {
        toggleShared(null);
    }

    function renewSlug(isShared: boolean = false, isRenew: boolean = false) {
        if (isShared) {
            createShareSlug({ id: data.id, renew: isRenew }).then((resp) => {
                copy(
                    `${document.location.origin}/ugc-requests/${resp.payload.admin_share_slug}/share`
                );
            });
            setRenewConfig({
                open: true,
                msg: isRenew ? NEW_SHARE_ALERT : SHARE_COPIED_ALERT,
            });
        } else {
            createNewSlug({ id: data.id }).then((resp) => {
                copy(`${FRONT_HOST}/ugc-request/${resp.payload.slug}`);
            });
            setRenewConfig({
                open: true,
                msg: NEW_UPLOAD_LINK_ALERT,
            });
        }
        closeShareMenu();
    }

    const handleExpandClick = useCallback(() => {
        setExpanded((prev) => !prev);
    }, []);

    const snackbarCloseHandler = useCallback(() => {
        setRenewConfig(renewInitialState);
    }, []);

    return (
        <Card className={classes.root}>
            <ModifySnackbar
                text={renewConfig.msg}
                open={renewConfig.open}
                handleClose={snackbarCloseHandler}
            />
            <Dialog open={popup} onClose={() => setPopup(null)} scroll="body">
                <UGCPopup data={popup} onClose={() => setPopup(null)} />
            </Dialog>
            <CardHeader
                avatar={
                    <Chip
                        label={`${data.price} ${data.currency}`}
                        color="primary"
                        style={{ fontWeight: 900 }}
                    />
                }
                action={
                    isDetailsPage ? null : (
                        <Tooltip title="Show details">
                            <IconButton
                                aria-label="show details"
                                onClick={() => {
                                    history.push(
                                        UGCRequestDetailsRoute.getLink({
                                            id: String(data.id),
                                        })
                                    );
                                }}
                            >
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
                title={
                    <Box m={1}>
                        <Grid container spacing={3}>
                            <Typography
                                variant="h6"
                                component="span"
                                className={classes.subCaption}
                            >
                                UGC-{data.id}:&nbsp;
                            </Typography>
                            <Box mr={2}>{data.title}</Box>
                            <Box>{`${DateTime.fromISO(data.created_at).toFormat(
                                'LLLL d, yyyy'
                            )}`}</Box>
                        </Grid>
                    </Box>
                }
                subheader={
                    <Grid
                        container
                        spacing={6}
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <UGCTitleBlock
                            capt="Event"
                            text={fillEmptyStr(data.event_name)}
                        />
                        <UGCTitleBlock
                            capt="Story"
                            text={
                                (data.stories.length !== 0 &&
                                    data.stories[0].story_name) ||
                                'N/A'
                            }
                        />
                        <UGCTitleBlock
                            capt="Status"
                            text={fillEmptyStr(UGC_REQUEST_STATUS[data.status])}
                        />
                        <UGCTitleBlock
                            capt="User step"
                            text={fillEmptyStr(UGC_USER_STEPS[data.user_step])}
                        />
                        <UGCTitleBlock
                            capt="Location"
                            text={`${fillEmptyStr(
                                data.country_name,
                                data.location,
                                { separator: ', ' }
                            )}`}
                        />
                    </Grid>
                }
            />
            <CardActions disableSpacing>
                {allowEdit && (
                    <Tooltip title="Edit request">
                        <IconButton
                            aria-label="Edit request"
                            onClick={() => setPopup(data)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <IconButton
                    onClick={({ currentTarget }) => toggleShared(currentTarget)}
                    aria-controls="share-menu"
                    aria-haspopup="true"
                >
                    <ShareIcon />
                </IconButton>
                <Menu
                    id="share-menu"
                    keepMounted
                    anchorEl={sharedOpen}
                    open={Boolean(sharedOpen)}
                    onClose={closeShareMenu}
                >
                    <MenuItem onClick={() => renewSlug(true)}>
                        Copy share link
                    </MenuItem>
                    <MenuItem onClick={() => renewSlug(true, true)}>
                        Reset share link
                    </MenuItem>
                    <MenuItem onClick={() => renewSlug()}>
                        Reset and copy upload link
                    </MenuItem>
                </Menu>
                {!isDetailsPage && (
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                )}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Table aria-label="simple table">
                        <TableBody>
                            <ReqProperty label="Price" value={data.price} />
                            <ReqProperty
                                label="Country"
                                value={data.country_name}
                            />
                            <ReqProperty
                                label="Location"
                                value={data.location}
                            />
                            <ReqProperty
                                label="Exclusive type"
                                value={data.exclusive_type}
                            />
                            <ReqProperty label="Location link">
                                <Link
                                    to={data.location_link || ''}
                                    style={
                                        !data.location_link
                                            ? { pointerEvents: 'none' }
                                            : void 0
                                    }
                                >
                                    {fillEmptyStr(data.location_link)}
                                </Link>
                            </ReqProperty>
                            <ReqProperty label="News links">
                                {data.news_links.map(
                                    (newsLink: string | any) => (
                                        <Typography
                                            component="p"
                                            className={classes.subCaption}
                                        >
                                            {fillEmptyStr(newsLink)}
                                        </Typography>
                                    )
                                )}
                            </ReqProperty>
                        </TableBody>
                    </Table>
                </CardContent>
            </Collapse>
        </Card>
    );
}

function ReqProperty({
    label,
    value,
    children,
}: {
    label: string;
    value?: string | null | number;
    children?: any;
}) {
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {label}
            </TableCell>
            <TableCell align="right">
                {children || fillEmptyStr(value)}
            </TableCell>
        </TableRow>
    );
}
