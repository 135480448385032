import { Grid } from '@material-ui/core';
import { UGCEntry } from 'api/admin/ugc/entries.types';
import { UGC_REQUEST_STATUS, UGC_USER_STEPS } from 'constants/status';
import { fillEmptyStr } from 'helpers/strings';
import React from 'react';
import UGCTitleBlock from './ugc-titile-block';

interface IUGCHeadInfoProps {
    entry: UGCEntry;
}

export default function UGCHeadInfo({ entry }: IUGCHeadInfoProps) {
    return (
        <Grid container justify="space-between">
            <UGCTitleBlock capt="Event" text={fillEmptyStr(entry.event_name)} />
            <UGCTitleBlock
                capt="Story"
                text={fillEmptyStr(
                    entry.stories.length !== 0 && entry.stories[0].story_name
                )}
            />
            <UGCTitleBlock
                capt="Status"
                text={UGC_REQUEST_STATUS[entry.status]}
            />
            <UGCTitleBlock capt="Price" text={`${entry.price} ${entry.currency}`} />
            <UGCTitleBlock
                capt="User Step"
                text={fillEmptyStr(UGC_USER_STEPS[entry.user_step])}
            />
            <UGCTitleBlock
                capt="Location"
                text={fillEmptyStr(entry.country_name, entry.location, {
                    separator: ', ',
                })}
            />
        </Grid>
    );
}
