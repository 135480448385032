import { useCountries } from "api/admin/countries";
import React, { useMemo } from "react";
import { MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { useField, useForm } from "react-final-form";

const ENTRY_CURRENCIES = [
    'USD', 'EUR', 'GBP', 'RUB', 'CAN',
]

type Props = {
    name: string;
    disabled?: boolean;
}

const CurrencyField: React.FC<Props> = ({
    name,
    disabled = false
}) => {
    const fromField = useField(name);
    const form = useForm();

    const options = useMemo(() => {
        return ENTRY_CURRENCIES;
    }, []);

    const handleChange = (event: any) => {
        event.preventDefault();
        fromField.input.onChange(event.target.value)
    };

    return (
        <Select
            value={fromField.input.value}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            input={<OutlinedInput margin='dense'/>}
            disabled={disabled}
        >
            {options.map((option: any) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    )
}

export default CurrencyField;