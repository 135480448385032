import React from 'react';
import {
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import TableContainer from '@material-ui/core/TableContainer';
import { EventType } from '../../../api/admin/event-story/events.types';
import { StoryType } from '../../../api/admin/event-story/stories.types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import qs from 'qs';
import RowStoryAction from './story-row-actions';
import RowEventAction from './event-row-actions';
import { useStories } from 'api/admin/event-story/stories';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from '../../../store';
import { actions } from '../../../reducers/events-stories/actions';
import ModifySnackbar from 'components/snackbar';
import { useState } from 'react';

export type LabelType = {
    key: string;
    label: string;
};
const labels: Array<LabelType> = [
    {
        key: 'name',
        label: 'Name',
    },
    {
        key: 'used-total',
        label: 'Total number',
    },
    {
        key: 'created-date',
        label: 'Creation Date',
    },
    {
        key: 'active',
        label: 'Active',
    },
    {
        key: 'actions',
        label: 'Actions',
    },
];

const storyActionErrorInit = { show: false, msg: '' };

type PropsTable = {
    dataEvents: Array<EventType>;
};
const EventsStoriesTable: React.FC<PropsTable> = ({ dataEvents }) => {
    const classes = useStyles();

    return (
        <div className={classes.table}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {labels.map((l) => (
                                <TableCell align="center" key={l.key}>
                                    {l.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataEvents.map((row: EventType) => (
                            <CollapseRow key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default EventsStoriesTable;

type PropsType = {
    row: EventType;
};
const CollapseRow: React.FC<PropsType> = ({ row }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const stories = useStories();
    const { data_stories } = useSelector(
        (store: AppStateType) => store.dataEventsStories
    );
    const [open, setOpen] = React.useState(false);

    const event_id: number = row.id;
    const event_id_str: string = event_id.toString();

    const [errorByAction, setErrorByAction] = useState(storyActionErrorInit);

    function errorShowHandler(msg: string) {
        setErrorByAction({ show: true, msg });
    }

    function errorCloseHandler() {
        setErrorByAction(storyActionErrorInit);
    }

    React.useEffect(() => {
        if (open) {
            const filterParamsStr = qs.stringify(
                { q: { event_id_in: [event_id] } },
                {
                    encode: false,
                    arrayFormat: 'brackets',
                    addQueryPrefix: true,
                }
            );
            stories.query(filterParamsStr).then((result) => {
                if (result.payload !== undefined) {
                    dispatch(
                        actions.setStories(
                            result.payload.stories,
                            event_id,
                            true
                        )
                    );
                }
            });
        } else {
            if (data_stories[event_id_str]) {
                dispatch(actions.setOpenStories(event_id, false));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, row.active]);

    return (
        <>
            <ModifySnackbar
                text={errorByAction.msg}
                open={errorByAction.show}
                handleClose={errorCloseHandler}
                severity="error"
            />
            <TableRow hover style={{ cursor: 'pointer' }}>
                <TableCell align="left" className={classes.colorText}>
                    {row.title}
                    <span className={classes.boxBlue}>
                        {`${
                            row.stories_count === null ? 0 : row.stories_count
                        } stories`}
                        <IconButton
                            aria-label="expand row"
                            className={classes.icon}
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {data_stories[event_id_str] &&
                            data_stories[event_id_str].openStories ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </span>
                </TableCell>
                <TableCell align="center">
                    {row.connectables_count === null
                        ? 0
                        : row.connectables_count}
                </TableCell>
                <TableCell align="center">
                    {DateTime.fromISO(row.created_at).toFormat(
                        'LLLL d, yyyy HH:mm a'
                    )}
                </TableCell>
                <TableCell align="center">
                    {row.active ? 'Yes' : 'No'}
                </TableCell>
                <TableCell align="center">
                    <RowEventAction row={row} />
                </TableCell>
            </TableRow>
            {data_stories[event_id_str] &&
                data_stories[event_id_str].openStories &&
                data_stories[event_id_str].stories.map(
                    (storyRow: StoryType) => (
                        <TableRow key={storyRow.id}>
                            <TableCell align="left" style={{ paddingLeft: 50 }}>
                                {storyRow.title}
                            </TableCell>
                            <TableCell align="center">
                                {storyRow.connectables_count === null
                                    ? 0
                                    : storyRow.connectables_count}
                            </TableCell>
                            <TableCell align="center">
                                {DateTime.fromISO(storyRow.created_at).toFormat(
                                    'LLLL d, yyyy HH:mm a'
                                )}
                            </TableCell>
                            <TableCell align="center">
                                {storyRow.active ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell align="center">
                                {row.active && (
                                    <RowStoryAction
                                        row={storyRow}
                                        onError={errorShowHandler}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    )
                )}
        </>
    );
};

const useStyles = makeStyles({
    table: {
        marginBottom: 10,
    },
    colorText: {
        color: 'blue',
    },
    boxBlue: {
        fontSize: '12px',
        fontWeight: 400,
        backgroundColor: 'blue',
        borderRadius: 50,
        marginLeft: 20,
        color: 'white',
        padding: '5px 0px 5px 10px',
    },
    icon: {
        color: 'white',
    },
});
