import { Button, makeStyles, Paper } from '@material-ui/core';
import SearchField from 'components/filter/search-field';
import arrayMutators from 'final-form-arrays';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import CheckboxField from '../../../components/filter/checkbox-group';
import { CountriesField } from '../../../components/filter/countries-field';
import FilterBlock from '../../../components/filter/filter-block';
import FilterDateRange from '../../../components/filter/filter-date-range';

const optionsStep = [
    { id: '0', label: 'Awaiting for Content' },
    { id: '1', label: 'Awaiting for Personal data' },
    { id: '2', label: 'Awaiting for Billing Address' },
    { id: '3', label: 'Awaiting for Payment information' },
    { id: '4', label: 'Signing Contract' },
    { id: '5', label: 'Signing Invoice' },
    { id: '11', label: 'Email confirmation' },
    { id: '6', label: 'Sent to review' },
    { id: '7', label: 'Data correction' },
];

const optionsStatus = [
    { id: '1', label: 'Link sent' },
    { id: '2', label: 'Waiting for the content' },
    { id: '3', label: 'Waiting for User Data' },
    { id: '4', label: 'Review' },
    { id: '5', label: 'Accepted' },
    { id: '6', label: 'Denied' },
    { id: '9', label: 'Data correction' },
    { id: '10', label: 'Email confirmation' },
];

type PropsType = {
    onSubmit: (values: any) => void;
    onResetAll: () => void;
    filterData: any;
};

const FilterRequests: React.FC<PropsType> = ({
    onSubmit,
    onResetAll,
    filterData,
}) => {
    const classes = useStyles();
    const [reset, setReset] = useState<boolean>(false);

    return (
        <Paper className={classes.paper}>
            <Form
                onSubmit={onSubmit}
                mutators={{ ...arrayMutators }}
                initialValues={{ ...filterData }}
            >
                {({ handleSubmit, form, values }) => {
                    function disabledReset(): boolean {
                        const data = { ...values };
                        delete data.event_in;
                        delete data.story_in;

                        return Object.keys(data).length === 0;
                    }

                    return (
                        <form onSubmit={handleSubmit}>
                            <FilterBlock
                                title="Date"
                                defaultOpen
                                name={['created_at_gteq', 'created_at_lteq']}
                            >
                                <FilterDateRange
                                    fromDateName="created_at_gteq"
                                    toDateName="created_at_lteq"
                                />
                            </FilterBlock>

                            <FilterBlock title="User`s step">
                                <CheckboxField
                                    name="user_step_in"
                                    options={optionsStep}
                                />
                            </FilterBlock>

                            <FilterBlock title="Request status">
                                <CheckboxField
                                    name="status_in"
                                    options={optionsStatus}
                                />
                            </FilterBlock>

                            <FilterBlock title="Country" defaultOpen>
                                <CountriesField name="country_id_eq" />
                            </FilterBlock>

                            <FilterBlock title="Location">
                                <SearchField
                                    name="search"
                                    reset={reset}
                                    setReset={setReset}
                                />
                            </FilterBlock>

                            <FilterBlock>
                                <Button
                                    color="primary"
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        marginTop: '15px',
                                    }}
                                    disabled={disabledReset()}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        form.reset();
                                        setReset(true);
                                        onResetAll();
                                    }}
                                >
                                    Reset all filters
                                </Button>
                            </FilterBlock>
                        </form>
                    );
                }}
            </Form>
        </Paper>
    );
};
export default FilterRequests;

const useStyles = makeStyles({
    paper: {
        padding: '10px 10px',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
    },
});
