import { StoryType } from 'api/admin/event-story/stories.types';
import { getStoriesAction } from 'api/admin/event-story/story.actions';
import AutocompleteCheckbox, {
    Option,
} from 'components/filter/autocomplete-checkbox';
import useDebounce from 'hooks/use-debounce';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useForm } from 'react-final-form';
import { FilterFormData, SearchProps } from './filter-event-story';

type PropsType = {
    name: string;
} & SearchProps;

const QUERY_CONF: qs.IStringifyOptions = {
    encode: false,
    arrayFormat: 'brackets',
};

function getStoriesList(list: StoryType[]) {
    return list.map(({ event_title, title, id, event_id }) => ({
        value: String(id),
        title,
        label: event_title,
        event_id,
    }));
}

const StoriesFields: React.FC<PropsType> = ({
    name,
    searchByHandle,
    searchBy,
}) => {
    const form = useForm();
    const { event_id_in, stories_id_in } = form.getState()
        .values as FilterFormData;
    const { query: queryStories, payload } = useParameterizedQuery(
        getStoriesAction
    );

    const [searchTerm, setSearchTerm] = useState('');
    const [options, setOptions] = useState<Option[]>([]);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const selectedList = useMemo(
        () =>
            options.filter(({ value }) =>
                stories_id_in.includes(String(value))
            ),
        [stories_id_in, options]
    );

    useEffect(() => {
        if (payload && searchBy === 'event') {
            let { stories } = payload;

            if (event_id_in.length > 0) {
                stories = payload.stories.filter((story) =>
                    event_id_in.includes(String(story.event_id))
                );
            }
            const storiesIDs = stories_id_in.filter((id) => {
                const story = stories.find((story) => String(story.id) === id);
                return story && event_id_in.includes(String(story.event_id));
            });
            form.change('stories_id_in', storiesIDs);
            setOptions(getStoriesList(stories));
        }
    }, [event_id_in, payload, searchBy]);

    useEffect(() => {
        if (searchBy === 'story' && payload) {
            let events: string[] = [...event_id_in];

            payload.stories.forEach(({ event_id, id }) => {
                const _id = String(event_id);
                if (!events.includes(_id) && stories_id_in.includes(String(id)))
                    events.push(_id);
            });

            form.change('event_id_in', events);
        }
    }, [searchBy, stories_id_in, form]);

    useEffect(() => {
        let filterParamsStr = qs.stringify(
            {
                search: debouncedSearchTerm,
                q: { event_id_in, active_eq: true },
            },
            QUERY_CONF
        );

        if (stories_id_in) {
            filterParamsStr = qs.stringify(
                {
                    q: {
                        stories_id_in,
                        active_eq: true,
                    },
                },
                QUERY_CONF
            );
        }

        queryStories(`?${filterParamsStr}`).then(({ payload }) => {
            if (payload) setOptions(getStoriesList(payload.stories));
        });
    }, [debouncedSearchTerm]);

    const changeHandle = useCallback(() => {
        if (!event_id_in.length) {
            searchByHandle('story');
        }
    }, [searchByHandle, event_id_in, form]);

    return (
        <AutocompleteCheckbox
            name={name}
            options={options}
            setSearchValue={setSearchTerm}
            value={selectedList}
            onChangeHandle={changeHandle}
        />
    );
};
export default StoriesFields;
