import React, { useState } from 'react';
import { Dialog, IconButton, Menu, MenuItem } from '@material-ui/core';
import { EventType } from '../../../api/admin/event-story/events.types';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import {useDeleteEvent} from "../../../api/admin/event-story/events";
import EventMerge from './event-actions/event-merge';
import EventRename from './event-actions/event-rename';
import { useUpdateEvent } from 'api/admin/event-story/events';

type PropsType = {
    row: EventType;
};
const RowEventAction: React.FC<PropsType> = ({ row }) => {
    const [popupEventMerge, setPopupEventMerge] = useState<any>(null);
    const [popupEventRename, setPopupEventRename] = useState<any>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const updateEvent = useUpdateEvent();
    // const deleteEvent = useDeleteEvent();

    const onClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const onMerge = () => {
        setPopupEventMerge(row);
        setAnchorEl(null);
    };

    // const onRemove = () => {
    //     deleteEvent(row.id);
    //     setAnchorEl(null);
    // };

    const onRename = () => {
        setPopupEventRename(row);
        setAnchorEl(null);
    };

    function onToggleActiveHandler() {
        updateEvent({ id: row.id, active: !row.active });
        setAnchorEl(null);
    }

    return (
        <>
            <div>
                <IconButton aria-label="more" onClick={onClick}>
                    <MoreHorizIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={onRename}>Rename</MenuItem>
                    <MenuItem onClick={onMerge}>Remove and replace</MenuItem>
                    <MenuItem onClick={onToggleActiveHandler}>
                        {row.active ? 'Disactivate' : 'Activate'}
                    </MenuItem>

                    {/*<MenuItem onClick={onRemove}>*/}
                    {/*    Remove*/}
                    {/*</MenuItem>*/}
                </Menu>
            </div>
            <Dialog
                open={popupEventMerge}
                fullWidth
                onClose={() => setPopupEventMerge(null)}
            >
                <EventMerge
                    data={popupEventMerge}
                    onClose={() => setPopupEventMerge(null)}
                />
            </Dialog>
            <Dialog
                open={popupEventRename}
                fullWidth
                onClose={() => setPopupEventRename(null)}
            >
                <EventRename
                    data={popupEventRename}
                    onClose={() => setPopupEventRename(null)}
                />
            </Dialog>
        </>
    );
};
export default RowEventAction;
