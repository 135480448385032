import React, { useState } from 'react';
import UsersTable from './users-table';
import { Box, Button, Container, Grid } from '@material-ui/core';
import SearchBar from '../../components/searchBar/search-bar';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { UsersRoute } from '../../config/routes';
import { useParameterizedQuery } from 'react-fetching-library';
import { getUsersAction } from '../../api/admin/users/users.actions';
import qs from 'qs';
import FilterUsers from './filter-users';
import { useUsersStyles } from './users.styles';
import { UserType } from '../../api/admin/users/users.types';

const Users: React.FC = () => {
    const classes = useUsersStyles();
    const location = useLocation();
    let history = useHistory();
    const [usersData, setUsersData] = useState<Array<UserType>>([]);
    const [filterData, setFilterData] = useState<any>(getFilterDataFromUrl());
    const [valueSearchBar, setValueSearchBar] = useState<string>(
        getSearchValueFromUrl()
    );
    const { payload, query } = useParameterizedQuery(getUsersAction);

    React.useEffect(() => {
        query(location.search).then((result) => {
            setUsersData(
                result.payload?.users !== undefined ? result.payload.users : []
            );
        });
    }, [location.search, setUsersData]);

    function getSearchValueFromUrl() {
        const obj = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (obj.search) return obj.search.toString();
        else return '';
    }

    function getFilterDataFromUrl() {
        let data: any = { q: {}, search: {} };
        data = qs.parse(location.search, { ignoreQueryPrefix: true });

        return { ...data.q };
    }

    const handleSearch = React.useCallback(() => {
        const searchParamsStr = getSearchParamsStr(valueSearchBar, {
            q: filterData,
        });
        history.push(UsersRoute.getLink() + searchParamsStr);
    }, [valueSearchBar]);

    const handleChange = React.useCallback(
        (newValue: string) => {
            setValueSearchBar(newValue);
            if (newValue === '') {
                const search = getSearchParamsStr('', { q: filterData });
                history.push(UsersRoute.getLink() + search);
            }
        },
        [setValueSearchBar, filterData]
    );

    const onResetAll = React.useCallback(() => {
        setFilterData({});
        const search = getSearchParamsStr(valueSearchBar, {});
        history.push(UsersRoute.getLink() + search);
    }, [valueSearchBar]);

    function createParamsObj(values: any) {
        const params: any = { q: {} };

        if (values.source_ranking_in) {
            params.q.source_ranking_in = [];
            values.source_ranking_in.forEach((item: any) =>
                params.q.source_ranking_in.push(item)
            );
        }

        values.country_id_eq && (params.q.country_id_eq = values.country_id_eq);

        return params;
    }

    function getSearchParamsStr(searchStr: string, filterObj: any) {
        const filterParamsStr = qs.stringify(filterObj, {
            encode: false,
            arrayFormat: 'brackets',
        });
        // const filterParamsStr = qs.stringify(filterObj, {encode: false})

        if (searchStr && filterParamsStr)
            return `?search=${searchStr}&${filterParamsStr}`;
        if (filterParamsStr) return `?${filterParamsStr}`;
        if (searchStr) return `?search=${searchStr}`;
        return '';
    }

    function onFilterSubmit(values: any) {
        const paramsObj = createParamsObj(values);
        values.updated_at_gteq &&
            (paramsObj.q.updated_at_gteq = values.updated_at_gteq);
        values.updated_at_lteq &&
            (paramsObj.q.updated_at_lteq = values.updated_at_lteq);
        setFilterData({ ...paramsObj.q });

        const search = getSearchParamsStr(valueSearchBar, paramsObj);

        history.push(UsersRoute.getLink() + search);
    }

    return (
        <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={4}>
                <Grid item xs={9}>
                    <SearchBar
                        value={valueSearchBar}
                        placeholder="Search user by email or name"
                        onSubmit={handleSearch}
                        changeValue={handleChange}
                    />
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                        >
                            List
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={true}
                        >
                            Map
                        </Button>
                    </Box>
                    <div>
                        <UsersTable data={usersData} />
                        {payload &&
                            payload?.pagy.count > 20 &&
                            payload?.pagy.count > usersData.length && (
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.buttonShow}
                                    onClick={() => {
                                        const paramsStr =
                                            location.search !== ''
                                                ? `${location.search}&page=${
                                                      payload?.pagy.page + 1
                                                  }`
                                                : `?page=${
                                                      payload?.pagy.page + 1
                                                  }`;
                                        query(paramsStr).then((result) => {
                                            setUsersData(
                                                result.payload !== undefined
                                                    ? [
                                                          ...usersData,
                                                          ...result.payload
                                                              .users,
                                                      ]
                                                    : [...usersData]
                                            );
                                        });
                                    }}
                                >
                                    Load more
                                </Button>
                            )}
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <FilterUsers
                        onSubmit={onFilterSubmit}
                        onResetAll={onResetAll}
                        filterData={filterData}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default withRouter(Users);
