import { makeStyles } from "@material-ui/core";

export const useUGCRequestsStyles = makeStyles({
    top: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 20,
    },
    buttonShow: {
        width: '100%',
        textTransform: 'none',
        shadows: "none"
    },
});
