import React, { Fragment, useEffect, useState } from "react";
import { Field, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import {
	Box,
	Button,
	DialogContentText,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { EntryStatus, UGCEntryURL } from "api/admin/ugc/entries.types";
import { UGCDialogScreenshots, UGCImageUploadOfURL } from "components/UGC";
import { TIMECODE_FORMAT } from "constants/common";
import { formatContentTiming } from "helpers/strings";
import AutocompleteEventsField from "./autocomplete-events-field";
import AutocompleteStoriesField from "./autocomplete-story-field";
import { CountriesField } from "./country";
import CurrencyField from "./currency-field";
/* import CheckboxField from './checkbox-add-content'; */
import { ExclusiveTypeField } from "./exclusive-type";
import TxtField from "./text-field";
import { IFormURL } from "./ugc-popup.types";
import VerficationsField from "./verifications-field";

interface IUGCFormURL {
	fields: any;
	name: string;
	index: number;
	error: boolean;
}

function UGCFormURL({ fields, name, index, error }: IUGCFormURL) {
	const form = useForm();
	const formState = form.getState();
	const url: UGCEntryURL = fields.value[index];

	function destroy(ev: any) {
		ev.preventDefault();
		if (url.id) {
			fields.update(index, { id: url.id, _destroy: true });
		} else {
			fields.remove(index);
		}
	}

	const destroyStyles = url._destroy ? { display: "none" } : {};

	const isCanNotEditDataUrl: boolean =
		!!url.admin_created && formState.values.user_step !== "content";

	return (
		<>
			<Grid
				item
				md={12}
				key={`new-url-${url.id || index}`}
				style={destroyStyles}
			>
				<Field
					render={({ input }) => (
						<TextField
							fullWidth
							disabled={isCanNotEditDataUrl}
							{...input}
							label="URL"
						/>
					)}
					name={`${name}[link]`}
				/>
			</Grid>

			<Grid
				item
				md={12}
				key={`new-url-date-${url.id || index}`}
				style={destroyStyles}
			>
				<Field
					render={({ input }) => (
						<TextField
							disabled={isCanNotEditDataUrl}
							required={true}
							type={"date"}
							error={error}
							{...input}
							label="Date of filming"
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
					name={`${name}[filmed_at]`}
				/>
			</Grid>
			<Grid
				item
				md={12}
				key={`new-url-location-${url.id || index}`}
				style={destroyStyles}
			>
				<Field
					render={({ input }) => (
						<TextField
							disabled={isCanNotEditDataUrl}
							error={error}
							{...input}
							label="Location"
							fullWidth
						/>
					)}
					name={`${name}[location]`}
				/>
			</Grid>
			<Grid
				item
				md={12}
				key={`new-url-description-${url.id || index}`}
				style={destroyStyles}
			>
				<Field
					render={({ input }) => (
						<TextField
							disabled={isCanNotEditDataUrl}
							error={error}
							{...input}
							label="Description"
							fullWidth
						/>
					)}
					name={`${name}[description]`}
				/>
			</Grid>
			<Grid
				item
				md={12}
				key={`new-url-time-${url.id || index}`}
				style={destroyStyles}
			>
				<Field
					render={({ input }) => (
						<TextField
							disabled={isCanNotEditDataUrl}
							error={error}
							{...input}
							label={`Time (${TIMECODE_FORMAT})`}
							fullWidth
						/>
					)}
					name={`${name}[content_timing]`}
					parse={formatContentTiming}
				/>
			</Grid>
			<Grid item md={12} style={destroyStyles}>
				<UGCImageUploadOfURL
					id={url.id || index}
					isUpdate={Boolean(url.id)}
					index={index}
					isCanNotEditDataUrl={isCanNotEditDataUrl}
				/>
				{!isCanNotEditDataUrl && !url._destroy && (
					<p
						className="field-subtext"
						style={{
							flexShrink: 0,
						}}
					>
						<a
							href="#root"
							style={{
								color: "#d0021b",
							}}
							onClick={destroy}
						>
							Remove link
						</a>
					</p>
				)}
			</Grid>
		</>
	);
}

export default function UGCForm() {
	const classes = useStyles();
	const [timingFillStatusList, setTimingFillStatusList] = useState<number[]>(
		[]
	);

	const form = useForm();
	const formState = form.getState();
	const { urls_attributes, status } = formState.values as {
		urls_attributes: IFormURL[];
		status: EntryStatus;
	};

	useEffect(() => {
		if (urls_attributes.length > 0) {
			urls_attributes.forEach((url, index) => {
				if (!url.content_timing && url.link) {
					if (!timingFillStatusList.includes(index)) {
						setTimingFillStatusList((prev) => [...prev, index]);
					}
				} else {
					setTimingFillStatusList((prev) => {
						prev.splice(index, 1);
						return prev;
					});
				}
			});
		}
	}, [urls_attributes]);

	const disabledEdit = status && status !== "created" && status !== "sent";

	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<TxtField name="title" label="File ID" />
			</Grid>
			<Grid item md={9}>
				<TxtField
					label="Price"
					name="price"
					onlyNumbers
					disabled={disabledEdit}
				/>
			</Grid>
			<Grid item md={3}>
				<CurrencyField name="currency" disabled={disabledEdit} />
			</Grid>
			<Grid item md={12}>
				<Box className={classes.title}>Event</Box>
				<AutocompleteEventsField name="event_id" />
			</Grid>
			<Grid item md={12}>
				<Box className={classes.title}>Story</Box>
				<AutocompleteStoriesField name="story_ids" />
			</Grid>
			<Grid item md={12}>
				<ExclusiveTypeField />
			</Grid>
			<Grid item md={12}>
				<CountriesField />
			</Grid>
			<Grid item md={12}>
				<TxtField name="location" label="Location" />
			</Grid>
			<Grid item md={12}>
				<TxtField name="location_link" label="Location link" type="url" />
			</Grid>
			<FieldArray name="news_links">
				{({ fields }) => (
					<>
						{fields.map((name, index) => (
							<Fragment key={index}>
								<Grid item md={9} xs={12}>
									<TxtField name={name} label="News link" />
								</Grid>
								<Grid
									item
									md={3}
									xs={12}
									style={{
										textAlign: "center",
										position: "relative",
										top: "20px",
									}}
								>
									{index === 0 ? (
										<Button color="primary" onClick={() => fields.push("")}>
											Add more
										</Button>
									) : (
										<IconButton onClick={() => fields.remove(index)} edge="end">
											<DeleteIcon color="secondary" />
										</IconButton>
									)}
								</Grid>
							</Fragment>
						))}
					</>
				)}
			</FieldArray>

			<Grid item md={12} xs={12}>
				<Box mt={2}>
					<FormControl style={{ minWidth: "100%" }}>
						<VerficationsField />
					</FormControl>
				</Box>
			</Grid>
			<Grid item md={12} xs={12}>
				<Box mb={3}>
					<DialogContentText>Verification materials</DialogContentText>
					<Grid item md={12}>
						{!formState.values.images_attributes.length && (
							<p className="field-subtext">No screenshots added</p>
						)}
						<UGCDialogScreenshots />
					</Grid>
				</Box>
			</Grid>
			<Grid item md={12} xs={12}>
				<DialogContentText>Links</DialogContentText>
			</Grid>
			<FieldArray name="urls_attributes" validate={ValidateValues}>
				{({ fields, meta }) => (
					<>
						{fields.map((name, index) => {
							return (
								<UGCFormURL
									fields={fields}
									name={name}
									index={index}
									key={name}
									error={timingFillStatusList.includes(index)}
								/>
							);
						})}
						<Grid item md={12} xs={12}>
							{!fields.length && (
								<p className="field-subtext">No links added</p>
							)}
							{!disabledEdit && (
								<Button
									color="primary"
									onClick={() => fields.push({ link: "" })}
								>
									Add link
								</Button>
							)}
						</Grid>
						{meta.error && (
							<div style={{ color: "red" }}>
								Every link should contain min 3 screenshots attached
							</div>
						)}
					</>
				)}
			</FieldArray>

			{/*            <Grid item md={12}>
                <CheckboxField
                    name="user_content_allowed"
                    label="Allow user to add content"
                />
            </Grid>*/}
		</Grid>
	);
}

const ValidateValues = (values: any) => {
	let errors: string = "";
	if (!Boolean(values)) {
		errors = "Required";
	} else {
		const links = values.filter(
			(item: any) => !item._destroy && (!item.link || !item.content_timing)
		);
		if (links.length > 0) {
			errors = "Required";
		} else {
			const imgs = values
				.filter((item: any) => !item._destroy)
				.some((item: any) => {
					return (
						!item.images ||
						item.images.filter((img: any) => !img._destroy).length < 3
					);
				});
			if (imgs) {
				errors = "Need to add 3 screenshots";
			}
		}
	}
	return errors;
};

const useStyles = makeStyles({
	title: {
		fontSize: "15px",
		fontWeight: 600,
	},
});
