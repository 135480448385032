import { makeStyles } from "@material-ui/core/styles";

export const useSearchBarStyles = makeStyles((theme) => ({
    box: {
        marginBottom: 40,
    },
    search: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    icon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        width: '100%',
    },
}))

