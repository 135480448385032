import {
    IChangeEntryStatusActionParams,
    IChangeUGCUserContentStatusActionParams,
    IToggleUserConfirmContentActionParams,
    IUserDataCommentsActionParams,
    UGCEntryURL,
    updateURL,
} from 'api/admin/ugc/entries.types';
import { Action } from 'react-fetching-library';
import {
    GetEntryParams,
    DestroyEntryParams,
    UpdateEntryStatusParams,
    SlugParams,
    UpdateEntryParams,
    UGCEntry,
} from './entries.types';
import PagyResponse from '../../../types/pagy';
import { makeFormData, makePlainObject } from 'helpers/files';

export function getEntriesAction(
    params: string
): Action<PagyResponse<UGCEntry>> {
    return {
        method: 'GET',
        endpoint: `/api/v1/admin/ugc/entries${params}`,
    };
}

export function getEntryAction(params: GetEntryParams): Action {
    return {
        method: 'GET',
        endpoint: `/api/v1/admin/ugc/entries/${params.id}`,
    };
}

export function createEntryAction(entry: FormData): Action {
    return {
        method: 'POST',
        endpoint: `/api/v1/admin/ugc/entries`,
        body: entry,
    };
}

export function updateEntryAction(params: {
    id: number;
    entry: FormData;
}): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/ugc/entries/${params.id}`,
        body: params.entry,
    };
}

export function destroyEntryAction(params: DestroyEntryParams): Action {
    return {
        method: 'DELETE',
        endpoint: `/api/v1/admin/ugc/entries/${params.id}`,
    };
}

export function updateEntryStatusAction(
    params: UpdateEntryStatusParams
): Action {
    return {
        method: 'PUT',
        endpoint: `/api/v1/admin/ugc/entries/${params.id}/status`,
        body: {
            status: params.status,
        },
    };
}

export function createNewSlugAction(params: SlugParams): Action {
    return {
        method: 'POST',
        endpoint: `/api/v1/admin/ugc/entries/${params.id}/slug`,
    };
}

export function createShareSlugAction(params: {
    id: number;
    renew: boolean;
}): Action {
    return {
        method: 'POST',
        endpoint: `/api/v1/admin/ugc/entries/${params.id}/admin_share_slugs`,
        body: { renew: params.renew },
    };
}

export function getShareSlugAction(params: any = {}): Action {
    return {
        method: 'GET',
        endpoint: `/api/v1/admin/ugc/entry_by_slug`,
        config: {
            search: params,
        },
    };
}

export function getUpdateUGCEntryAction({
    id,
    entry,
}: {
    id: number;
    entry: UpdateEntryParams;
}): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/ugc/entries/${id}`,
        body: {
            entry,
        },
    };
}

export function getUGCEntryURLCreateAction({
    id,
    urls_attributes,
}: {
    id: number;
    urls_attributes: UGCEntryURL[];
}): Action {
    return {
        method: 'POST',
        endpoint: `/api/v1/admin/ugc/entries/${id}/urls`,
        body: {
            urls_attributes,
        },
    };
}

export function changeUGCUserContentStatusAction({
    content_id,
    accepted_status,
    type = 'url',
}: IChangeUGCUserContentStatusActionParams): Action {
    return {
        method: 'PUT',
        endpoint: `/api/v1/admin/ugc/content/${content_id}/change_${type}_status`,
        body: {
            accepted_status,
        },
    };
}

export function confirmUserDataAction(entry_id: number): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/ugc/entries/${entry_id}/confirm_user_data`,
    };
}

export function updateEntryURLAction(url: updateURL): Action {
    const params = makePlainObject(url, 'url');

    return {
        method: 'PUT',
        endpoint: `/api/v1/admin/ugc/urls/${url.id}`,
        body: makeFormData(params),
    };
}

export function userDataCommentsAction({
    entry_id,
    comment,
}: IUserDataCommentsActionParams): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/ugc/entries/${entry_id}/comments`,
        body: {
            entry: {
                comment,
            },
        },
    };
}

export function toggleUserConfirmContentAction({
    entry_id,
    content_confirmed,
}: IToggleUserConfirmContentActionParams): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/ugc/entries/${entry_id}/confirm_content`,
        body: {
            content_confirmed,
        },
    };
}

export function changeEntryStatusAction({
    entry_id,
    status,
}: IChangeEntryStatusActionParams): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/ugc/entries/${entry_id}/${status}`,
    };
}
