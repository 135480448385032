import React from 'react';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import EventsChooseField from '../event-actions/events-field';
import {
    useMergeWithEvent,
    useStories,
} from '../../../../api/admin/event-story/stories';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from '../../../../store';
import qs from 'qs';
import { actions } from '../../../../reducers/events-stories/actions';
import { StoryType } from '../../../../api/admin/event-story/stories.types';
import { getStoriesAction } from 'api/admin/event-story/story.actions';
import { useParameterizedQuery } from 'react-fetching-library';

type PropsType = {
    onClose: () => void;
    data: StoryType;
    onError?: (text: string) => void;
};
const StoryMergeEvent: React.FC<PropsType> = ({ data, onClose, onError }) => {
    const dispatch = useDispatch();
    const mergeEvent = useMergeWithEvent();
    const stories = useStories();
    const { data_stories } = useSelector(
        (store: AppStateType) => store.dataEventsStories
    );
    const getStoriesAPI = useParameterizedQuery(getStoriesAction);

    function checkError(dataArr: StoryType[]) {
        return dataArr.some(({ title }) => data.title === title);
    }

    async function onSubmit(formData: any) {
        const storiesFromOpen = data_stories[data.event_id.toString()];
        const storiesToOpen = data_stories[formData.event_id.id.toString()];
        let error = false;
        const filterParamsStrTo = qs.stringify(
            { q: { event_id_in: [formData.event_id.id] } },
            {
                encode: false,
                arrayFormat: 'brackets',
                addQueryPrefix: true,
            }
        );

        if (!storiesToOpen) {
            const { payload } = await getStoriesAPI.query(filterParamsStrTo);
            error = checkError(payload?.stories || []);
        } else {
            error = checkError(storiesToOpen.stories);
        }

        if (error && onError) {
            onError('This event already contains story with that name.');
            return null;
        }

        const { status } = await mergeEvent({
            id_merge: data.id,
            event_id: formData.event_id.id,
        });

        if (status) {
            if (!error && storiesFromOpen && storiesFromOpen.openStories) {
                const filterParamsStr = qs.stringify(
                    { q: { event_id_in: [data.event_id] } },
                    {
                        encode: false,
                        arrayFormat: 'brackets',
                        addQueryPrefix: true,
                    }
                );
                const { payload } = await stories.query(filterParamsStr);
                if (payload && payload.stories) {
                    dispatch(
                        actions.setStories(payload.stories, data.event_id, true)
                    );
                }
            }

            if (!error && storiesToOpen && storiesToOpen.openStories) {
                const { payload } = await stories.query(filterParamsStrTo);
                if (payload && payload.stories) {
                    dispatch(
                        actions.setStories(
                            payload.stories,
                            formData.event_id.id,
                            true
                        )
                    );
                }
            }
        }

        onClose();
    }

    return (
        <Form
            onSubmit={onSubmit}
            validate={(values) => {
                const errors: any = {};
                if (!values.event_id) {
                    errors.event_id = 'Required';
                }
                return errors;
            }}
        >
            {({ handleSubmit, errors }) => {
                const isDisabled = Object.keys(errors ?? {}).length !== 0;

                return (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle id="form-dialog-title">
                            Move to another event
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {`You are about to move story
                                 "${data && data?.title}" to another EVENT.`}
                            </DialogContentText>
                            <Box mb={1}>Move to:</Box>
                            <EventsChooseField name="event_id" />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                disabled={isDisabled}
                            >
                                Move
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        </Form>
    );
};
export default StoryMergeEvent;
