import { useMutation, Action } from "react-fetching-library";
import { useCurrentAdmin } from "./current_admin";

export function signOutAction(): Action {
    return {
        method: "DELETE",
        endpoint: "/api/v1/admin/sign_out",
    };
}

export function useSignOut() {
    const signOut = useMutation(signOutAction);
    const currentAdmin = useCurrentAdmin();

    return async function () {
        currentAdmin.setPayload(undefined);
        await signOut.mutate(null);
        return await currentAdmin.query(null);
    };
}
