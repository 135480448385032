import React from 'react';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { StoryType } from '../../../../api/admin/event-story/stories.types';
import AutocompleteEvents from './autocomplete-events';
import AutocompleteStories from './autocomplete-story';
import { useMergeWithStory, useStories } from 'api/admin/event-story/stories';
import qs from 'qs';
import { actions } from '../../../../reducers/events-stories/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from '../../../../store';

type PropsType = {
    onClose: () => void;
    data: StoryType;
};
const StoryMergeStory: React.FC<PropsType> = ({ data, onClose }) => {
    const dispatch = useDispatch();
    const mergeWithStory = useMergeWithStory();
    const stories = useStories();
    const { data_stories } = useSelector(
        (store: AppStateType) => store.dataEventsStories
    );

    async function onSubmit(formData: any) {
        const { status } = await mergeWithStory({
            id: data.id,
            id_merge: formData.story_ids.id,
            event_id: formData.story_ids.event_id,
        });

        if (status) {
            if (data_stories[data.event_id.toString()].openStories) {
                const filterParamsStr = qs.stringify(
                    { q: { event_id_in: [data.event_id] } },
                    {
                        encode: false,
                        arrayFormat: 'brackets',
                        addQueryPrefix: true,
                    }
                );
                const { payload } = await stories.query(filterParamsStr);
                if (payload && payload.stories) {
                    dispatch(
                        actions.setStories(payload.stories, data.event_id, true)
                    );
                }
            }

            if (
                data_stories[formData.story_ids.event_id.toString()].openStories
            ) {
                const filterParamsStr = qs.stringify(
                    { q: { event_id_in: [formData.story_ids.event_id] } },
                    {
                        encode: false,
                        arrayFormat: 'brackets',
                        addQueryPrefix: true,
                    }
                );
                const { payload } = await stories.query(filterParamsStr);
                if (payload && payload.stories) {
                    dispatch(
                        actions.setStories(
                            payload.stories,
                            formData.story_ids.event_id,
                            true
                        )
                    );
                }
            }
        }
        onClose();
    }

    return (
        <Form
            onSubmit={onSubmit}
            validate={(values) => {
                const errors: any = {};
                if (!values.event_id) {
                    errors.event_id = 'Required';
                }
                if (!values.story_ids) {
                    errors.story_ids = 'Required';
                }
                return errors;
            }}
        >
            {({ handleSubmit, errors }) => {
                const isDisabled = Object.keys(errors ?? {}).length !== 0;

                return (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle id="form-dialog-title">
                            Remove and replace story
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {`You are about to assign all the requests 
                                from story "${data && data.title}" to another 
                                story and delete this one.`}
                            </DialogContentText>
                            <Box mb={1}>assign to:</Box>
                            <Box mb={1}>
                                <AutocompleteEvents name="event_id" />
                            </Box>
                            <Box>
                                <AutocompleteStories name="story_ids" />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                disabled={isDisabled}
                            >
                                Remove and replace
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        </Form>
    );
};
export default StoryMergeStory;
