import {makeStyles} from "@material-ui/core";

export const useUserProfileStyles = makeStyles({
    top: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 40,
    },
    container: {
        padding: '20px 60px',
    },
    content: {
        marginBottom: 100,
        padding: '20px 40px',
    },
    title: {
        marginBottom: 10,
        marginLeft: 20,
    },
    labelList: {
        fontWeight: 600,
    },
});