import {
    Box,
    Button,
    Container,
    Dialog,
    Grid,
    Typography,
} from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { EventsProvider } from 'api/admin/event-story/events';
import { StoriesProvider } from 'api/admin/event-story/stories';
import { UGCEntriesProvider, useUGCEntries } from 'api/admin/ugc/entries';
import { useAppStyles } from 'app.styles';
import ModifySnackbar from 'components/snackbar';
import { UGCPopup } from 'components/UGC';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UGCRequestsRoute } from '../../config/routes';
import FilterEventStory from './filter-requests/filter-event-story';
import FilterRequests from './filter-requests/filter-requests';
import UGCRequestRow from './ugc-request-row';
import { useUGCRequestsStyles } from './ugs-requests.styles';

export default function UGCRequestsPage() {
    return (
        <UGCEntriesProvider initFetch={false}>
            <EventsProvider initFetch={false}>
                <StoriesProvider initFetch={false}>
                    <UGCRequests />
                </StoriesProvider>
            </EventsProvider>
        </UGCEntriesProvider>
    );
}

export function scrollTop() {
    window.scrollTo({top: 0, behavior: 'auto'})
}

export function UGCRequests() {
    const [requestCreated, setReqCreateStatus] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const classes = useUGCRequestsStyles();
    const [popup, setPopup] = useState<any>(null);
    const [filterData, setFilterData] = useState<any>(getFilterDataFromUrl());
    const entries = useUGCEntries();
    const commonClasses = useAppStyles();

    const page: number = !entries.payload?.pagy.page
        ? 1
        : entries.payload?.pagy.page;

    const count_entries: number = !entries.payload?.pagy.count
        ? 0
        : entries.payload?.pagy.count;

    const per_page: number = 20;
    const count_pages: number = Math.ceil(count_entries / per_page);

    useEffect(() => {
        entries.query(location.search);
        scrollTop();
    }, [location.search]);

    const onResetAll = React.useCallback(() => {
        const newFilterData = {
            event_id_in: filterData.event_id_in,
            stories_id_in: filterData.stories_id_in,
        };
        setFilterData(newFilterData);

        const filterParamsStr = qs.stringify(
            { q: newFilterData },
            { encode: false, arrayFormat: 'brackets' }
        );
        history.push(UGCRequestsRoute.getLink() + `?${filterParamsStr}`);
        // history.push(UGCRequestsRoute.getLink());
    }, [filterData]);

    function getFilterDataFromUrl() {
        let data: any = { q: {}, search: {} };
        data = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (data.search !== undefined) {
            return { ...data.q, search: data.search };
        } else {
            return { ...data.q };
        }
    }

    function createParamsObj(values: any) {
        const params: any = { q: {} };

        if (values.user_step_in) {
            params.q.user_step_in = [];
            values.user_step_in.forEach((item: any) =>
                params.q.user_step_in.push(item)
            );
        }
        if (values.status_in) {
            params.q.status_in = [];
            values.status_in.forEach((item: any) =>
                params.q.status_in.push(item)
            );
        }

        values.country_id_eq && (params.q.country_id_eq = values.country_id_eq);
        values.search && (params.search = values.search);

        return params;
    }

    function onFilterSubmit(values: any) {
        const paramsObj = createParamsObj(values);
        paramsObj.q.event_id_in = filterData.event_id_in;
        paramsObj.q.stories_id_in = filterData.stories_id_in;
        values.created_at_gteq &&
            (paramsObj.q.created_at_gteq = values.created_at_gteq);
        values.created_at_lteq &&
            (paramsObj.q.created_at_lteq = values.created_at_lteq);

        setFilterData({ ...paramsObj.q, search: paramsObj.search });

        const filterParamsStr = qs.stringify(paramsObj, {
            encode: false,
            arrayFormat: 'brackets',
        });

        // query(`?${filterParamsStr}`);
        history.push(UGCRequestsRoute.getLink() + `?${filterParamsStr}`);
    }

    function onFilterEventStory(values: any) {
        const params: any = { q: {} };
        params.q = values;
        const newFilterData = {
            ...filterData,
            event_id_in: values.event_id_in,
            stories_id_in: values.stories_id_in,
        };
        setFilterData(newFilterData);

        const filterParamsStr = qs.stringify(
            { q: newFilterData, search: newFilterData.search },
            { encode: false, arrayFormat: 'brackets' }
        );

        history.push(UGCRequestsRoute.getLink() + `?${filterParamsStr}`);
    }

    function snackbarCloseHandler() {
        setReqCreateStatus(false);
    }

    function onCreateReqHandler(success?: boolean) {
        setPopup(null);
        if (success) {
            setReqCreateStatus(true);
        }
    }

    return (
        <Container maxWidth="xl" className={commonClasses.container}>
            <ModifySnackbar
                text="Upload link is created and copied. Send it to UGC owner."
                open={requestCreated}
                handleClose={snackbarCloseHandler}
            />
            <Dialog open={popup} onClose={() => setPopup(null)}>
                <UGCPopup data={popup} onClose={onCreateReqHandler} />
            </Dialog>
            <Box className={classes.top}>
                <Typography variant="h4">Requests</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setPopup({})}
                >
                    Create request
                </Button>
            </Box>
            <Grid container spacing={4}>
                <Grid item md={9}>
                    <Grid item md={12}>
                        <FilterEventStory
                            onSubmit={onFilterEventStory}
                            filterData={filterData}
                        />
                    </Grid>
                    <Grid container spacing={2}>
                        {entries.payload?.entries.map((item) => (
                            <Grid item md={12} key={item.id}>
                                <UGCRequestRow data={item} />
                            </Grid>
                        ))}

                        {count_entries > per_page && (
                            <Grid
                                item
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                <Pagination
                                    color="primary"
                                    page={page}
                                    count={count_pages}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            component={Link}
                                            to={
                                                UGCRequestsRoute.getLink() +
                                                `?${qs.stringify(
                                                    {
                                                        q: filterData,
                                                        page: item.page,
                                                    },
                                                    {
                                                        encode: false,
                                                        arrayFormat: 'brackets',
                                                    }
                                                )}`
                                            }
                                            {...item}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item md={3}>
                    <FilterRequests
                        onSubmit={onFilterSubmit}
                        onResetAll={onResetAll}
                        filterData={filterData}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
