import React from 'react';
import qs from 'qs';
import { useMergeEvent } from '../../../../api/admin/event-story/events';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import EventsChooseField from './events-field';
import { EventType } from 'api/admin/event-story/events.types';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from '../../../../store';
import { actions } from '../../../../reducers/events-stories/actions';
// import { getStoriesAction } from '../../../../api/admin/event-story/story.actions';
// import { useParameterizedQuery } from 'react-fetching-library';
import { useStories } from 'api/admin/event-story/stories';

type PropsType = {
    onClose: () => void;
    data: EventType;
};
const EventMerge: React.FC<PropsType> = ({ data, onClose }) => {
    const dispatch = useDispatch();
    const mergeEvent = useMergeEvent();
    const storiesAPI = useStories();
    // const storiesAPI = useParameterizedQuery(getStoriesAction);
    const { data_stories } = useSelector(
        (store: AppStateType) => store.dataEventsStories
    );

    async function onSubmit(formData: any) {
        await mergeEvent({ id: data.id, id_merge: formData.event_id.id });

        const id_merge: string = formData.event_id.id.toString();
        if (data_stories[id_merge] && data_stories[id_merge].openStories) {
            const { payload } = await storiesAPI.query(
                qs.stringify(
                    { q: { event_id_in: [formData.event_id.id] } },
                    {
                        encode: false,
                        arrayFormat: 'brackets',
                        addQueryPrefix: true,
                    }
                )
            );

            if (payload && payload.stories) {
                dispatch(
                    actions.setStories(
                        payload.stories,
                        formData.event_id.id,
                        true
                    )
                );
            }
        }
        onClose();
    }

    return (
        <Form
            onSubmit={onSubmit}
            validate={(values) => {
                const errors: any = {};
                if (!values.event_id) {
                    errors.event_id = 'Required';
                }
                return errors;
            }}
        >
            {({ handleSubmit, errors }) => {
                const isDisabled = Object.keys(errors ?? {}).length !== 0;

                return (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle id="form-dialog-title">
                            Remove and replace event
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {`You are about to move all the stories
                                 from event "${data && data?.title}" to another 
                                 event and delete this one.`}
                            </DialogContentText>
                            <Box mb={1}>Move to:</Box>
                            <EventsChooseField name="event_id" />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                disabled={isDisabled}
                            >
                                Remove and replace
                            </Button>
                        </DialogActions>
                    </form>
                );
            }}
        </Form>
    );
};
export default EventMerge;
