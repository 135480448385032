import { Checkbox, TextField, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useForm, useField } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useVerifications } from 'api/admin/verifications';

type Props = {
};

const VerificationField: React.FC<Props> = ({
}) => {
    const classes = useStyles();
    const form = useForm();
    const fromField = useField('verification');
    let verificationOptions = useVerifications().payload || [];

    if (!!useField('id').input.value) {
        verificationOptions = fromField.input.value || [];
    }

    const handleChange = (event: any, values: any) => {
        event.preventDefault();
        fromField.input.onChange(values)
    };

    const handleClose = (event: any, values: any) => {
    };

    return (
        <div className={classes.content}>
            <Autocomplete
                multiple
                value={fromField.input.value || []}
                size="medium"
                options={verificationOptions}
                onChange={handleChange}
                onInputChange={handleClose}
                getOptionSelected={(option, value) =>
                    option.id == value.id
                }
                getOptionLabel={(option: any) => option.title}
                renderOption={(option, { selected }) => {
                    return (
                        <>
                            <Checkbox
                                color="primary"
                                icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                }
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.title}
                        </>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder="Verification methods"
                    />
                )}
            />
        </div>
    );
};
export default VerificationField;

const useStyles = makeStyles({
    content: {
        padding: 0,
    },
});
