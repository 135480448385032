import {
	Button,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { UGCEntryImageAttrs } from "api/admin/ugc/entries.types";
import clsx from "clsx";
import { AddImages } from "components/screenshot";
import { fillEmptyStr } from "helpers/strings";
import React, { useCallback } from "react";
import { useForm } from "react-final-form";
import useStyles from "./image-upload.styles";

export default function UGCDialogScreenshots() {
	const classes = useStyles();
	const id = "dialog-screenshot-button";
	const formAPI = useForm();
	const formState = formAPI.getState();
	const name = `images_attributes`;
	const savedScreenshots = formState.values[name] as UGCEntryImageAttrs[];

	function deleteScreenshotHandler(index: number) {
		formAPI.mutators.removeScreenshot(name, index);
	}

	const wrappedDialogScreenshot = useCallback(
		(list: File[]) =>
			list.map((image) => ({
				_destroy: false,
				image,
			})),
		[]
	);

	return (
		<>
			<div className={classes.root}>
				<AddImages
					name={name}
					id={id}
					images={savedScreenshots}
					middleware={wrappedDialogScreenshot}
				/>
				<label htmlFor={id}>
					<Button variant="text" color="primary" component="span">
						Add screenshots
					</Button>
				</label>
			</div>
			<div className={classes.demo}>
				<List dense={true}>
					{savedScreenshots.map((img, index) => {
						const isFile = img.image instanceof File;
						let previewURL: string;

						if (isFile) {
							previewURL = URL.createObjectURL(img.image as File);
						} else {
							previewURL = fillEmptyStr(img.file as string);
						}

						return (
							<ListItem
								key={previewURL}
								className={clsx(img._destroy && classes.hideItem)}
							>
								<img
									src={previewURL}
									alt={previewURL}
									className={classes.previewImg}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="delete"
										onClick={deleteScreenshotHandler.bind(null, index)}
										className={clsx(img._destroy && classes.hideItem)}
									>
										<DeleteIcon color="secondary" />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>
			</div>
		</>
	);
}
