import { makeStyles } from "@material-ui/core";

export const useAppStyles = makeStyles({
    signInContainer: {
        maxWidth: 300,
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        paddingTop: 20,
        paddingBottom: 20,
    },
});
