import { makeStyles } from "@material-ui/core";

export const useRequestStyles = makeStyles((t) => ({
	root: {
		marginLeft: 0,
		marginRight: 0,
	},
	block: {
		backgroundColor: "#E9F0F1",
		borderWidth: 1,
		borderRadius: "20px 19px",
		borderColor: "#ABABAB",
	},
	subCaption: {
		fontSize: 14,
		lineHeight: "20px",
	},
	text: {
		fontSize: 12,
		lineHeight: "20px",
		textTransform: "initial",
		wordWrap: "break-word",
	},
	link: {
		textDecoration: "underline",
		cursor: "pointer",
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		fontSize: 16,
		transition: t.transitions.create("transform", {
			duration: t.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	textBreak: {
		wordWrap: "break-word",
		fontSize: 15,
	},
	italic: {
		fontStyle: "italic",
	},
	textArea: {
		fontSize: 12,
		padding: 5,
	},
	preview: {
		width: 169,
		height: 77,
		objectFit: "cover",
	},
	image: {
		"&-container": {
			position: "relative",
		},

		"&--row": {
			display: "inline-block",
		},

		"&-container:hover &-delete": {
			opacity: 1,
			pointerEvents: "initial",
		},

		"&-delete": {
			position: "absolute",
			right: 0,
			top: 0,
			transform: "translate(50%, -50%)",
			backgroundColor: "#F44336",
			borderRadius: "50%",
			width: 15,
			height: 15,
			color: "#fff",
			border: "none",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			fontSize: 12,
			cursor: "pointer",
			transition: "opacity 0.3s",
			opacity: 0,
			userSelect: "none",
			pointerEvents: "none",
		},
	},
	confirmForm: {
		width: 850,
		margin: "auto",
	},
	ugcTitle: {
		fontWeight: "bold",
		fontSize: "28px",
	},
	statusBlock: {
		fontSize: 20,
		fontWeight: 400,
		marginTop: 10,
		marginBottom: 25,

		"&__sub": {
			fontSize: 16,
			marginLeft: 10,
		},

		"&::before": {
			content: '""',
			width: 12,
			height: 12,
			display: "inline-block",
			borderRadius: "50%",
			marginRight: 10,
		},

		"&--danger::before": {
			backgroundColor: "#F44336",
		},

		"&--warning::before": {
			backgroundColor: "#ffc107",
		},

		"&--success::before": {
			backgroundColor: "#28a745",
		},
	},
	durationSM: {
		width: 50,
		height: 24,
		maxHeight: 24,
		marginLeft: 10,
	},

	screenshot: {
		width: 70,
		height: 50,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-around",
		border: "1px solid #000",
		borderRadius: "3px",
		cursor: "pointer",
		fontSize: 8,
		paddingTop: 5,
		paddingBottom: 5,
		backgroundColor: "transparent",
		transition: "opacity .3s",
		boxSizing: "border-box",

		"&--disabled": {
			color: "#C4C4C4",
			borderColor: "#C4C4C4",
			pointerEvents: "none",
			userSelect: "none",
		},

		"&:hover": {
			opacity: 0.7,
		},

		"&--bulk": {
			position: "relative",
			marginLeft: 6,

			"&::before, &::after": {
				content: '""',
				position: "absolute",
				top: -1,
				bottom: -1,
				width: "100%",
				borderTopLeftRadius: "3px",
				borderBottomLeftRadius: "3px",
				display: "block",
				borderWidth: "1px",
				borderColor: "currentColor",
				borderStyle: "solid",
				borderRightColor: "transparent",
			},

			"&::before": {
				left: -3,
			},

			"&::after": {
				left: -5,
			},
		},
	},
	userContentRowHead: {
		borderRightColor: "rgba(0, 0, 0, 0.12)",
		borderRightWidth: "1px",
		borderRightStyle: "solid",
	},
	userContentRow: {
		boxSizing: "border-box",
		borderColor: "transparent",
		borderTopColor: "rgba(0, 0, 0, 0.12)",
		borderWidth: "1px",
		borderStyle: "solid",

		"&:last-of-type": {
			borderBottomColor: "rgba(0, 0, 0, 0.12)",
		},
	},
	downloadVideo: {
		width: 14,
		height: 14,
		backgroundImage:
			"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAuODMxNSA2LjAwMTM1QzEwLjc1OTEgNS44NzUwNSAxMC42IDUuNzk0NjEgMTAuNDI0OSA1Ljc5NDYxSDguNjM3MzZWMS4yMDgyNkM4LjYzNzM2IDEuMDEzNTIgOC40MzcxNiAwLjg1NTQ2OSA4LjE5MDQ5IDAuODU1NDY5SDYuNDAyOTlDNi4xNTYzMSAwLjg1NTQ2OSA1Ljk1NjExIDEuMDEzNTIgNS45NTYxMSAxLjIwODI2VjUuNzk0NjFINC4xNjg2MUMzLjk5MzQzIDUuNzk0NjEgMy44MzQzNSA1Ljg3NTc2IDMuNzYxOTUgNi4wMDEzNUMzLjY4ODY3IDYuMTI3NjUgMy43MTcyNyA2LjI3NTEyIDMuODMyNTYgNi4zNzk1NUw2Ljk2MDY5IDkuMjAxOTJDNy4wNDU1OSA5LjI3ODgzIDcuMTY4MDQgOS4zMjI1NyA3LjI5Njc0IDkuMzIyNTdDNy40MjU0NCA5LjMyMjU3IDcuNTQ3ODggOS4yNzgxMiA3LjYzMjc5IDkuMjAxOTJMMTAuNzYwOSA2LjM3OTU1QzEwLjg3NzEgNi4yNzU4MyAxMC45MDM5IDYuMTI3NjUgMTAuODMxNSA2LjAwMTM1WiIgZmlsbD0iIzIyMjIyMiIvPjxwYXRoIGQ9Ik0xMi4yMTI1IDguNjE3MTlWMTAuNzM0SDIuMzgxMjVWOC42MTcxOUgwLjU5Mzc1VjExLjQzOTZDMC41OTM3NSAxMS44Mjk3IDAuOTk0MTUgMTIuMTQ1MSAxLjQ4NzUgMTIuMTQ1MUgxMy4xMDYyQzEzLjYwMDUgMTIuMTQ1MSAxNCAxMS44Mjk3IDE0IDExLjQzOTZWOC42MTcxOUgxMi4yMTI1WiIgZmlsbD0iIzIyMjIyMiIvPjwvc3ZnPg==)",
		backgroundPosition: "center",
		backgroundSize: "cover",
		borderWidth: 0,
		display: "inline-block",
		padding: 0,
		margin: "1px 1px 1px 5px",
		cursor: "pointer",
	},

	tableRowCaption: {
		width: 190,
		color: "rgba(#222222, 70%)",
	},

	correctionBtnWrap: {
		display: "flex",
		justifyContent: "flex-end",
	},

	disabled: {
		opacity: 0.5,
	},

	cell: {
		height: 63.75,
	},
}));
