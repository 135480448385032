import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toggleUserConfirmContent } from 'api/admin/ugc/entries';
import { IPayloadURLScreenshots } from 'api/admin/ugc/entries.types';
import clsx from 'clsx';
import { AddImages, ViewImage } from 'components/screenshot';
import React, { useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { useRequestStyles } from '../ugc-request.styles';

interface UGCContentScreenshotsProps {
    urlID: number;
    disabled?: boolean;
    deleteHandler: (index: number) => void;
    entryID: number;
    contentConfirmed: boolean;
    hiddenAction: boolean;
}

export default function UGCContentScreenshots({
    urlID,
    disabled,
    deleteHandler,
    entryID,
    contentConfirmed,
    hiddenAction,
}: UGCContentScreenshotsProps) {
    const [confirmDelete, setConfirmDelete] = useState<null | number>(null);
    const contentStatusAPI = toggleUserConfirmContent();

    async function handleDeleteScreenshot() {
        await deleteHandler(confirmDelete as number);
        if (contentConfirmed) {
            await contentStatusAPI({
                entry_id: entryID,
                content_confirmed: false,
            });
        }
        handleCloseConfirm();
    }

    function handleOpenConfirm(index: number) {
        setConfirmDelete(index);
    }

    function handleCloseConfirm() {
        setConfirmDelete(null);
    }

    const { input } = useField('images');
    const list = input.value as unknown[];

    const classes = useRequestStyles();
    const addBtnId = `images_add_url_${urlID}`;

    const links: string[] = useMemo(() => {
        return list.map((img) => {
            const isFile = img instanceof File;

            if (isFile) {
                return URL.createObjectURL(img as File);
            } else {
                return (img as IPayloadURLScreenshots).link;
            }
        });
    }, [list]);

    return (
        <Grid container>
            {!(hiddenAction && disabled) && (
                <Dialog
                    open={confirmDelete !== null}
                    onClose={handleCloseConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        You really want to delete screenshot?
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            onClick={handleCloseConfirm}
                            color="secondary"
                            autoFocus
                        >
                            Disagree
                        </Button>
                        <Button
                            onClick={handleDeleteScreenshot}
                            color="primary"
                        >
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {links.map((link, index) => {
                return (
                    <Box
                        m={1}
                        key={link}
                        className={`${classes.image}-container`}
                    >
                        {!disabled && (
                            <button
                                className={`${classes.image}-delete`}
                                onClick={handleOpenConfirm.bind(null, index)}
                                type="button"
                            >
                                &#10006;
                            </button>
                        )}
                        <ViewImage link={link} />
                    </Box>
                );
            })}

            {!hiddenAction && (
                <Box m={1}>
                    <AddImages name="images" images={list} id={addBtnId} />
                    <label
                        htmlFor={addBtnId}
                        className={clsx(classes.screenshot, {
                            [`${classes.screenshot}--bulk`]: !input.value
                                .length,
                            [`${classes.screenshot}--disabled`]: disabled,
                        })}
                    >
                        <AddCircleOutlineIcon fontSize="small" />
                        Add screenshot
                    </label>
                </Box>
            )}
        </Grid>
    );
}
