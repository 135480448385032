import { makeStyles } from "@material-ui/core/styles";

export const useFilterBlockStyles = makeStyles({
    title: {
        padding: 10,
        fontSize: '15px',
        fontWeight: 600
    },
    body: {
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
    },
    footer: {
        // paddingRight: 10,
        paddingBottom: 10,
        // paddingLeft: 10,
        display: "flex",
        // justifyContent: "flex-end",
    },
    btnReset: {
        fontSize: '13px',
        fontWeight: 500,
        color: '#222',
        textTransform: 'none',
        '&:disabled': {
            color: 'rgb(190, 194, 195)',
        }
    }
});
