import { Checkbox, TextField, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { Field } from 'react-final-form';

export type Option = { value: string; title: string; label?: string };

type AutocompleteCheckbox = {
    name: string;
    options: Option[];
    setSearchValue: (value: string) => void;
    placeholder?: string;
    variant?: TextFieldProps['variant'];
    size?: 'small' | 'medium';
    defaultList?: Option[];
    value?: Option[];
    onChangeHandle?: () => void;
};

const AutocompleteCheckbox: React.FC<AutocompleteCheckbox> = ({
    name,
    options,
    setSearchValue,
    variant = 'outlined',
    placeholder,
    size = 'small',
    defaultList,
    value,
    onChangeHandle = () => {},
}) => {
    const classes = useStyles();
    const handleClose = (event: any, value: string) => {
        if (!value) setSearchValue('');
    };

    return (
        <div className={classes.content}>
            <Field name={name}>
                {({ input }) => {
                    return (
                        <Autocomplete
                            multiple
                            defaultValue={defaultList}
                            size={size}
                            value={value}
                            options={options}
                            onInputChange={handleClose}
                            getOptionSelected={(option, value) =>
                                option.value === value.value
                            }
                            groupBy={(option) => option.label || ''}
                            getOptionLabel={(option) => option.title}
                            renderOption={(option, { selected }) => {
                                return (
                                    <>
                                        <Checkbox
                                            color="primary"
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.title}
                                    </>
                                );
                            }}
                            onChange={(_, values) => {
                                input.onChange(
                                    values.map(({ value }) => value)
                                );
                                onChangeHandle();
                            }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        variant={variant as any}
                                        placeholder={placeholder}
                                        onChange={(e) =>
                                            setSearchValue(e.target.value)
                                        }
                                    />
                                );
                            }}
                        />
                    );
                }}
            </Field>
        </div>
    );
};
export default AutocompleteCheckbox;

const useStyles = makeStyles({
    content: {
        padding: 0,
    },
});
