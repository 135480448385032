import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import {
    Link,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { LabelType } from '../../pages/users/users-table';
import { DateTime } from 'luxon';
import { UGCEntry } from '../../api/admin/ugc/entries.types';
import { NavLink } from 'react-router-dom';
import { UGCRequestDetailsRoute } from '../../config/routes';

type PropsTable = {
    labels: Array<LabelType>;
    data: Array<UGCEntry> | undefined;
};
const TableUserRequests: React.FC<PropsTable> = ({ labels, data }) => {
    const classes = useStyles();

    function event_stories(data: UGCEntry): string {
        const event_name = `${
            data.event_name === null ? 'N/A' : data.event_name
        }`;
        const story_name = `${
            data.stories && data.stories.length !== 0
                ? data.stories[0].story_name
                : 'N/A'
        }`;
        return `${event_name} / ${story_name}`;
    }

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {labels.map((l) => (
                            <TableCell align="center" key={l.key}>
                                {l.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data &&
                        data.map((row) => (
                            <TableRow key={row.id} hover>
                                <TableCell align="center">
                                    <Link
                                        component={NavLink}
                                        to={UGCRequestDetailsRoute.getLink({
                                            id: String(row.id),
                                        })}
                                        className={classes.numberLink}
                                        color="inherit"
                                    >
                                        {row.title}
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    {event_stories(row)}
                                </TableCell>
                                <TableCell align="center">
                                    {DateTime.fromISO(row.created_at).toFormat(
                                        'LLLL d, yyyy HH:mm a'
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default TableUserRequests;

const useStyles = makeStyles({
    numberLink: {
        textDecoration: 'underline',
    },
});
