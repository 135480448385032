import { Action } from 'react-fetching-library';
import {
    StoriesResponseType,
    StoryParamsType,
    StoryType,
    StoryUpdateActionParams,
} from './stories.types';

export function getStoriesAction(params: string): Action<StoriesResponseType> {
    return {
        method: 'GET',
        endpoint: `/api/v1/admin/categorization/stories${params}`,
    };
}

export function getStoryAction(id: number): Action<StoryType> {
    return {
        method: 'GET',
        endpoint: `/api/v1/admin/categorization/stories/${id}`,
    };
}

export function createStoryAction(params: StoryParamsType): Action {
    return {
        method: 'POST',
        endpoint: `/api/v1/admin/categorization/stories`,
        body: {
            story: params,
        },
    };
}

export function mergeWithStoryAction({
    id,
    id_merge,
    event_id,
}: {
    id: number;
    id_merge: number;
    event_id: number;
}): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/categorization/stories/${id_merge}/merge`,
        body: {
            merge_with: id,
        },
    };
}

export function mergeWithEventAction({
    id_merge,
    event_id,
}: {
    id_merge: number;
    event_id: number;
}): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/categorization/stories/${id_merge}/change_event`,
        body: {
            new_event: event_id,
        },
    };
}

export function updateStoryAction({
    id,
    ...story
}: StoryUpdateActionParams): Action {
    return {
        method: 'PATCH',
        endpoint: `/api/v1/admin/categorization/stories/${id}`,
        body: {
            story,
        },
    };
}

export function deleteStoryAction(id: number): Action {
    return {
        method: 'DELETE',
        endpoint: `/api/v1/admin/categorization/stories/${id}`,
    };
}
